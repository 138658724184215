import React, { Component } from 'react';
import Categories from './Categories';

import i18n from "../utils/i18n";

import './css/overview.css';

class Overview extends Component {
  constructor(props) {
    super(props);
    const state = {
      categories: [],
    };

    this.state = state;
    this.updateCategories = this.updateCategories.bind(this);
  }

  updateCategories(e, checkbox) {
    this.setState((prevState) => {
      const { id, checked } = checkbox;
      let categories = prevState.categories;
      if (checked) {
        categories.push(id);
      } else {
        categories = categories.filter(function(element) {
          return element !== id;
        });
      }
      return {
        categories,
      }
    });
  }

  render() {
    return (
      <div>
        <div className="Overview clearfix">
          <div className="header-information">
            <label>
              ECQB-PPL <br/>
              <b>{i18n("Fragenkatalog")}</b>
            </label>
          </div>
        </div>
        <Categories
          flightschool_categories={this.props.flightschool_categories}
          radio_categories={this.props.radio_categories}
          questions={this.props.questions}
          onChange={this.updateCategories}
          enableFlightschoolContent={this.props.enableFlightschoolContent}
          enableRadioQuestions={this.props.enableRadioQuestions}
          onSelectScenario={this.props.onSelectScenario}
        />
        <div className="Overview actions-container action-container-overview">
          <button className="btn-primary" disabled={this.state.categories.length === 0} onClick={e => this.props.startQuiz({ mode: "training", categories: this.state.categories, event: e })} >{i18n("startTraining")}</button>
          <button className="btn-primary" onClick={e => this.props.startQuiz({ mode: "simulation", categories: this.state.categories, event: e })} >{i18n("Exam")}</button>
        </div>
      </div>
    );
  }
}

export default Overview;
