import React, { Component } from 'react';
import { Container } from "semantic-ui-react";
import ReactPixel from 'react-facebook-pixel';
import _ from 'underscore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import utils from '../utils/utils';
// import PaymentModal from './PaymentModal';
import OutOfCapacityModal from './outOfCapacity/OutOfCapacityModal';

import './css/login.css';
import paymentLogo from '../landingpage/images/payments/payment.png';

import i18n from "../utils/i18n";

// new prices
const PAYMENT_SMALL_PRICE = "price_1Q1Yp2JMvwvDioT5ho4745at";
const PAYMENT_MEDIUM_PRICE = "price_1Q1YoiJMvwvDioT5g7LRrDbf";
const PAYMENT_LARGE_PRICE = "price_1Q1YpHJMvwvDioT5EkzEBVQC";
const DREIECK_STRIPE_PRICE = "price_1Q1YoAJMvwvDioT5z1Ekpikh";
const SCHLUESSELANHAENGER_STRIPE_PRICE = "price_1Q1YmCJMvwvDioT5Ehg0rMK6";
const FLIGHTCOMPUTER_STRIPE_PRICE = "price_1Q1YsjJMvwvDioT51I7phMtA";
const HEADSET_STRIPE_PRICE = "price_1Q1YlTJMvwvDioT5a08vsqMD";
const CHEATSHEET_STRIPE_PRICE = "price_1Q1YFxJMvwvDioT5exULCQd8";
const CHEATSHEET_FREE_STRIPE_PRICE = "price_1Q1YnbJMvwvDioT5euqiKe8y";


class RequestLoginData extends Component {
  constructor(props) {
    super(props);

    let product = null;
    // let lang = null;
    const location = this.props.location;
    if (location && location.location && location.location.search) {
      product = this.extractUrlValue("product", location.location.search);
    }

    this.state = { 
      showPaymentChooseMethodeDialog: product ? true : false,
      product,
    };

    this.inverval = null;

    this.closeDialog = this.closeDialog.bind(this);
    this.openDialogAndLoadStripeRessources = this.openDialogAndLoadStripeRessources.bind(this);
    this.onPaymentSelection = this.onPaymentSelection.bind(this);
    this.stripeCheckout = this.stripeCheckout.bind(this);
    this.onPayPalCancel = this.onPayPalCancel.bind(this);
    this.onPayPalError = this.onPayPalError.bind(this);
    this.onPayPalSuccess = this.onPayPalSuccess.bind(this);
    this.checkPaymentState = this.checkPaymentState.bind(this);
    this.sofortCheckout = this.sofortCheckout.bind(this);
    this.fetchClientSecret = this.fetchClientSecret.bind(this);
    this.checkSOFORTPayment = this.checkSOFORTPayment.bind(this);
    this.loadStripeRessources = this.loadStripeRessources.bind(this);
    this.stripeLoaded = false;
  }

  componentDidMount() {
    const advancedMatching = {};
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('711428279376703', advancedMatching, options);
    this.checkPaymentState(window.location.hash);
  }

  loadStripeRessources() {
    if (!this.stripeLoaded) {
      const stripeScript = document.createElement('script');
      stripeScript.src = "https://js.stripe.com/v3";
      stripeScript.async = true;
      stripeScript.onload = () => {
        this.stripe = window.Stripe("pk_live_LBs9pdWo14TDAeWkrkegsXsJ00YeCtbs5n");
        this.stripeLoaded = true;
      };
      document.body.appendChild(stripeScript);
    }
  }

  extractUrlValue(key, string) {
    const match = string.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }

  checkPaymentState(hash) {
    const search = window.location.search;
    if (search.indexOf("payment_intent_client_secret") > 0) {
      this.checkSOFORTPayment();
      return null;
    }

    if (!hash) return null;
    if (hash.indexOf("success") > 0) {
      this.notify(true);
      utils.deleteCookie("purchase_school_ref");
    } else if (hash.indexOf("cancel") > 0) {
      this.notify(false);
    } 
  }

  notify(success) {
    if (success) {
      ReactPixel.track("Purchase",{ value: 69.00, currency: 'EUR' });
      const callback = function (options) {
        window.location = window.location.origin + '/successful';;
      };
      window.gtag('event', 'conversion', {
        'send_to': 'AW-1036337835/Y0kwCMPjgIsCEKuFle4D',
        'value': 69.0,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': callback
      });
    } else {
      clearInterval(this.inverval);
      toast.error(i18n("payment_not_successfull"));
    }
  }

  openDialogAndLoadStripeRessources() {

    // Load Stripe Ressources
    this.loadStripeRessources();

    this.setState({
      showPaymentChooseMethodeDialog: true,
    });
  }

  closeDialog() {
    this.setState({
      showPaymentChooseMethodeDialog: false,
    });
  }

  onPaymentSelection(option, time, products, options) {
    this.closeDialog();
    if (option === "creditcard") {
      // Facebook Pixel
      ReactPixel.trackCustom("Weiter zu Stripe!", {});
      this.stripeCheckout(time, products);
    } if (option === "sofort") {
      // Facebook Pixel
      ReactPixel.trackCustom("Weiter zu Stripe!", {});
      this.sofortCheckout(time, products, options);
    }
  }

  getPackagePrices(includeHeadset, includeFlightcomputer) {
    const items = [
      { price: DREIECK_STRIPE_PRICE, quantity: 1 },
      { price: SCHLUESSELANHAENGER_STRIPE_PRICE, quantity: 1 },
    ];
    if (includeFlightcomputer) {
      items.push({ price: FLIGHTCOMPUTER_STRIPE_PRICE, quantity: 1 });
    }
    if (includeHeadset) {
      items.push({ price: HEADSET_STRIPE_PRICE, quantity: 1 });
    }
    return items;
  }

  async getStripeSessionId(time, line_items) {
    const price = this.getPrice(time);
    let lineItems = [{ price: price, quantity: 1 }];
    const includeHeadset = line_items["headset"];
    const includeFlightcomputer = line_items["flightcomputer"];
    const includeCheatSheet = line_items["cheatsheet"];

    if (includeHeadset || includeFlightcomputer) {
      var packageItems = this.getPackagePrices(
        includeHeadset,
        includeFlightcomputer
      );
      _.each(packageItems, function(item) {
        lineItems.push(item);
      });
    }
    if (includeCheatSheet) {
      if (time === "24") {
        lineItems.push({ price: CHEATSHEET_FREE_STRIPE_PRICE, quantity: 1 });
      } else {
        lineItems.push({ price: CHEATSHEET_STRIPE_PRICE, quantity: 1 });
      }
    }

    let purchase_school_ref = utils.getCookieValue("purchase_school_ref");
    const language = document.documentElement.lang;

    const data = {
      products: lineItems,
      clientReferenceId: purchase_school_ref,
      language,
      successUrl: window.location.origin + '/login#success',
      cancelUrl: window.location.origin + '/login#canceled',
      time: time,
    };

    const response = await fetch(
      "https://api.ppltrainer.at/stripe/create-session-id.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    return await response.json();
  }

  stripeCheckout(time, line_items) {
    var that = this;
    this.getStripeSessionId(time, line_items).then((res) => {
      var sessionId = res.id;
      that.stripe
        .redirectToCheckout({
          sessionId,
        })
        .then(function(result) {
          if (result.error) {
            ReactPixel.trackCustom("Creditcard Error", {});
            const error = result.error.message;
            console.log(error);
          }
        });
    });
  }

  async fetchClientSecret(time, products, user) {
    let product = "basic";
    if (time === "6") product = "basic";
    if (time === "12") product = "premium";
    if (time === "24") product = "platin";

    let purchase_school_ref = utils.getCookieValue("purchase_school_ref");
    const language = document.documentElement.lang;

    const data = {
      product: product,
      user: user,
      products: products,
      clientReferenceId: purchase_school_ref,
      language,
    };

    const response = await fetch('https://api.ppltrainer.at/stripe/payment-intent.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  }

  sofortCheckout(time, products, options) {
    var that = this;
    this.fetchClientSecret(time, products, options.user).then(res => {
      that.stripe.confirmSofortPayment(
        res.clientSecret,
        {
          payment_method: {
            sofort: {
              country: options.country
            },
          },
          return_url: window.location.origin + '/login',
        }
      );
    });
  }

  checkSOFORTPayment() {
    var url = new URL(window.location);
    const that = this;
    var clientSecret = url.searchParams.get('payment_intent_client_secret');
    this.stripe.retrievePaymentIntent(clientSecret).then(function(response) {
      if (response.error) {
        // Handle error here
        that.notify(false);
      } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
        // Handle successful payment here
        that.notify(true);
        utils.deleteCookie("purchase_school_ref");
      }
    });

    if (this.inverval) return;
    this.inverval = window.setInterval(function() {
      that.checkSOFORTPayment();
    }, 1500);
  }

  getPrice(time) {
    let price = PAYMENT_MEDIUM_PRICE; // Default 12 Monate
    if (time === "6") price = PAYMENT_SMALL_PRICE; // 6 Monate
    if (time === "24") price = PAYMENT_LARGE_PRICE; // 24 Monate
    return price;
  }

  onPayPalError() {
    ReactPixel.trackCustom("PayPal Error", {});
    this.closeDialog();
    this.notify(false);
  }

  onPayPalSuccess() {
    this.closeDialog();
    this.notify(true);
  }

  onPayPalCancel() {
    ReactPixel.trackCustom("PayPal abgebrochen", {});
    this.closeDialog();
    this.notify(false);
  }

  render() {
    return (
      <Container textAlign="center" className="request-login">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
          />
        <div className="request-login-inner">

          <div>{ 
            this.state.showPaymentChooseMethodeDialog ? 
              // <PaymentModal defaultProduct={this.state.product} onSelect={this.onPaymentSelection} onAbort={this.closeDialog} onPayPalError={this.onPayPalError} onPayPalSuccess={this.onPayPalSuccess} onPayPalCancel={this.onPayPalCancel} />
              <OutOfCapacityModal onAbort={this.closeDialog} />
            :
              <button onClick={this.openDialogAndLoadStripeRessources} className="buy-btn">{i18n("buy_ppltrainer_here")}</button>
          }</div>

          <div className="no_account_available_link" onClick={this.openDialogAndLoadStripeRessources}>{i18n("no_account_available")}</div>

          <img className="payment-methods-login" title={i18n("zahlungsmoeglichkeiten")} alt={i18n("zahlungsmoeglichkeiten")} src={paymentLogo} />
        
        </div>
      </Container>
    );
  }
}

export default RequestLoginData;
