import React, { Component } from 'react';
import _ from 'underscore';
import { Icon, Modal } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';
import defaults from '../../../utils/defaults';

class ScenarioFormular extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      foundedElements: [],
      mode: this.props.mode,
      categories: this.props.categories,
    };

    this.onSearch = this.onSearch.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.collectAnswers = this.collectAnswers.bind(this);
  }

  componentDidMount() {
    let categories = this.state.categories;
    // merge with default categories
    const defaultCategories = defaults.defaultCategories.concat("NVFR");
    categories = categories.concat(defaultCategories);

    this.setState({ categories });
  }

  getFlatQuestionKeysInGivenLanguage() {
    const lang = document.documentElement.lang;
    return (this.props.answers &&  this.props.answers[lang] && Object.keys(this.props.answers[lang])) || []; 
  }

  collectAnswers(item) {
    const answers = [];
    const correctAnswer = item[item["Correct Answer"]];
    answers.push(item["A"]);
    answers.push(item["B"]);
    answers.push(item["C"]);
    answers.push(item["D"]);
    const index = answers.indexOf(correctAnswer);
    if (index !== -1) {
      answers.splice(index, 1);
      answers.unshift(correctAnswer);
    }
    return answers;
  }

  onSearch(options) {
    const { value } = options.target;
    this.setState({ searchTerm: value });
    this.fetchData(value);
  }

  fetchData(term) {
    const foundedElements = [];
    const flatQuestionKeysInGivenLanguage = this.getFlatQuestionKeysInGivenLanguage();
    for (let key in this.props.questions) {
      _.each(this.props.questions[key], (item) => {
        _.each(item.data, function(element) {
          let question = element.question || element["Question Stem"] || "";
          let questionID = element["Question ID"] || element["key"] || "";
          let questionItem = question.match(new RegExp(term, 'i')) || questionID.match(new RegExp(term, 'i'));
          if (flatQuestionKeysInGivenLanguage.indexOf(questionID) === -1 && questionItem) {
            foundedElements.push(element);
          }
        });
      });
    }
    this.setState({ foundedElements });
  }

  render() {
    const that = this;
    return <div className='add-scenario-question-container add-additional-document-container'>

      <Modal.Header>
        { this.props.mode === "addQuestion" ? i18n("select_question") : i18n("select_category") }
      </Modal.Header>
      <Modal.Description>
          <div>
            <div className='mm-description-item'>{ this.props.mode === "addQuestion" ? i18n("select_question_description") : i18n("select_category_description") }</div>
          </div>
        </Modal.Description>

      {
        this.props.mode === "addQuestion" ?
        <div className='result-list-container'>
          <div className={`fbl mm-search input-field-new-answer height-fix-scenario`}>
            <input type="search" value={this.state.searchTerm} placeholder={i18n("search_questions")} onChange={this.onSearch} />
            <Icon name="search" title={i18n("search")} />
          </div>

          <div className='result-content set-new-answer-assistance'>
            <ul>
              {
                this.state.searchTerm && 
                this.state.searchTerm.length > 2 && 
                  _.map(this.state.foundedElements, function (item, index) {
                    let question = item.question || item["Question Stem"] || "";
                    let questionID = item["Question ID"] || item["key"] || index;
                    let readableKey = item["Question ID"] || i18n('flightschool');
                    const correctAnswerIndex = item["correctAnswer"] || 0;
                    const answers = item.answers || that.collectAnswers(item);
                    const correctAnswer = answers[correctAnswerIndex];
                    const image = item.img;
                    return <li key={index + "_" + questionID} className="question-answer-item" onClick={() => that.props.onSelectItem({ question, questionID, readableKey, correctAnswerIndex, answers, correctAnswer, image })}>
                      <div className='question-item'>{question} <small>({readableKey})</small></div>
                      <div className='answers-containers correctAnswer'>{correctAnswer}</div>
                      <button className='btn btn-second'>{i18n('select_question')}</button>
                    </li>;
                  })
              }
            </ul>
          </div>
        </div>
        :
        <div className='set-new-answer-assistance-container'>
          <div className='result-content set-new-answer-assistance mt-15'>
            <ul>
              {
                _.map(this.state.categories, function (item, index) {
                  return <li key={index + "_" + item} className="question-answer-item fbc" onClick={() => that.props.onSelectItem({ item })}>
                    <div className='question-item fbl'>{i18n(item) || item}</div>
                    <button className='btn btn-second fbf'>{i18n('select_category')}</button>
                  </li>;
                })
              }
            </ul>
          </div>
        </div>
      }

      <div className="actions fixed-action-layout">
        <button onClick={() => this.props.onCancel()} className="btn btn-second">
          {i18n("cancel")}
        </button>
      </div>
    </div>
  }
}

export default ScenarioFormular;
