import React, { Component } from 'react';
import _ from 'underscore';
import { Icon, Modal, Select } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';

class AnswerFormular extends Component {
  constructor(props) {
    super(props);

    let customText = null, id = null, question = null, answer = null;
    // let image = null,  language = null;
    if (this.props.formularData) {
      ({ customText, id, question, answer } = this.props.formularData);
      // ({ image, language } = this.props.formularData);
    }

    this.state = {
      searchTerm: '',
      foundedElements: [],
      questionID: id || null,
      question: question || null,
      answer: answer || null,
      customText: customText || '',
      language: document.documentElement.lang,
    };

    this.onSearch = this.onSearch.bind(this);
    this.collectAnswers = this.collectAnswers.bind(this);
    this.update = this.update.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
  }

  collectAnswers(item) {
    const answers = [];
    const correctAnswer = item[item["Correct"]];
    answers.push(item["A"]);
    answers.push(item["B"]);
    answers.push(item["C"]);
    answers.push(item["D"]);
    const index = answers.indexOf(correctAnswer);
    if (index !== -1) {
      answers.splice(index, 1);
      answers.unshift(correctAnswer);
    }
    return answers;
  }

  onSearch(options) {
    const { value } = options.target;
    this.setState({ searchTerm: value });
    this.fetchData(value);
  }

  getLangOptions() {
    return _.map(['de','en','sk','nl','ro','cs','sl','fr','fi','is','es'], function(lang, index) {
      return { key: lang, value: lang, text: i18n(lang) };
    });
  }

  getFlatQuestionKeysInGivenLanguage() {
    const lang = document.documentElement.lang;
    return (this.props.answers &&  this.props.answers[lang] && Object.keys(this.props.answers[lang])) || []; 
  }

  fetchData(term) {
    const foundedElements = [];
    const flatQuestionKeysInGivenLanguage = this.getFlatQuestionKeysInGivenLanguage();
    for (let key in this.props.questions) {
      _.each(this.props.questions[key], (item) => {
        _.each(item.data, function(element) {
          let question = element.question || element["Question"] || "";
          let questionID = element["Question ID"] || element["key"] || "";
          let questionItem = question.match(new RegExp(term, 'i')) || questionID.match(new RegExp(term, 'i'));
          if (flatQuestionKeysInGivenLanguage.indexOf(questionID) === -1 && questionItem) {
            foundedElements.push(element);
          }
        });
      });
    }

    this.setState({ foundedElements });
  }

  selectQuestion(questionID, question, answer) {
    this.setState({
      foundedElements: [],
      searchTerm: '',
      questionID,
      question,
      answer,
      customText: '',
    });
  }

  previewImage(file) {
    var reader = new FileReader();
    reader.onload = function(){
        var preview = document.getElementById('image-preview');
        preview.src = reader.result;
        preview.style.display = 'block';
    };
    reader.readAsDataURL(file);
  }

  update(options, data) {
    const that = this;
    let name = options.target.name || (data && data.name);
    let value = options.target.value || (data && data.value);

    if (options.target.files) {
      const file = options.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      name = "image";
      value = formData;

      this.previewImage(file);
    }

    const obj = {};
    obj[name] = value;
    this.setState(obj, () => {
      const { customText, image, language} = this.state;
      this.props.updateContent({
        data: {"answer": { 
          customText,
          image,
          language,
          id: that.state.questionID,
          question: this.state.question,
          answer: this.state.answer,
        }},
        id: 'answer',
        questionID: this.state.questionID,
      });
    });
  }

  render() {
    const that = this;
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_answer_explanation")}</Modal.Header>
      <div className='mm-form-container create-answer'>

        <div className='headline'><span className='headline-description'>{i18n("help_students_with_your_custom_explanation")}</span></div>
        <div className='additional-new-answer-assistance-headline'>{i18n("search_for_question_and_ids")}</div>

        {
          !this.state.questionID ?
            <>
              <div className={`fbl mm-search input-field-new-answer`}>
                <input type="search" value={this.state.searchTerm} placeholder={i18n("search_questions")} onChange={this.onSearch} />
                <Icon name="search" title={i18n("search")} />
              </div>

              <div className='result-content set-new-answer-assistance'>
                <ul>
                  {
                    this.state.searchTerm && 
                    this.state.searchTerm.length > 2 && 
                      _.map(this.state.foundedElements, function (item, index) {
                        let question = item.question || item["Question"] || "";
                        let questionID = item["Question ID"] || item["key"] || index;
                        let readableKey = item["Question ID"] || i18n('flightschool');
                        const correctAnswerIndex = item["correctAnswer"] || 0;
                        const answers = item.answers || that.collectAnswers(item);
                        const correctAnswer = answers[correctAnswerIndex];
                        return <li key={index + "_" + questionID} className="question-answer-item" onClick={() => that.selectQuestion(questionID, question, correctAnswer)}>
                          <div className='question-item'>{question} <small>({readableKey})</small></div>
                          <div className='answers-containers correctAnswer'>{correctAnswer}</div>
                          <button className='btn btn-second'>{i18n('select_question')}</button>
                        </li>;
                      })
                  }
                </ul>
              </div>
            </>
            :
            <>
              <div className='question-answer-summary'>
                <div className='question-summary'>{this.state.question}</div>
                <div className='correctAnswer'>{this.state.answer}</div>
                <textarea value={this.state.customText} name="customText" onChange={(options) => this.update(options)}></textarea>
                <Select className="mt-5 select-lang-from-for-answers" value={this.state.language} placeholder={i18n('language')} name="language" options={this.getLangOptions()} onChange={(options, data) => this.update(options, data)} />
                <input className='image-input' type="file" lang={document.documentElement.lang} accept="image/*" id="image" placeholder={i18n("image")} name="image" onChange={(options) => this.update(options)}/><br/>
                <img id="image-preview" src="#" alt="preview" className="image-preview"/>
              </div>
            </>
        }
        
      </div>  
    </Modal.Content>
  }
}

export default AnswerFormular;
