import React, { Component } from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react'
import i18n from "../../utils/i18n";
import _ from 'underscore';

class PreviewBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getExpirationDate() {
    const { expirationDate } = this.props.userinfo.idToken.claims;
    return moment(expirationDate).format("DD MMM YYYY");
  }

  getRemainingDays() {
    const { expirationDate } = this.props.userinfo.idToken.claims;
    const diffInDays = moment(expirationDate).diff(moment(new Date()), 'days') + 1;
    return diffInDays;
  }

  getStudentGroupsCount() {
    return this.props.groups ? Object.keys(this.props.groups).length : 0;
  }
  getStudentCount() {
    return this.props.students ? Object.keys(this.props.students).length : 0;
  }
  getQuestionCount()  {
    return this.props.questions ? Object.keys(this.props.questions).length : 0;
  }
  getCategoryCount() {
    return (this.props.categories && this.props.categories.length) || 0;
  }
  getHomeworkCount() {
    return (this.props.homework && this.props.homework.length) || 0;
  }
  getExamCount() {
    return (this.props.exam && this.props.exam.length) || 0;
  }
  getAnswersCount() {
    if (this.props.answers && Object.keys(this.props.answers).length) {
      let count = 0;
      _.each(this.props.answers, function (answer) {
        count += Object.keys(answer).length;
      });
      return count;
    } else {
      return 0;
    }
  }
  getScenarioCount() {
    return (this.props.scenarios && Object.keys(this.props.scenarios).length) || 0;
  }
  getDocumentsCount() {
    return (this.props.documents && Object.keys(this.props.documents).length) || 0;
  }

  render() {
    return <div className='content-start'>
      {/*<div className="box">
        <div><Icon name="calendar alternate outline" /></div>
        <div className='box-content'>
          {i18n('account_valied_until')}: {this.getExpirationDate()} <br/>
          <span className='days-left'>{this.getRemainingDays()} {i18n('days')}</span>
        </div>
      </div>*/}
      <div className="box editable" onClick={e => this.props.onBoxClick('groups')}>
        <div><Icon name="users" /></div>
        <div className='box-content'>
          {i18n('flightschool_classes')} <br/>
          <b>{this.getStudentGroupsCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('students')}>
        <div><Icon name="user" /></div>
        <div className='box-content'>
          {i18n('all_flight_students')} <br/>
          <b>{this.getStudentCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('categories')}>
        <div><Icon name="list layout" /></div>
        <div className='box-content'>
          {i18n('all_question_categories')} <br/>
          <b>{this.getCategoryCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('questions')}>
        <div><Icon name="question circle" /></div>
        <div className='box-content'>
          {i18n('questions')} <br/>
          <b>{this.getQuestionCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('homework')}>
        <div><Icon name="student" /></div>
        <div className='box-content'>
          {i18n('homework')} <br/>
          <b>{this.getHomeworkCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('exam')}>
        <div><Icon name="book" /></div>
        <div className='box-content'>
          {i18n('exams')} <br/>
          <b>{this.getExamCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('answer')}>
        <div><Icon name="comment alternate" /></div>
        <div className='box-content'>
          {i18n('answer_assistance')} <br/>
          <b>{this.getAnswersCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('scenario')}>
        <div><Icon name="sitemap" /></div>
        <div className='box-content'>
          {i18n('scenarios')} <br/>
          <b>{this.getScenarioCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
      <div className="box editable" onClick={e => this.props.onBoxClick('document')}>
        <div><Icon name="file" /></div>
        <div className='box-content'>
          {i18n('documents')} <br/>
          <b>{this.getDocumentsCount()}</b>
        </div>
        <div className='add-icon'>
          <Icon name="plus" />
        </div>
      </div>
    </div>
  }
}

export default PreviewBoxes;
