import React, { Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import i18n from '../../../utils/i18n';
import defaults from '../../../utils/defaults';
import _ from 'underscore';

class CategoriesFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
    };

    this.defaultCategories = defaults.defaultCategories.concat("NVFR");
    this.update = this.update.bind(this);
  }

  update(options) {
    const { name, value } = options.target;
    const obj = {};

    // check if there is a translation (key) in the background
    const defaultCategory = _.find(this.defaultCategories, function(cat) {
      return i18n(cat) === value;
    });

    obj[name] = defaultCategory || value;
    this.setState(obj, () => {
      this.props.updateContent({data: this.state, id: 'category'});
    });
  }

  render() {
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_category")}</Modal.Header>
      <Modal.Description>
        <div>
          <div className='mm-description-item'>{i18n("create_new_category_hint")}</div>
        </div>
      </Modal.Description>
      <div className='mm-form-container'>
        <form>
          <div className='mm-form-item'>
            <Icon name="list" title={i18n("category")} />
            <input type="text" id="category" placeholder={i18n("category")} name="category" onChange={(options) => this.update(options)} />
          </div>
        </form>
      </div>  
    </Modal.Content>
  }
}

export default CategoriesFormular;
