import React, { Component } from 'react';

import i18n from "../utils/i18n";

class AdvancedSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question_count: 16,
      question_count_radio: 16
    };

    this.updateQuestionCount = this.updateQuestionCount.bind(this);
  }

  updateQuestionCount(options) {
    const state = {};
    state[options.target.name] = parseInt(options.target.value);
    this.setState(state);
    this.props.onChange(state);
  }

  render() {
    return (
      <div className="advanced_settings_block">
        <div className="advanced_settiongs_container">
          <span>{i18n("full_count_for_exam")}: <b>{this.state.question_count}</b></span>
          <input onChange={(options) => this.updateQuestionCount(options)} min="1" max="50" name="question_count" step="1" type="range" value={this.state.question_count}></input>
        </div>
        <div className="advanced_settiongs_container">
          <span>{i18n("radio_count_questions_for_exam")}: <b>{this.state.question_count_radio}</b></span>
          <input onChange={(options) => this.updateQuestionCount(options)} min="1" max="200" name="question_count_radio" step="1" type="range" value={this.state.question_count_radio}></input>
        </div>
      </div>
    );
  }
}

export default AdvancedSettings;
