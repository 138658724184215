const baseCategories = ["ALW", "HPL", "MET", "COM", "OPR", "FPP", "AGK", "NAV"];
const withPrefix = (prefix) => [prefix, ...baseCategories];

export default {
  defaultCategories: ["AGK", "PFA", "PFB", "PFH", ...baseCategories],
  printCategories: withPrefix("PFA"),
  defaultBPLCategories: withPrefix("PFB"),
  defaultSPLCategories: withPrefix("PFA"),
  defaultPPLHCategories: withPrefix("PFH"),
  defaultPPLACategories: withPrefix("PFA")
};