import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '@okta/okta-signin-widget/dist/css/okta-theme.css';

import './login.css';

class SignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    el.className = "signInWidget";
    this.widget = new OktaSignIn({
      baseUrl: this.props.baseUrl,
      logo: './img/logo.png',
      features: {
        autoFocus: false
      },
      language: document.documentElement.lang,
      i18n: {
        'en': {
          'primaryauth.username.placeholder': 'E-Mail-Address'
        },
        'de': {
          'primaryauth.username.placeholder': 'E-Mail-Adresse'
        },
        'nl': {
          'primaryauth.username.placeholder': 'E-mail adres',
          'primaryauth.title': "Aanmelden",
          'primaryauth.username.tooltip': "E-mail adres",
          'primaryauth.password.placeholder': "Paswoord",
          'primaryauth.password.tooltip': "Paswoord",
          'primaryauth.submit': "Aanmelden",
          'remember': "Herinner mij",
          'needhelp': "Hulp nodig bij het aanmelden?",
          'forgotpassword': "Paswoord vergeten?",
          'password.reset': "Reset Wachtwoord",
          'password.forgot.email.or.username.placeholder': "E-mail adres",
          'password.forgot.sendEmail': "Reset via e-mail",
          'goback': "Terug naar aanmelden"
        },
        'sk': {
          'primaryauth.username.placeholder': 'E-mailová adresa',
          'primaryauth.title': "Zaregistrujte sa",
          'primaryauth.username.tooltip': "E-mailová adresa",
          'primaryauth.password.placeholder': "Heslo",
          'primaryauth.password.tooltip': "Heslo",
          'primaryauth.submit': "Zaregistrujte sa",
          'remember': "Pamätaj si ma",
          'needhelp': "Potrebujete pomoc s registráciou?",
          'forgotpassword': "Zabudli ste heslo?",
          'password.reset': "Obnovenie hesla",
          'password.forgot.email.or.username.placeholder': "E-mailová adresa",
          'password.forgot.sendEmail': "Obnovenie e-mailom",
          'goback': "Späť na prihlásenie"
        },
        'cs': {
          'primaryauth.username.placeholder': 'E-mailová adresa'
        },
        'ro': {
          'primaryauth.username.placeholder': 'Adresa de e-mail'
        }
      }
    });

    // handle focus problem with Safari/iPhone/Mac
    var urlparameter = URLSearchParams && new URLSearchParams(window.location.search);
    if (urlparameter) {
      var urlParameterValue = urlparameter.get('product');
      if (urlParameterValue) {
        this.widget.on('afterRender', function (context) {
          var textInputEl = document.getElementById('okta-signin-username');
          if (textInputEl === document.activeElement) {
            textInputEl.blur();
          } else {
            var unsetFocus = function () { 
              document.activeElement.blur();
            };
            var onElMouseOver = function () {
              textInputEl.removeEventListener("focus", unsetFocus);
            }
            textInputEl.addEventListener("focus", unsetFocus);
            textInputEl.addEventListener("mouseover", onElMouseOver);
          }
        });    
      }
    }

    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div />;
  }
}

export default SignInWidget;