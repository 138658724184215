import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import _ from 'underscore';
import i18n from "../utils/i18n";

class SavedQuestionOverlay extends Component {

  constructor(props) {
    super(props);
    const question = this.props.question;
    let correctAnswer = 0;

    if (question["Correct"]) {
      switch(question["Correct"]) {
        case "A": 
          correctAnswer = 0;
          break;
        case "B": 
          correctAnswer = 1;
          break;
        case "C": 
          correctAnswer = 2;
          break;
        case "D": 
          correctAnswer = 3;
          break;
        default: 
          correctAnswer = null;
      }
    }

    const imgURL = this.getFullImageURL(question.img);

    this.state = {
      documentPath: 'data/24.6/documents',
      question: question.question || question["Question"],
      answers: question.answers || [ question["A"],question["B"],question["C"],question["D"] ],
      correctAnswer: question.correctAnswer || correctAnswer,
      img: imgURL,
    };
  }

  getFullImageURL(image) {
    if (image) {
      if (_.isArray(image)) {
        return _.map(image, function (img) {
          const fullImg = (img && img.toUpperCase && img.toUpperCase()) || img;
          const image = fullImg.split(".")[0] + ".jpg"; // split UPPERCASE.jpg
          return image;
        });
      }
      if (/^https?:\/\//.test(image)) {
        return image;
      }
    }
    
    return null;
  }

  render() {
    const that = this;
    return (
      <div className='saved-question-settings-overlay'>
        <div className='saved-question-settings-inner'>
          <Icon name="close" onClick={this.props.onClose} className="info-circle-menu saved-question-close-icon" />
          <div className='saved-question-content-container'>
            <div className='settings-item-header'>{i18n('saved_question')}</div>
            <b>{this.state.question}</b>
            <div className='saved-question-content-answers'>
              {
                _.map(this.state.answers, function(answer, key) {
                  return (
                    <div className={`${key === that.state.correctAnswer && "correct-answer"}`} key={key}>
                      {answer}
                    </div>
                  );
                })
              }
              {
                // TODO potentially an issue with this.state.fullScreenImage.type === "url" (see question.js)
                this.state.img && (
                  <div className='saved-question-image-container'>
                    {
                      _.isArray(that.state.img) ?
                        that.state.img.map(img => (
                          <img 
                            key={img} 
                            src={`./${that.state.documentPath}/${img}`} 
                            className="question-img" 
                            alt="saved-question"
                          />
                        ))
                      :
                      <img src={that.state.img} alt="saved-question" />
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SavedQuestionOverlay;
