import React, { Component } from 'react';
import { Icon, Modal, Loader } from 'semantic-ui-react';
import i18n from '../../utils/i18n';

class CreateItemModal extends Component {
  constructor(props) {
    super(props);
    this.confirmClose = this.confirmClose.bind(this);
    this.save = this.save.bind(this);
    this.unsetContent = this.unsetContent.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  confirmClose() {
    // TODO confirm closing modal
    this.unsetContent();
    this.props.onClose();
  }

  unsetContent() {
    this.setState({
      data: null,
      id: null,
    });
  }

  save() {
    const data = this.state;
    if (data) {
      this.props.onSave({ data });
      // TTODO too early to cleanup as there could be an issue from the backend
      // this.unsetContent();
    }
  }

  updateContent(options) {
    this.setState(options);
  }

  render() {
    if (!this.props.formular) return null;
    return <Modal
      className='manager-modal slide-in-right'
      open={this.props.open}
      dimmer="inverted"
      onClose={this.confirmClose}
      closeOnDimmerClick={false}
    >

      <div className="loading-dimmer ui modal active">
        <Loader active />
      </div>

      <this.props.formular 
        {...this.props} 
        updateContent={this.updateContent}
      />

      <Modal.Actions>
        {
          !this.props.hideSaveAction &&
            <button onClick={() => this.save()} className="btn btn-primary btn-create-item-model">
              {this.props.formularActionLabel || i18n("save")} <Icon name='save' />
            </button>
        }
        <button onClick={() => this.confirmClose()} className="btn btn-second">
          {i18n("cancel")}
        </button>
      </Modal.Actions>

    </Modal>
  }
}

export default CreateItemModal;
