import React, { Component } from 'react';
import { List, Checkbox, Dropdown, Input } from 'semantic-ui-react';
import _ from 'underscore';
import PopupDialog from './PopupDialog';

import i18n from '../utils/i18n';
import defaults from '../utils/defaults';
import API from '../utils/resources/api';

class ChooseCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      country: "AT",
      language: "de",
      languages: [
        {"text": i18n("deutsch"), "value": "de"},
        {"text": i18n("englisch"), "value": "en"},
        {"text": i18n("rumaenisch"), "value": "ro"},
        {"text": i18n("slowenisch"), "value": "sl"},
        {"text": i18n("franzoesisch"), "value": "fr"},
        {"text": i18n("niederlaendisch"), "value": "nl"},
      ],
      countries: [
        {"text": i18n("austria"), "value": "AT"},
        {"text": i18n("germany"), "value": "DE"},
      ],
      motor: true,
      heli: false,
      segel: false,
      ballon: false,
      radio: false,
      radioTyp: "AT_bfz",
      radioTypes: [
        {"text": "BZF I", "value": "DE_bzf1"},
        {"text": "BZF II", "value": "DE_bzf2"},
        {"text": "AZF", "value": "DE_azf"},
        {"text": "BFZ", "value": "AT_bfz"},
        {"text": "EFZ", "value": "AT_efz"},
        {"text": "AFZ", "value": "AT_afz"},
        {"text": "Upgrade BFZ - EFZ", "value": "AT_upgrade-bfz-efz"},
        {"text": "Upgrade BFZ - AFZ", "value": "AT_upgrade-bfz-afz"},
        {"text": "Upgrade EFZ - AFZ", "value": "AT_upgrade-efz-afz"},
      ],
      radioIsAvailableInCountry: true,
      estimateExamMonthsOptions: this.getEstimatedExamMonthOptions(),
      estimatedExamMonth: this.getInitialEstimatedExamMonth(),
      examDate: null, // this.getInitialExamDate(),
    };

    this.start = this.start.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setLocalStorage = this.setLocalStorage.bind(this);
    this.getLocalStorage = this.getLocalStorage.bind(this);
    this.setCountry = this.setCountry.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.getLanguages = this.getLanguages.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getRadioTypes = this.getRadioTypes.bind(this);
    this.setRadioTyp = this.setRadioTyp.bind(this);
    this.setEstimatedExamMonth = this.setEstimatedExamMonth.bind(this);
    this.setExamDate = this.setExamDate.bind(this);

  }

  getEstimatedExamMonthOptions() {
    const options = [];
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const months = ["januar", "februar", "märz", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "dezember"];
    for (let year = currentYear; year <= currentYear + 2; year++) {
      for (let month = (year === currentYear ? currentMonth : 0); month < 12; month++) {
        const monthKey = months[month];
        const monthText = i18n(monthKey);
        const value = `${year}-${String(month + 1).padStart(2, '0')}`;
        options.push({ text: `${monthText} ${year}`, value });
      }
    }
    return options;
  }

  getInitialEstimatedExamMonth() {
     // Estimate exam date in 9 months (2025-12)
    let estimatedExamDate = new Date(new Date().setMonth(new Date().getMonth() + 9));
    let formattedDate = estimatedExamDate.getFullYear() + '-' + String(estimatedExamDate.getMonth() + 1).padStart(2, '0');
    return formattedDate;
  }

  getInitialExamDate() {
    // Estimate exam date in 9 months (2025-12-31)
    return new Date(new Date().setMonth(new Date().getMonth() + 9)).toISOString().split('T')[0];
  }

  componentDidMount() {
    const state = this.getLocalStorage();
    if (!state) {
      this.setState({ active: true });
    } else {
      this.setState(state);
    }
  }

  setCountry(options, data) {
    var country = data.value;
    let radioTyp; // Default AT
    switch(country) {
      case "DE":
        radioTyp = "DE_bzf1";
        break;
      case "AT":
        radioTyp = "AT_bfz";
        break;
      default:
        radioTyp = null;
    }
    this.setState({
      country,
      radioTyp
    });
  }

  setLanguage(options, data) {
    var language = data.value;
    this.setState({ language });
  }

  onChange(options, element) {
    this.setState({ [element.name]: element.checked });
  }

  setLocalStorage() {
    const currentLS = this.getLocalStorage();
    const data = {
      motor: this.state.motor,
      heli: this.state.heli,
      segel: this.state.segel,
      ballon: this.state.ballon,
      country: this.state.country,
      language: this.state.language,
      radio: this.state.radio,
      radioTyp: this.state.radioTyp,
      estimatedExamMonth: this.state.estimatedExamMonth,
      examDate: this.state.examDate,
    };

    if (!_.isEqual(currentLS, data)) {
      this.clearCompletedQuestions();
      localStorage.setItem('ppltrainer', JSON.stringify(data));
    }

    API.syncSettings();
  }

  clearCompletedQuestions() {
    const hardQuestionsPool = JSON.parse(localStorage.getItem('hardQuestionsPool')) || {};
    _.each(defaults.defaultCategories.concat("NVFR"), function(key) {
      localStorage.removeItem(key);
      localStorage.removeItem("FS_" + key);
      localStorage.removeItem("OLDER_" + key);
      if (hardQuestionsPool[key]) {
        delete hardQuestionsPool[key];
      }
    });
    localStorage.setItem('hardQuestionsPool', JSON.stringify(hardQuestionsPool));
  }

  getLocalStorage() {
    return JSON.parse(localStorage.getItem('ppltrainer'));
  }

  start() {
    if (this.state.motor || this.state.segel || this.state.ballon || this.state.heli || this.state.radio) {
      this.setLocalStorage();
      this.setState({ active: false });
      this.props.changed();
    }
  }

  getLanguages() {
    return this.state.languages;
  }

  getCountries() {
    return this.state.countries;
  }

  getEstimatedExamMonths() {
    return this.state.estimateExamMonthsOptions;
  }

  getRadioTypes() {
    var that = this;
    var values = _.filter(this.state.radioTypes, function (entry) {
      return entry.value.indexOf(that.state.country) === 0;
    });
    return values;
  }

  setExamDate(options, data) {
    var examDate = data.value;
    this.setState({ examDate });
  }

  setEstimatedExamMonth(options, data) {
    var estimatedExamMonth = data.value;
    this.setState({ estimatedExamMonth });
  }

  setRadioTyp(options, data) {
    var radioTyp = data.value;
    this.setState({ radioTyp });
  }

  radioIsAvailableInCountry() {
    const availableCountries = ['AT', 'DE'];
    return availableCountries.indexOf(this.state.country) >= 0;
  }

  render() {
    return (
      <PopupDialog active={this.state.active || this.props.forceOpen} className="settings-initial-modal">
        <img className="ppl-logo" src="./img/logo.png" alt="Logo PPLTrainer"/>
        <label className="center">{i18n("which_catalog_do_you_want_to_use")}</label>

        <div className='question-settings-container'>

          <List divided relaxed>
            <List.Item>
              <List.Icon name='plane' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='div' className="fbc">
                  <span className="fbl">{i18n("motorflugschein")}</span>
                  <span className="fbf">(PPL-A, LAPL) <Checkbox toggle checked={this.state.motor} name="motor" onChange={this.onChange} /></span>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='rocket' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='div' className="fbc">
                  <span className="fbl">{i18n("helikopterschein")}</span>
                  <span className="fbf">(PPL-H) <Checkbox toggle checked={this.state.heli} name="heli" onChange={this.onChange} /></span>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='paper plane' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='div' className="fbc">
                  <span className="fbl">{i18n("segelflugschein")}</span>
                  <span className="fbf">(SPL) <Checkbox toggle checked={this.state.segel} name="segel" onChange={this.onChange} /></span>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='lightbulb' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='div' className="fbc">
                  <span className="fbl">{i18n("ballonfuehrerschein")}</span>
                  <span className="fbf">(BPL) <Checkbox toggle checked={this.state.ballon} name="ballon" onChange={this.onChange} /></span>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='microphone' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='div' className="fbc">
                  <span className="fbl">{i18n("flugfunkzeugnis")}</span>
                  <span className="fbf"><Checkbox toggle checked={this.state.radio} name="radio" onChange={this.onChange} /></span>
                </List.Header>
              </List.Content>
            </List.Item>
          </List>

          <div className="question-settings-item-container">
            <label>{i18n("choose_your_language")}</label>
            <Dropdown 
              value={this.state.language}
              onChange={this.setLanguage}
              selection
              options={this.getLanguages()}
            />
          </div>

          <div className="question-settings-item-container">
            <label>{i18n("choose_estimated_exam_date")}</label>
            {
              // If exact exam date is availabe, render it, otherwise estimate it by month
              this.state.examDate ?
                <Input
                  className='estimated-exam-date'
                  type="date"
                  value={this.state.examDate}
                  onChange={this.setExamDate}
                />
              :
                <Dropdown 
                  value={this.state.estimatedExamMonth}
                  onChange={this.setEstimatedExamMonth}
                  selection
                  options={this.getEstimatedExamMonths()}
                />
            }
          </div>

          {
            this.state.radio && this.radioIsAvailableInCountry() ?
            <>
              <div className="question-settings-item-container">
                <label>{i18n("where_will_you_take_the_radiotelephone_exam")}</label>
                <Dropdown 
                  value={this.state.country}
                  onChange={this.setCountry}
                  selection
                  options={this.getCountries()}
                />
              </div>

              <div className="question-settings-item-container">
                <label>{i18n("select_radio_certificate")}</label>
                <Dropdown 
                  value={this.state.radioTyp}
                  onChange={this.setRadioTyp}
                  selection
                  options={this.getRadioTypes()}
                />
              </div>
            </>
            : null
          }

        </div>

        <label className="center hint">{i18n("if_you_make_changes_you_will_reset_statistics")}</label>

        <button className="ui fluid btn-primary" onClick={this.start}>{i18n("lets_go")}</button>
      </PopupDialog>
    );
  }
}

export default ChooseCatalog;
