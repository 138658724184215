import $ from 'jquery';

var keys = {
  "Fragenkatalog": {
    "de": "Fragenkatalog",
    "en": "Question Catalogue",
    "sk": "Dotazník",
    "nl": "Vragenlijst",
    "ro": "Chestionar",
    "cs": "Dotazník",
    "sl": "Katalog vprašanj",
    "fr": "Catalogue de questions",
    "fi": "Kysymysluettelo",
    "is": "Spurningaskrá",
    "es": "Catálogo de preguntas"
  },
  "GeneralSettings": {
    "de": "Allgemeine Einstellungen",
    "en": "General Settings",
    "sk": "Všeobecné nastavenia",
    "nl": "Algemene instellingen",
    "ro": "Setări generale",
    "cs": "Obecná nastavení",
    "sl": "Splošne nastavitve",
    "fr": "Paramètres généraux",
    "fi": "Yleiset asetukset",
    "is": "Almennar stillingar",
    "es": "Configuración general"
  },
  "change_questiondatabase": {
    "de": "Fragenkatalog auswählen",
    "en": "Select question database",
    "sk": "Vyberte databázi otázek",
    "nl": "Selecteer vraagdatabase",
    "ro": "Selectați baza de date a întrebărilor",
    "cs": "Vybrat databázi otázek",
    "sl": "Izberite bazo podatkov za vprašanja",
    "fr": "Sélectionner la base de données des questions",
    "fi": "Valitse kysymystietokanta",
    "is": "Veldu spurningagagnagrunn",
    "es": "Seleccionar base de datos de preguntas"
  },
  "PPL_Fragenkatalog": {
    "de": "PPL Fragenkatalog",
    "en": "PPL Question Catalogue",
    "sk": "PPL Dotazník",
    "nl": "PPL Vragenlijst",
    "ro": "PPL Chestionar",
    "cs": "PPL Dotazník",
    "sl": "Katalog vprašanj PPL",
    "fr": "Catalogue de questions PPL",
    "fi": "PPL kysymysluettlo",
    "is": "PPL spurningaskrá",
    "es": "Catálogo de preguntas de PPL"
  },
  "motorflugschein": {
    "de": "Motorflugschein",
    "en": "Fixed Wing Aircraft License",
    "sk": "Licencia pilota motorových lietadiel",
    "nl": "Gemotoriseerd vlieger",
    "ro": "Licență de pilot cu motor",
    "cs": "Průkaz pilota motorových letadel",
    "sl": "Licenca za letala s fiksnimi krili",
    "fr": "Licence d'aéronef à voilure fixe",
    "fi": "Kiinteäsiipisten ilma-alusten lupakirja",
    "is": "Flugvélaskírteini með föstum vængjum",
    "es": "Licencia de avión de ala fija"
  },
  "helikopterschein": {
    "de": "Helikopterschein",
    "en": "Helicopter License",
    "sk": "Vrtuľníkový preukaz",
    "nl": "Helikopterbrevet",
    "ro": "Licență de elicopter",
    "cs": "Průkaz způsobilosti vrtulníku",
    "sl": "Licenca za helikopter",
    "fr": "Licence hélicoptère",
    "fi": "Helikopterin lupakirja",
    "is": "Þyrluleyfi",
    "es": "Licencia de helicóptero"
  },
  "segelflugschein": {
    "de": "Segelflugschein",
    "en": "Glider License",
    "sk": "Plachtársky preukaz",
    "nl": "Zweefvliegbrevet",
    "ro": "Licență de planorism",
    "cs": "Plachtařský průkaz",
    "sl": "Dovoljenje za jadralno letalo",
    "fr": "Licence Planeur",
    "fi": "Purjelentäjän lupakirja",
    "is": "Svifflugsleyfi",
    "es": "Licencia de planeador"
  },
  "ballonfuehrerschein": {
    "de": "Ballonführerschein",
    "en": "Hotair Balloon License",
    "sk": "Licencia pilota balónov",
    "nl": "Ballonvaarder",
    "ro": "Licența de pilot de baloane",
    "cs": "Licence pilota balonů",
    "sl": "Licenca za balon na vroč zrak",
    "fr": "Licence de ballon à air chaud",
    "fi": "Kuumailmalentäjän lupakirja",
    "is": "Loftbelgsleyfi",
    "es": "Licencia de globo aerostático"
  },
  "flugfunkzeugnis": {
    "de": "Sprechfunkzeugnis",
    "en": "Aeronautical Radio Operator Certificate",
    "sk": "Aeronautical Radio Operator Certificate",
    "nl": "Aeronautical Radio Operator Certificate",
    "ro": "Aeronautical Radio Operator Certificate",
    "cs": "Aeronautical Radio Operator Certificate",
    "sl": "Certifikat letalskega radijskega operaterja",
    "fr": "Certificat d'opérateur radio aéronautique",
    "fi": "Ilmailuradio-operaattorin lupakirja",
    "is": "Flugrekandaskírteini",
    "es": "Certificado de Operador de Radio Aeronáutica"
  },
  "kategorien": {
    "de": "Kategorien",
    "en": "Categories",
    "sk": "Kategórie",
    "nl": "Categorieën",
    "ro": "Categorii",
    "cs": "Kategorie",
    "sl": "Kategorije",
    "fr": "Catégories",
    "fi": "Kategoriat",
    "is": "Flokkar",
    "es": "Categorías"
  },
  "sprachen": {
    "de": "Sprachen",
    "en": "Languages",
    "sk": "Jazyky",
    "nl": "Talen",
    "ro": "Limbi",
    "cs": "Jazyky",
    "sl": "Jeziki",
    "fr": "Langues",
    "fi": "Kielet",
    "is": "Tungumál",
    "es": "Idiomas"
  },
  "englisch": {
    "de": "Englisch",
    "en": "English",
    "sk": "Angličtina",
    "nl": "English",
    "ro": "Engleză",
    "cs": "Angličtina",
    "sl": "angleščina",
    "fr": "Anglais",
    "fi": "Englanti",
    "is": "Enska",
    "es": "Inglés"
  },
  "deutsch": {
    "de": "Deutsch",
    "en": "German",
    "sk": "Nemčina",
    "nl": "Duits",
    "ro": "Germană",
    "cs": "Němčina",
    "sl": "nemščina",
    "fr": "Allemand",
    "fi": "Saksa",
    "is": "þýska, Þjóðverji, þýskur",
    "es": "Alemán"
  },
  "rumaenisch": {
    "de": "Rumänisch",
    "en": "Romanian",
    "sk": "Rumunský",
    "nl": "Roemeens",
    "ro": "Română",
    "cs": "Rumunština",
    "sl": "romunščina",
    "fr": "Roumain",
    "fi": "Romania",
    "is": "rúmenska",
    "es": "Rumano"
  },
  "slowenisch": {
    "de": "Slowenisch",
    "en": "Slovenian",
    "sk": "Slovinčina",
    "nl": "Sloveens",
    "ro": "Slovenă",
    "cs": "Slovinština",
    "sl": "slovenščina",
    "fr": "Slovène",
    "fi": "Slovenia",
    "is": "slóvenska",
    "es": "Esloveno"
  },
  "franzoesisch": {
    "de": "Französisch",
    "en": "French",
    "sk": "Francúzsky",
    "nl": "Frans",
    "ro": "Franceză",
    "cs": "Francouzština",
    "sl": "Francoski",
    "fr": "Français",
    "fi": "Ranska",
    "is": "franska",
    "es": "Francés"
  },
  "niederlaendisch": {
    "de": "Niederländisch",
    "en": "Dutch",
    "sk": "Holandský",
    "nl": "Nederlands",
    "ro": "Olandeză",
    "cs": "Holandský",
    "sl": "Nizozemska",
    "fr": "Néerlandais",
    "fi": "Hollanti",
    "is": "hollenska",
    "es": "Holandés"
  },
  "zahlungsmoeglichkeiten": {
    "de": "Zahlungsmöglichkeiten",
    "en": "Payment options",
    "sk": "Možnosti platby",
    "nl": "Betaalmogelijkheden",
    "ro": "Opțiuni de plată",
    "cs": "Možnost platby",
    "sl": "Opcije plačil",
    "fr": "Options de paiement",
    "fi": "Maksuvaihtoehdot",
    "is": "Greiðslumöguleikar",
    "es": "Opciones de pago"
  },
  "payment_not_successfull": {
    "de": "Ihre Zahlung war leider nicht erfolgreich.",
    "en": "Your payment was not successful",
    "sk": "Vaša platba bohužiaľ nebola úspešná.",
    "nl": "Helaas, uw betaling is niet gelukt.",
    "ro": "Vaše platba bohužel nebyla úspěšná.",
    "cs": "Din păcate, plata dvs. nu a fost efectuată cu succes.",
    "sl": "Plačilo ni bilo uspešno",
    "fr": "Votre paiement n'a pas abouti",
    "fi": "Maksua ei suoritettu",
    "is": "Greiðslan þín tókst ekki",
    "es": "Tu pago no se realizó con éxito"
  },
  "buy_ppltrainer_here": {
    "de": "Zugang kaufen",
    "en": "Buy here",
    "sk": "Kúpiť tu",
    "nl": "Hier kopen",
    "ro": "Cumpărați aici",
    "cs": "Koupit zde",
    "sl": "Kupi",
    "fr": "Achetez ici",
    "fi": "Osta täältä",
    "is": "Kauptu hér",
    "es": "Compra aquí"
  },
  "buy_ppltrainer": {
    "de": "PPL Trainer kaufen",
    "en": "Purchase PPL Trainer",
    "sk": "Kúpiť PPL Trainer",
    "nl": "PPL-opleider kopen",
    "ro": "Cumpărați PPL Trainer",
    "cs": "Koupit trenažér PPL",
    "sl": "Kupi PPL trenerja",
    "fr": "Acheter PPL Trainer",
    "fi": "Osta PPL Trainer",
    "is": "Keyptu PPL þjálfara",
    "es": "Comprar PPL Trainer"
  },
  "activate_coupon": {
    "de": "Einlösen",
    "en": "Redeem",
    "sk": "Vykúpiť",
    "nl": "Inwisselen",
    "ro": "Răscumpărați",
    "cs": "Vyměnit",
    "sl": "Unovči",
    "fr": "Rédiger",
    "fi": "Lunasta",
    "is": "Innleysa",
    "es": "Activar cupón"
  },
  "nextStep": {
    "de": "Nächster Schritt",
    "en": "Next step",
    "sk": "Ďalší krok",
    "nl": "Volgende stap",
    "ro": "Další krok",
    "cs": "Dalši",
    "sl": "Naslednji korak",
    "fr": "Étape suivante",
    "fi": "Seuraava askel",
    "is": "Næsta skref",
    "es": "Siguiente paso"
  },
  "creditcard": {
    "de": "Kreditkarte",
    "en": "Creditcard",
    "sk": "Kreditná karta",
    "nl": "Kredietkaart",
    "ro": "Card de credit",
    "cs": "Kreditní karta",
    "sl": "Kreditna kartica",
    "fr": "Carte de crédit",
    "fi": "Luottokortti",
    "is": "Kreditkort",
    "es": "Tarjeta de crédito"
  },
  "month_12": {
    "de": "12 Monate",
    "en": "12 Months",
    "sk": "12 mesiacov",
    "nl": "12 maanden termijn",
    "ro": "Termen de 12 luni",
    "cs": "12 měsíců",
    "sl": "12 mesecev",
    "fr": "12 mois",
    "fi": "12 kuukautta",
    "is": "12 mánuðir",
    "es": "12 meses"
  },
  "month_24": {
    "de": "24 Monate",
    "en": "24 Months",
    "sk": "24 mesiacov",
    "nl": "24 maanden termijn",
    "ro": "Termen de 24 luni",
    "cs": "24 měsíců",
    "sl": "24 mesecev",
    "fr": "24 mois",
    "fi": "24 kuukautta",
    "is": "24 mánuðir",
    "es": "24 meses"
  },
  "month_6": {
    "de": "6 Monate",
    "en": "6 Months",
    "sk": "6 mesiacov",
    "nl": "6 maanden termijn",
    "ro": "Termen de 6 luni",
    "cs": "6 měsíců",
    "sl": "6 mesecev",
    "fr": "6 mois",
    "fi": "6 kuukautta",
    "is": "6 mánuðir",
    "es": "6 meses"
  },
  "runtime_description": {
    "de": "Wie lange möchtest du deinen Zugang nutzen?",
    "en": "How long would you like to use your access?",
    "sk": "Ako dlho chcete používať svoj prístup?",
    "nl": "Hoelang wil je toegang gebruiken?",
    "ro": "Cât timp doriți să utilizați accesul?",
    "cs": "Jak dlouho chcete využívat svůj přístup?",
    "sl": "Kako dolgo želite uporabljati svoj dostop?",
    "fr": "Combien de temps souhaitez-vous utiliser votre accès ?",
    "fi": "Kuinka kauan haluat käyttää pääsyäsi?",
    "is": "Hversu lengi viltu nota aðganginn þinn?",
    "es": "¿Cuánto tiempo te gustaría usar tu acceso?"
  },
  "paymentmethod": {
    "de": "Zahlungsart",
    "en": "Payment method",
    "sk": "Spôsob platby",
    "nl": "Betaalmethode",
    "ro": "Metodă de plată",
    "cs": "Způsob platby",
    "sl": "Način plačila",
    "fr": "Mode de paiement",
    "fi": "Maksutapa",
    "is": "Greiðslumáti",
    "es": "Método de pago"
  },
  "paymentmethod_description": {
    "de": "Wie möchtest du deinen Zugang bezahlen?",
    "en": "How would you like to pay for your access?",
    "sk": "Ako chcete zaplatiť za prístup?",
    "nl": "Hoe wil je voor je toegang betalen?",
    "ro": "Cum doriți să plătiți pentru accesul dvs.?",
    "cs": "Jak chcete zaplatit za přístup?",
    "sl": "Kako želite plačati za svoj dostop?",
    "fr": "Comment souhaitez-vous payer votre accès ?",
    "fi": "Miten haluat maksaa pääsystäsi?",
    "is": "Hvernig viltu borga fyrir aðganginn þinn?",
    "es": "¿Cómo te gustaría pagar por tu acceso?"
  },
  "confirm-include-to-cart": {
    "de": "Hinzufügen",
    "en": "Add",
    "sk": "Pridať",
    "nl": "Toevoegen",
    "ro": "Adaugă",
    "cs": "Přidat",
    "sl": "Dodaj",
    "fr": "Ajouter",
    "fi": "Lisää",
    "is": "Bæta við",
    "es": "Añadir"
  },
  "aviation_headset": {
    "de": "Piloten Headset",
    "en": "Aviation Headset",
    "sk": "Letecká náhlavná súprava",
    "nl": "Luchtvaart Headset",
    "ro": "Căști de aviație",
    "cs": "Letecká sluchátka",
    "sl": "Letalske slušalke",
    "fr": "Casque d'aviation",
    "fi": "Ilmailukuulokkeet",
    "is": "Flug heyrnartól",
    "es": "Auriculares de aviación"
  },
  "e6b_flightcomputer": {
    "de": "E6B Flightcomputer",
    "en": "E6B Flightcomputer",
    "sk": "E6B navigačný počítač",
    "nl": "E6B Navigatiecomputer",
    "ro": "Calculator de navigație E6B",
    "cs": "Navigační počítač E6B",
    "sl": "E6B navigacijski računalnik",
    "fr": "Ordinateur de vol E6B",
    "fi": "E6B-lennontietokone",
    "is": "E6B flugreiknivél",
    "es": "Computadora de vuelo E6B"
  },
  "free_of_charge": {
    "de": "Gratis",
    "en": "Free",
    "sk": "Zdarma",
    "nl": "Gratis",
    "ro": "Gratuit",
    "cs": "Zdarma",
    "sl": "Brezplačno",
    "fr": "Gratuit",
    "fi": "Ilmainen",
    "is": "Ókeypis",
    "es": "Gratis"
  },
  "totalPrice": {
    "de": "Gesamt",
    "en": "Total",
    "sk": "Celkom",
    "nl": "Totaal",
    "ro": "Total",
    "cs": "Celkem",
    "sl": "Skupaj",
    "fr": "Total",
    "fi": "Yhteensä",
    "is": "Samtals",
    "es": "Total"
  },
  "more_products_in_shop": {
    "de": "Weitere Produkte im Shop ansehen",
    "en": "See more products in the store",
    "sk": "Viac produktov nájdete v obchode",
    "nl": "Zie meer producten in de winkel",
    "ro": "Vezi mai multe produse în magazin",
    "cs": "Podívejte se na další produkty v obchodě",
    "sl": "Oglejte si več izdelkov v trgovini",
    "fr": "Voir plus de produits dans le magasin",
    "fi": "Tutustu muihin tuotteisiin kaupassa",
    "is": "Sjá fleiri vörur í versluninni",
    "es": "Ver más productos en la tienda"
  },
  "ruecktrittsrecht": {
    "de": "Ich nehme zur Kenntnis, dass ich mein Rücktrittsrecht (Widerrufsrecht für die Lizenz) nach Bereitstellung der Zugangsdaten verliere.",
    "en": "I acknowledge that I lose my right of withdrawal (right of cancellation) after the access data has been provided.",
    "sk": "Beriem na vedomie, že po poskytnutí prístupových údajov strácam právo na odstúpenie od zmluvy (právo na zrušenie).",
    "nl": "Ik erken dat ik na het verstrekken van de toegangsgegevens mijn herroepingsrecht (recht op annulering) verlies.",
    "ro": "Recunosc că îmi pierd dreptul de retragere (dreptul de anulare) după furnizarea datelor de acces.",
    "cs": "Beru na vádomí, že po poskytnutÍ přistupových údajů ztratím právo na odstoupení.",
    "sl": "Zavedam se, da po posredovanju podatkov za dostop izgubim pravico do umika (pravico do preklica).",
    "fr": "Je reconnais que je perds mon droit de retrait (droit d'annulation) après que les données d'accès ont été fournies.",
    "fi": "Tiedostan, että menetän oikeuteni tilauksen peruuttamiseen (peruuttamisoikeus) sen jälkeen, kun kirjautumistiedot on toimitettu.",
    "is": "Ég viðurkenni að ég missi afturköllunarréttinn (uppsagnarréttur) eftir að aðgangsgögnin hafa verið veitt.",
    "es": "Reconozco que pierdo mi derecho de desistimiento (derecho de cancelación) después de que se hayan proporcionado los datos de acceso."
  },
  "which_catalog_do_you_want_to_use": {
    "de": "Welchen Fragenkatalog möchtest du verwenden?",
    "en": "Which questionnaire do you want to use?",
    "sk": "Ktorý dotazník chcete použiť?",
    "nl": "Welke vragenlijst wilt u gebruiken?",
    "ro": "Ce chestionar doriți să utilizați?",
    "cs": "Který dotazník chcete použít?",
    "sl": "Kateri vprašalnik želite uporabiti?",
    "fr": "Quel questionnaire voulez-vous utiliser ?",
    "fi": "Mitä kyselyä haluat käyttää?",
    "is": "Hvaða spurningalista viltu nota?",
    "es": "¿Qué cuestionario quieres usar?"
  },
  "austria": {
    "de": "Österreich",
    "en": "Austria",
    "sk": "Rakúsko",
    "nl": "Oostenrijk",
    "ro": "Austria",
    "cs": "Rakousko",
    "sl": "Avstrija",
    "fr": "Autriche",
    "fi": "Itävalta",
    "is": "Austurríki",
    "es": "Austria"
  },
  "germany": {
    "de": "Deutschland",
    "en": "Germany",
    "sk": "Nemecko",
    "nl": "Duitsland",
    "ro": "Germania",
    "cs": "Německo",
    "sl": "Nemčija",
    "fr": "Allemagne",
    "fi": "Saksa",
    "is": "Þýskalandi",
    "es": "Alemania"
  },
  "slowakei": {
    "de": "Slowakei",
    "en": "Slovakia",
    "sk": "Slovensko",
    "nl": "Slowakije",
    "ro": "Slovacia",
    "cs": "Slovensko",
    "sl": "Slovaška",
    "fr": "Slovaquie",
    "fi": "Slovakia",
    "is": "Slóvakíu",
    "es": "Eslovaquia"
  },
  "slowenien": {
    "de": "Slowenien",
    "en": "Slovenia",
    "sk": "Slovinsko",
    "nl": "Slovenië",
    "ro": "Slovenia",
    "cs": "Slovinsko",
    "sl": "Slovenija",
    "fr": "Slovénie",
    "fi": "Slovenia",
    "is": "Slóvenía",
    "es": "Eslovenia"
  },
  "niederlande": {
    "de": "Niederlande",
    "en": "Netherlands",
    "sk": "Holandsko",
    "nl": "Nederland",
    "ro": "Olanda",
    "cs": "Nizozemsko",
    "sl": "Nizozemska",
    "fr": "Pays-Bas",
    "fi": "Hollanti",
    "is": "Hollandi",
    "es": "Países Bajos"
  },
  "rumaenien": {
    "de": "Rumänien",
    "en": "Romania",
    "sk": "Rumunsko",
    "nl": "Roemenië",
    "ro": "România",
    "cs": "Rumunsko",
    "sl": "Romunija",
    "fr": "Roumanie",
    "fi": "Romania",
    "is": "Rúmenía",
    "es": "Rumania"
  },
  "tschechien": {
    "de": "Tschechien",
    "en": "Czech Republic",
    "sk": "Česká republika",
    "nl": "Tsjechische Republiek",
    "ro": "Republica Cehă",
    "cs": "Česká republika",
    "sl": "Češka republika",
    "fr": "République Tchèque",
    "fi": "Tšekin tasavalta",
    "is": "Tékkland",
    "es": "República Checa"
  },
  "france": {
    "de": "Frankreich",
    "en": "France",
    "sk": "Francúzsko",
    "nl": "Frankrijk",
    "ro": "Franța",
    "cs": "Francie",
    "sl": "Francija",
    "fr": "France",
    "fi": "Ranska",
    "is": "Frakklandi",
    "es": "Francia"
  },
  "netherlands": {
    "de": "Niederlande",
    "en": "Netherlands",
    "sk": "Holandsko",
    "nl": "Nederland",
    "ro": "Olanda",
    "cs": "Nizozemsko",
    "sl": "Nizozemska",
    "fr": "Pays-Bas",
    "fi": "Alankomaat",
    "is": "Hollandi",
    "es": "Países Bajos"
  },
  "international": {
    "de": "International",
    "en": "International",
    "sk": "Medzinárodné",
    "nl": "Internationaal",
    "ro": "Internațional",
    "cs": "Mezinárodní",
    "sl": "Mednarodno",
    "fr": "International",
    "fi": "Kansainvälinen",
    "is": "Alþjóðlegt",
    "es": "Internacional"
  },
  "if_you_make_changes_you_will_reset_statistics": {
    "de": "Bei Änderungen wird deine aktuelle Statistik zurückgesetzt!",
    "en": "If changes are made, your current statistics will be reset!",
    "sk": "Po vykonaní zmien sa vaše aktuálne štatistiky vynulujú!",
    "nl": "Wanneer er wijzigingen worden aangebracht, worden uw huidige statistieken opnieuw ingesteld!",
    "ro": "Când se fac modificări, statisticile curente vor fi resetate!",
    "cs": "Po provedení změn se vaše aktuální statistiky vynulují!",
    "sl": "Če pride do sprememb, bo vaša trenutna statistika ponastavljena!",
    "fr": "En cas de changement, vos statistiques actuelles seront réinitialisées !",
    "fi": "Jos teet muutoksia, nykyiset statistiikat menetetään!",
    "is": "Ef breytingar eru gerðar verður núverandi tölfræði þín endurstillt!",
    "es": "¡Si se realizan cambios, tus estadísticas actuales se restablecerán!"
  },
  "lets_go": {
    "de": "Los geht's",
    "en": "Let's go",
    "sk": "Poďme",
    "nl": "Laten we gaan.",
    "ro": "Să mergem.",
    "cs": "Pojďme",
    "sl": "Pojdimo",
    "fr": "Allons-y",
    "fi": "Antaa mennä",
    "is": "Förum",
    "es": "¡Vamos!"
  },
  "startTraining": {
    "de": "Training starten",
    "en": "Start training",
    "sk": "Začať školenie",
    "nl": "Start training",
    "ro": "Zahájení školení",
    "cs": "Începeți pregătirea",
    "sl": "Začni trenirati",
    "fr": "Commencer la formation",
    "fi": "Aloita harjoittelu",
    "is": "Byrjaðu á þjálfun",
    "es": "Comenzar entrenamiento"
  },
  "settings": {
    "de": "Einstellungen",
    "en": "Settings",
    "sk": "Nastavenia",
    "nl": "Instellingen",
    "ro": "Setări",
    "cs": "Nastavení",
    "sl": "Nastavitve",
    "fr": "Paramètres",
    "fi": "Asetukset",
    "is": "Stillingar",
    "es": "Ajustes"
  },
  "my_ppltrainer": {
    "de": "Mein PPL Trainer",
    "en": "My PPL Trainer",
    "sk": "Môj PPL Trainer",
    "nl": "Mijn PPL Trainer",
    "ro": "PPL Antrenorul Meu",
    "cs": "Můj PPL Trainer",
    "sl": "Moj PPL Trainer",
    "fr": "Mon PPL Trainer",
    "fi": "Minun PPL Trainer",
    "is": "Minn PPL Trainer",
    "es": "Mi PPL Trainer"
  },
  "chooseATopicFirst": {
    "de": "Wähle zuerst Themen aus!",
    "en": "Select topics first!",
    "sk": "Najskôr vyberte témy!",
    "nl": "Kies eerst onderwerpen!",
    "ro": "Selectați mai întâi subiectele!",
    "cs": "Nejprve vyberte témata!",
    "sl": "Najprej izberite teme!",
    "fr": "Sélectionnez d'abord les sujets !",
    "fi": "Valitse aihe ensin!",
    "is": "Veldu efni fyrst!",
    "es": "¡Selecciona temas primero!"
  },
  "name_first": {
    "de": "Vorname",
    "en": "First Name",
    "sk": "First Name",
    "nl": "First Name",
    "ro": "First Name",
    "cs": "First Name",
    "sl": "First Name",
    "fr": "Prénom",
    "fi": "Etunimi",
    "is": "Fyrsta nafn",
    "es": "Nombre"
  },
  "name_last": {
    "de": "Nachname",
    "en": "Last Name",
    "sk": "Last Name",
    "nl": "Last Name",
    "ro": "Last Name",
    "cs": "Last Name",
    "sl": "Last Name",
    "fr": "Nom de famille",
    "fi": "Sukunimi",
    "is": "Eftirnafn",
    "es": "Apellido"
  },
  "address_street": {
    "de": "Straße/Nr.",
    "en": "Street/Nr.",
    "sk": "Street/Nr.",
    "nl": "Street/Nr.",
    "ro": "Street/Nr.",
    "cs": "Street/Nr.",
    "sl": "Street/Nr.",
    "fr": "Rue/Nr.",
    "fi": "Katu/kadun numero",
    "is": "Gata/nr.",
    "es": "Calle/Núm."
  },
  "address_plz": {
    "de": "PLZ/Ort",
    "en": "ZIP code/City",
    "sk": "ZIP code/City",
    "nl": "ZIP code/City",
    "ro": "ZIP code/City",
    "cs": "ZIP code/City",
    "sl": "ZIP code/City",
    "fr": "Code postal/Ville",
    "fi": "Postinumero/kaupunki",
    "is": "Póstnúmer/borg",
    "es": "Código postal/Ciudad"
  },
  "userData": {
    "de": "Benutzerdaten",
    "en": "User Data",
    "sk": "Údaje používateľa",
    "nl": "Gebruikersgegevens",
    "ro": "Date de utilizator",
    "cs": "Údaje o uživateli",
    "sl": "Uporabniški podatki",
    "fr": "Données de l'utilisateur",
    "fi": "Käyttäjätiedot",
    "is": "Notendagögn",
    "es": "Datos de usuario"
  },
  "confirmEmail": {
    "de": "E-Mail bestätigen",
    "en": "Confirm E-Mail",
    "sk": "Potvrdiť e-mail",
    "nl": "Bevestig ",
    "ro": "Confirmați e-mailul",
    "cs": "Potvrzení e-mailu",
    "sl": "Potrdi email",
    "fr": "Confirmer l'e-mail",
    "fi": "Vahvista sähköposti",
    "is": "Staðfesta tölvupóst",
    "es": "Confirmar correo electrónico"
  },
  "firstName": {
    "de": "Vorname",
    "en": "Firstname",
    "sk": "Krstné meno",
    "nl": "Voornaam",
    "ro": "Nume și prenume",
    "cs": "Křestní jméno",
    "sl": "Ime",
    "fr": "Prénom",
    "fi": "Etunimi",
    "is": "Fyrsta nafn",
    "es": "Nombre"
  },
  "lastName": {
    "de": "Nachname",
    "en": "Lastname",
    "sk": "Priezvisko",
    "nl": "Achternaam",
    "ro": "Numele de familie",
    "cs": "Příjmení",
    "sl": "Priimek",
    "fr": "Nom de famille",
    "fi": "Sukunimi",
    "is": "Eftirnafn",
    "es": "Apellido"
  },
  "recomanded_for_you": {
    "de": "Empfehlungen für dich",
    "en": "Recommendations for you",
    "sk": "Odporúčanie pre vás",
    "nl": "Aanbeveling voor u",
    "ro": "Recomandare pentru tine",
    "cs": "Doporučení pro vás",
    "sl": "Priporočilo za vas",
    "fr": "Recommandation pour vous",
    "fi": "Suosittelemme sinulle",
    "is": "Tilmæli fyrir þig",
    "es": "Recomendación para ti"
  },
  "free_shipping": {
    "de": "Kostenloser Versand",
    "en": "Free shipping",
    "sk": "Doprava zadarmo",
    "nl": "Gratis verzending",
    "ro": "Transport gratuit",
    "cs": "Doprava zdarma",
    "sl": "Brezplačna dostava",
    "fr": "Livraison gratuite",
    "fi": "Ilmainen toimitus",
    "is": "Ókeypis sendingarkostnaður",
    "es": "Envío gratuito"
  },
  "free_key": {
    "de": "Gratis Schlüsselbund",
    "en": "Free keychain",
    "sk": "Bezplatná kľúčenka",
    "nl": "Gratis sleutelhanger",
    "ro": "Breloc gratuit",
    "cs": "Klíčenka zdarma",
    "sl": "Brezplačen obesek za ključe",
    "fr": "Porte-clés gratuit",
    "fi": "Ilmainen avaimenperä",
    "is": "Ókeypis lyklakippa",
    "es": "Llavero gratis"
  },
  "free_navigationtriangle": {
    "de": "Gratis Navigationsdreieck",
    "en": "Free navigation triangle",
    "sk": "Navigačný trojuholník zadarmo",
    "nl": "Gratis navigatiedriehoek",
    "ro": "Triunghi de navigație gratuit",
    "cs": "Navigační trojúhelník zdarma",
    "sl": "Brezplačni navigacijski trikotnik",
    "fr": "Triangle de navigation gratuit",
    "fi": "Ilmainen navigointikolmio",
    "is": "Ókeypis flakk þríhyrningur",
    "es": "Triángulo de navegación gratis"
  },
  "no_account_available": {
    "de": "Sie haben noch keinen Account?",
    "en": "You don't have an account?",
    "sk": "Ešte nemáte účet?",
    "nl": "Heb je nog geen account?",
    "ro": "Nu aveți încă un cont?",
    "cs": "Ještě nemáte účet?",
    "sl": "Še nimate računa?",
    "fr": "Vous n'avez pas de compte ?",
    "fi": "Eikö sinulla ole käyttäjätiliä?",
    "is": "Ertu ekki með reikning?",
    "es": "¿No tienes una cuenta?"
  },
  "details": {
    "de": "Weitere Angaben (Optional, zb. Karteinummer oder Fluglizenz)",
    "en": "Additional Infos (Optional, e.g. Usernumber or Flightlicense)",
    "sk": "Ďalšie informácie (nepovinné, napr. číslo karty)",
    "nl": "Andere informatie (facultatief, bv. kaartnummer)",
    "ro": "Alte informații (opțional, de exemplu, numărul cardului)",
    "cs": "Další informace (nepovinné, např. číslo karty)",
    "sl": "Dodatne informacije (izbirno, npr. uporabniška številka ali licenca za let)",
    "fr": "Informations supplémentaires (facultatif, par exemple numéro d'utilisateur ou licence de vol)",
    "fi": "Lisätiedot (vapaaehtoinen, kuten käyttäjänumero tai lentolupa)",
    "is": "Viðbótarupplýsingar (valfrjálst, t.d. notandanúmer eða flugleyfi)",
    "es": "Información adicional (opcional, por ejemplo, número de usuario o licencia de vuelo)"
  },
  "create_new_exam": {
    "de": "Neue Prüfung anlegen",
    "en": "Create new exam",
    "sk": "Vytvorenie novej skúšky",
    "nl": "Nieuw examen maken",
    "ro": "Creați un nou examen",
    "cs": "Vytvořit novou zkoušku",
    "sl": "Ustvari nov izpit",
    "fr": "Créer un nouvel examen",
    "fi": "Luo uusi testi",
    "is": "Búðu til nýtt próf",
    "es": "Crear nuevo examen"
  },
  "flightstudent_details": {
    "de": "Flugschüler Details",
    "en": "Flight student details",
    "sk": "Detaily študenta letu",
    "nl": "Details van de vliegstudent",
    "ro": "Detalii ale elevului de zbor",
    "cs": "Podrobnosti studenta letu",
    "sl": "Podrobnosti študenta letenja",
    "fr": "Détails de l'étudiant en pilotage",
    "fi": "Lentävän opiskelijan tiedot",
    "is": "Upplýsingar um flugnemandi",
    "es": "Detalles del estudiante de vuelo"
  },
  "advanced_settings": {
    "de": "Weitere Einstellungen",
    "en": "Advanced Settings",
    "sk": "Pokročilé nastavenia",
    "nl": "Geavanceerde instellingen",
    "ro": "Setări avansate",
    "cs": "Pokročilá nastavení",
    "sl": "Napredne nastavitve",
    "fr": "Paramètres avancés",
    "fi": "Edistyneet asetukset",
    "is": "Ítarlegar stillingar",
    "es": "Configuración avanzada"
  },
  "confirm_delete_exam": {
    "de": "Möchtest du die Prüfung wirklich löschen?",
    "en": "Do you really want to delete this exam?",
    "sk": "Naozaj chcete tento test odstrániť?",
    "nl": "Wil je dit examen echt verwijderen?",
    "ro": "Sigur doriți să ștergeți acest examen?",
    "cs": "Opravdu chcete tento test smazat?",
    "sl": "Ali res želite izbrisati ta izpit?",
    "fr": "Voulez-vous vraiment supprimer cet examen ?",
    "fi": "Haluatko varmasti poistaa tämän testin?",
    "is": "Viltu virkilega eyða þessu prófi?",
    "es": "¿Realmente quieres eliminar este examen?"
  },
  "confirm_deleting_document": {
    "de": "Möchtest du das Dokument wirklich löschen?",
    "en": "Do you really want to delete this document?",
    "sk": "Naozaj chcete tento dokument odstrániť?",
    "nl": "Wil je dit document echt verwijderen?",
    "ro": "Sigur doriți să ștergeți acest document?",
    "cs": "Opravdu chcete tento dokument smazat?",
    "sl": "Ali res želite izbrisati ta dokument?",
    "fr": "Voulez-vous vraiment supprimer ce document ?",
    "fi": "Haluatko todella poistaa tämän asiakirjan?",
    "is": "Viltu virkilega eyða þessu skjali?",
    "es": "¿Realmente quieres eliminar este documento?"
  },
  "send_to_email": {
    "de": "E-Mail senden",
    "en": "Send as email",
    "sk": "Send as email",
    "nl": "Send as email",
    "ro": "Send as email",
    "cs": "Send as email",
    "sl": "Send as email",
    "fr": "Envoyer comme e-mail",
    "fi": "Lähetä sähköpostitse",
    "is": "Senda sem tölvupóst",
    "es": "Enviar por correo electrónico"
  },
  "full_count_for_exam": {
    "de": "Allgemeine Fragenanzahl pro Kategorie",
    "en": "Total number of questions per category",
    "sk": "Total number of questions per category",
    "nl": "Total number of questions per category",
    "ro": "Total number of questions per category",
    "cs": "Total number of questions per category",
    "sl": "Total number of questions per category",
    "fr": "Nombre total de questions par catégorie",
    "fi": "Kysymysten määrä yhteensä per kategoria",
    "is": "Heildarfjöldi spurninga í hverjum flokki",
    "es": "Número total de preguntas por categoría"
  },
  "radio_count_questions_for_exam": {
    "de": "Fragenanzahl der Kategorie Sprechfunkt",
    "en": "Number of questions for radiotelephone",
    "sk": "Total number of questions per category",
    "nl": "Total number of questions per category",
    "ro": "Total number of questions per category",
    "cs": "Total number of questions per category",
    "sl": "Total number of questions per category",
    "fr": "Nombre de questions pour le radiotéléphone",
    "fi": "Radiopuhelinta koskevien kysymysten määrä",
    "is": "Fjöldi spurninga fyrir radíósíma",
    "es": "Número de preguntas para radiotelefonía"
  },
  "is_used_for_exam_sheet": {
    "de": "Die Daten werden für das persönliche Ergebnis benötigt.",
    "en": "The data is needed for the personal result.",
    "sk": "Tieto údaje sú potrebné pre osobný výsledok.",
    "nl": "De gegevens zijn nodig voor het persoonlijk resultaat.",
    "ro": "Datele sunt necesare pentru rezultatul personal.",
    "cs": "Údaje jsou potřebné pro osobní výsledek.",
    "sl": "Podatki so potrebni za osebni rezultat.",
    "fr": "Ces données sont nécessaires pour le résultat personnel.",
    "fi": "Tiedot tarvitaan henkilökohtaisia tuloksia varten.",
    "is": "Gögnin eru nauðsynleg fyrir persónulega niðurstöðu.",
    "es": "Los datos son necesarios para el resultado personal."
  },
  "trainingsmode": {
    "de": "Trainingsmodus",
    "en": "Training mode",
    "sk": "Tréningový režim",
    "nl": "Trainingsmodus",
    "ro": "Modul de antrenament",
    "cs": "Tréninkový režim",
    "sl": "Način usposabljanja",
    "fr": "Mode formation",
    "fi": "Harjoitustila",
    "is": "Þjálfunarhamur",
    "es": "Modo de entrenamiento"
  },
  "continueExam": {
    "de": "Prüfung fortsetzen",
    "en": "Continue exam",
    "sk": "Pokračovať v skúške",
    "nl": "Ga door met het onderzoek",
    "ro": "Continuați examinarea",
    "cs": "Pokračovat ve zkoušce",
    "sl": "Nadaljujte z izpitom",
    "fr": "Continuer l'examen",
    "fi": "Jatka testiä",
    "is": "Prófið áfram",
    "es": "Continuar examen"
  },
  "startNewExam": {
    "de": "Neue Prüfung starten",
    "en": "Start new exam",
    "sk": "Spustenie novej skúšky",
    "nl": "Nieuw examen beginnen",
    "ro": "Începeți un nou examen",
    "cs": "Zahájení nové zkoušky",
    "sl": "Začni nov izpit",
    "fr": "Commencer un nouvel examen",
    "fi": "Aloita uusi testi",
    "is": "Byrjaðu á nýju prófi",
    "es": "Comenzar nuevo examen"
  },
  "examFound": {
    "de": "Prüfung gefunden",
    "en": "Exam found",
    "sk": "Nájdená skúška",
    "nl": "Examen gevonden",
    "ro": "Nalezená zkouška",
    "cs": "Examen găsit",
    "sl": "Izpit najden",
    "fr": "Examen trouvé",
    "fi": "Testi löydetty",
    "is": "Próf fundið",
    "es": "Examen encontrado"
  },
  "continueOrStartNewExam": {
    "de": "Du hast deine letzte Prüfung noch nicht beendet. Möchtest du die Prüfung fortsetzen oder eine neue Prüfung starten?",
    "en": "You have not finished your last exam yet. Do you want to continue the exam or start a new one?",
    "sk": "Ešte ste neukončili poslednú skúšku. Chcete pokračovať v skúške alebo začať novú skúšku?",
    "nl": "Je bent nog niet klaar met je laatste examen. Wilt u het examen voortzetten of een nieuw examen beginnen?",
    "ro": "Nu ați terminat încă ultimul examen. Doriți să continuați examenul sau să începeți un nou examen?",
    "cs": "Ještě jste nedokončili poslední zkoušku. Chcete pokračovat ve zkoušce nebo začít novou zkoušku?",
    "sl": "Še niste končali zadnjega izpita. Ali želite nadaljevati izpit ali začeti novega?",
    "fr": "Vous n'avez pas encore terminé votre dernier examen. Voulez-vous continuer l'examen ou en commencer un nouveau ?",
    "fi": "Et ole vielä tehnyt edellistä testiäsi. Haluatko jatkaa testiä vai aloittaa uuden?",
    "is": "Þú hefur ekki enn lokið síðasta prófi þínu. Viltu halda prófinu áfram eða byrja á nýju?",
    "es": "Todavía no has terminado tu último examen. ¿Quieres continuar el examen o empezar uno nuevo?"
  },
  "flightschool": {
    "de": "Flugschule",
    "en": "Flight school",
    "sk": "Letecká škola",
    "nl": "Vliegschool",
    "ro": "Școala de zbor",
    "cs": "Letecká škola",
    "sl": "Letalska šola",
    "fr": "École de pilotage",
    "fi": "Lentokoulu",
    "is": "Flugskóli",
    "es": "Escuela de vuelo"
  },
  "Bekanntenkreis": {
    "de": "Bekanntenkreis",
    "en": "Circle of friends",
    "sk": "Okruh známych",
    "nl": "Kennissenkring",
    "ro": "Cercul de cunoștințe",
    "cs": "Okruh známých",
    "sl": "Krog prijateljev",
    "fr": "Cercle d'amis",
    "fi": "Ystävien piiri",
    "is": "Vinahópur",
    "es": "Círculo de amigos"
  },
  "my_flightschool": {
    "de": "Meine Flugschule",
    "en": "My flight school",
    "sk": "Moja letecká škola",
    "nl": "Mijn vliegschool",
    "ro": "Școala mea de zbor",
    "cs": "Moje letecká škola",
    "sl": "Moja letalska šola",
    "fr": "Mon école de pilotage",
    "fi": "Lentokouluni",
    "is": "Flugskólinn minn",
    "es": "Mi escuela de vuelo"
  },
  "no_flightschool": {
    "de": "Keine Flugschule",
    "en": "No flight school",
    "sk": "Žiadna letecká škola",
    "nl": "Geen vliegschool",
    "ro": "Fără școală de zbor",
    "cs": "Žádná letecká škola",
    "sl": "Brez letalske šole",
    "fr": "Pas d'école de pilotage",
    "fi": "Ei lentokoulua",
    "is": "Enginn flugskóli",
    "es": "Sin escuela de vuelo"
  },
  "no_public_questions_available": {
    "de": "Keine Fragen vorhanden",
    "en": "No questions available",
    "sk": "Nie sú k dispozícii žiadne otázky",
    "nl": "Geen vragen beschikbaar",
    "ro": "Nu există întrebări disponibile",
    "cs": "Žádné otázky nejsou k dispozici",
    "sl": "Na voljo ni nobenega vprašanja",
    "fr": "Aucune question disponible",
    "fi": "Ei kysymyksiä saatavilla",
    "is": "Engar spurningar í boði",
    "es": "No hay preguntas disponibles"
  },
  "no_flightschool_selected": {
    "de": "Keine Flugschule ausgewählt",
    "en": "No flight school selected",
    "sk": "Žiadna vybraná letecká škola",
    "nl": "Geen vliegschool geselecteerd",
    "ro": "Nicio școală de zbor selectată",
    "cs": "Geen vliegschool geselecteerd",
    "sl": "Izbrana ni nobena letalska šola",
    "fr": "Aucune école de pilotage sélectionnée",
    "fi": "Ei lentokoulua valittuna",
    "is": "Enginn flugskóli valinn",
    "es": "No se ha seleccionado ninguna escuela de vuelo"
  },
  "flightschool_questions": {
    "de": "Fragen meiner Flugschule",
    "en": "Questions from my flight school",
    "sk": "Otázky z mojej leteckej školy",
    "nl": "Vragen van mijn vliegschool",
    "ro": "Întrebări de la școala mea de zbor",
    "cs": "Otázky z mé letecké školy",
    "sl": "Vprašanja iz moje letalske šole",
    "fr": "Questions de mon école de pilotage",
    "fi": "Kysymykset lentokoulultani",
    "is": "Spurningar frá flugskólanum mínum",
    "es": "Preguntas de mi escuela de vuelo"
  },
  "flightschool_questions_subtitle": {
    "de": "Zusätzliche Fragen meiner Flugschule",
    "en": "Additional questions from my flight school",
    "sk": "Ďalšie otázky mojej letovej školy",
    "nl": "Aanvullende vragen van mijn vliegschool",
    "ro": "Întrebări suplimentare de la școala mea de zbor",
    "cs": "Další otázky mé letové školy",
    "sl": "Dodatna vprašanja moje letalske šole",
    "fr": "Questions supplémentaires de mon école de pilotage",
    "fi": "Lisäkysymyksiä lennonopetuskoultultani",
    "is": "Aukaskjarri frá flugskólanum mínum",
    "es": "Preguntas adicionales de mi escuela de vuelo"
  },
  "scenarios_of_my_flightschool": {
    "de": "Szenarien meiner Flugschule",
    "en": "Scenarios from my flight school",
    "sk": "Scenáre mojej leteckej školy",
    "nl": "Scenario's van mijn vliegschool",
    "ro": "Scenariile școlii mele de zbor",
    "cs": "Scénáře mé letecké školy",
    "sl": "Scenariji moje letalske šole",
    "fr": "Scénarios de mon école de pilotage",
    "fi": "Lentokouluni skenaariot",
    "is": "Sænur mínar flugskólans",
    "es": "Escenarios de mi escuela de vuelo"
  },
  "scenarios_of_my_flightschool_subtitle": {
    "de": "Szenarien sind speziell ausgewählte Fragen zum jeweiligen Themengebiet",
    "en": "Scenarios are specially selected questions on the respective subject area",
    "sk": "Scenáre sú špeciálne vybrané otázky k danej téme",
    "nl": "Scenario's zijn speciaal geselecteerde vragen over het betreffende onderwerp",
    "ro": "Scenariile sunt întrebări special selectate pe tema respectivă",
    "cs": "Sénáře jsou speciálně vybrané otázky k danému tématu",
    "sl": "Scenariji so posebej izbrana vprašanja o določeni temi",
    "fr": "Les scénarios sont des questions spécialement sélectionnées sur le sujet concerné",
    "fi": "Skenaariot ovat erityisesti valittuja kysymyksiä tietystä aiheesta",
    "is": "Skenarión eru sérstaklega valdar spurningar um tiltekið efnisvið",
    "es": "Los escenarios son preguntas especialmente seleccionadas sobre el tema en cuestión"
  },
  "no_scenarios_available": {
    "de": "Keine Szenarien vorhanden",
    "en": "No scenarios available",
    "sk": "Žiadne scenáre nie sú k dispozícii",
    "nl": "Geen scenario's beschikbaar",
    "ro": "Nu sunt scenarii disponibile",
    "cs": "Žádné scénáře nejsou k dispozici",
    "sl": "Ni na voljo nobenih scenarijev",
    "fr": "Aucun scénario disponible",
    "fi": "Ei saatavilla olevia skenaarioita",
    "is": "Engin sviðsmynd tiltæk",
    "es": "No hay escenarios disponibles"
  },
  "show_questions_of_flightschool": {
    "de": "Zeige Fragen meiner Flugschule",
    "en": "Show questions of my flight school",
    "sk": "Zobraziť verejné otázky mojej leteckej školy",
    "nl": "Toon openbare vragen van mijn vliegschool",
    "ro": "Arată întrebările publice ale școlii mele de zbor",
    "cs": "Zobrazit veřejné otázky mé letecké školy",
    "sl": "Pokaži javna vprašanja moje letalske šole",
    "fr": "Afficher les questions publiques de mon école de pilotage",
    "fi": "Näytä lentokouluni julkiset kysymykset",
    "is": "Sýndu almennar spurningar um flugskólann minn",
    "es": "Mostrar preguntas de mi escuela de vuelo"
  },
  "show_content_of_flightschool": {
    "de": "Zeige Inhalte meiner Flugschule",
    "en": "Show content of my flight school",
    "sk": "Zobraziť obsah mojej leteckej školy",
    "nl": "Toon inhoud van mijn vliegschool",
    "ro": "Afișați conținutul școlii mele de zbor",
    "cs": "Zobrazit obsah mé letecké školy",
    "sl": "Prikaži vsebino moje letalske šole",
    "fr": "Afficher le contenu de mon école de pilotage",
    "fi": "Näytä lentokouluni sisältö",
    "is": "Sýna innihald flugskólans míns",
    "es": "Mostrar contenido de mi escuela de vuelo"
  },
  "my_last_exams": {
    "de": "Meine letzten Prüfungen",
    "en": "My last exams",
    "sk": "Moje posledné skúšky",
    "nl": "Mijn laatste examens",
    "ro": "Ultimele mele examene",
    "cs": "Moje poslední zkoušky",
    "sl": "Moji zadnji izpiti",
    "fr": "Mes derniers examens",
    "fi": "Viimeiset tenttini",
    "is": "Mínar síðustu próf",
    "es": "Mis últimos exámenes"
  },
  "maximum_3_results": {
    "de": "Maximal 3 Prüfungen",
    "en": "Maximum 3 exams",
    "sk": "Maximálne 3 skúšky",
    "nl": "Maximaal 3 examens",
    "ro": "Maxim 3 examene",
    "cs": "Maximálně 3 zkoušky",
    "sl": "Največ 3 izpiti",
    "fr": "Maximum 3 examens",
    "fi": "Enintään 3 tenttiä",
    "is": "Hámark 3 próf",
    "es": "Máximo 3 exámenes"
  },
  "delete": {
    "de": "Löschen",
    "en": "Delete",
    "sk": "Odstrániť",
    "nl": "Delete",
    "ro": "Ștergeți",
    "cs": "Odstranit",
    "sl": "Izbriši",
    "fr": "Supprimer",
    "fi": "Poista",
    "is": "Eyða",
    "es": "Eliminar"
  },
  "edit": {
    "de": "Ändern",
    "en": "Edit",
    "sk": "Upraviť",
    "nl": "Bewerken",
    "ro": "Editare",
    "cs": "Upravit",
    "sl": "Uredi",
    "fr": "Modifier",
    "fi": "Muokkaa",
    "is": "Breyta",
    "es": "Editar"
  },
  "download": {
    "de": "Download",
    "en": "Download",
    "sk": "Stiahnite si",
    "nl": "Download",
    "ro": "Descărcați",
    "cs": "Stáhnout",
    "sl": "Prenesi",
    "fr": "Télécharger",
    "fi": "Lataa",
    "is": "Sækja",
    "es": "Descargar"
  },
  "your_flightschool_doesnt_use_ato_version": {
    "de": "Deine Flugschule benutzt die ATO-Version noch nicht",
    "en": "Your flight school does not use the ATO version yet",
    "sk": "Vaša letecká škola ešte nepoužíva verziu ATO",
    "nl": "Uw vliegschool gebruikt nog niet de ATO versie",
    "ro": "Școala dumneavoastră de zbor nu utilizează încă versiunea ATO",
    "cs": "Vaše letecká škola ještě nepoužívá verzi ATO.",
    "sl": "Vaša letalska šola še ne uporablja različice ATO",
    "fr": "Votre école de pilotage n'utilise pas encore la version ATO",
    "fi": "Lentokoulusi ei käytä ATO-versiota vielä",
    "is": "Flugskólinn þinn notar ekki ATO útgáfuna ennþá",
    "es": "Tu escuela de vuelo aún no utiliza la versión ATO"
  },
  "immediately_jump_to_next_question": {
    "de": "Sofort zur nächsten Frage springen",
    "en": "Jump to the next question immediately",
    "sk": "Okamžite prejdite na ďalšiu otázku",
    "nl": "Ga onmiddellijk naar de volgende vraag",
    "ro": "Treceți imediat la următoarea întrebare",
    "cs": "Okamžitý přechod na další otázku",
    "sl": "Takoj skočite na naslednje vprašanje",
    "fr": "Passez immédiatement à la question suivante",
    "fi": "Hyppää välittömästi seuraavana kysymykseen",
    "is": "Farðu strax í næstu spurningu",
    "es": "Saltar a la siguiente pregunta inmediatamente"
  },
  "radio_questions": {
    "de": "Sprechfunk",
    "en": "Radiotelephone",
    "sk": "Radiotelephone",
    "nl": "Radiotelephone",
    "ro": "Radiotelephone",
    "cs": "Radiotelephone",
    "sl": "Izberite svoj radiotelefonski certifikat",
    "fr": "Radiotéléphonie",
    "fi": "Radiopuhelin",
    "is": "Útvarpssími",
    "es": "Radiotelefonía"
  },
  "where_will_you_take_the_radiotelephone_exam": {
    "de": "Wo wirst du die Funkprüfung ablegen?",
    "en": "Where will you take the radiotelephone exam?",
    "sk": "Kde budeš skladať skúšku z rádiotelefonie?",
    "nl": "Waar ga je het radiotelefoonexamen afleggen?",
    "ro": "Unde vei susține examenul de radiotelefonie?",
    "cs": "Kde budeš skládat zkoušku z radiotelefonie?",
    "sl": "Kje boš opravljal izpit iz radiotelefonije?",
    "fr": "Où passeras-tu l'examen de radiotéléphonie?",
    "fi": "Missä suoritat radiopuhelinkokeen?",
    "is": "Hvar ætlar þú að taka radíósímaviðtalspróf?",
    "es": "¿Dónde harás el examen de radiotelefonía?"
  },
  "choose_estimated_exam_date": {
    "de": "Wann planst du ungefähr, die Prüfung abzulegen?",
    "en": "When do you approximately plan to take the exam?",
    "sk": "Kedy približne plánuješ zložiť skúšku?",
    "nl": "Wanneer plan je ongeveer om het examen af te leggen?",
    "ro": "Când plănuiești aproximativ să susții examenul?",
    "cs": "Kdy přibližně plánuješ složit zkoušku?",
    "sl": "Kdaj približno nameravaš opraviti izpit?",
    "fr": "Quand prévois-tu d'approximativement passer l'examen ?",
    "fi": "Milloin suunnittelet suurin piirtein kokeen suorittamista?",
    "is": "Hvenær áætlarðu að taka prófið að mestu leyti?",
    "es": "¿Cuándo planeas aproximadamente realizar el examen?"
  },
  "select_radio_certificate": {
    "de": "Welches Funkzeugnis möchtest du erwerben?",
    "en": "Which radiotelephone certificate do you want to obtain?",
    "sk": "Aký rádiotelefónny preukaz chceš získať?",
    "nl": "Welk radiotelefooncertificaat wil je behalen?",
    "ro": "Ce certificat de radiotelefonie dorești să obții?",
    "cs": "Jakou rádiotelefonní licenci chceš získat?",
    "sl": "Katero radijsko dovoljenje želiš pridobiti?",
    "fr": "Quel certificat de radiotéléphonie souhaites-tu obtenir ?",
    "fi": "Minkä radiopuhelintodistuksen haluat hankkia?",
    "is": "Hvaða radíósímaskírteini viltu fá?",
    "es": "¿Qué certificado de radiotelefonía deseas obtener?"
  },
  "choose_your_language": {
    "de": "Die Fragen in folgender Sprache anzeigen?",
    "en": "Show the questions in the following language?",
    "sk": "Zobrazte otázky v nasledujúcom jazyku?",
    "nl": "Geef de vragen in de volgende taal weer?",
    "ro": "Arătați întrebările în următoarea limbă?",
    "cs": "Zobrazte otázky v následujícím jazyce?",
    "sl": "Vprašanja prikažite v naslednjem jeziku?",
    "fr": "Affichez les questions dans la langue suivante?",
    "fi": "Näytä kysymykset seuraavalla kielellä?",
    "is": "Sýndu spurningarnar á eftirfarandi tungumáli?",
    "es": "Mostrar las preguntas en el siguiente idioma?"
  },
  "questions": {
    "de": "Fragen",
    "en": "Questions",
    "sk": "Otázky",
    "nl": "Vragen",
    "ro": "Întrebări",
    "cs": "Otázky",
    "sl": "Vprašanja",
    "fr": "Questions",
    "fi": "Kysymykset",
    "is": "Spurningar",
    "es": "Preguntas"
  },
  "reset": {
    "de": "Zurücksetzen",
    "en": "Reset",
    "sk": "Obnovenie",
    "nl": "Reset",
    "ro": "Resetare",
    "cs": "Obnovení",
    "sl": "Ponastavi",
    "fr": "Réinitialiser",
    "fi": "Nollaa",
    "is": "Endurstilla",
    "es": "Reiniciar"
  },
  "cancel": {
    "de": "Abbrechen",
    "en": "Cancel",
    "sk": "Zrušiť",
    "nl": "Annuleren",
    "ro": "Anulează",
    "cs": "Zrušit",
    "sl": "Prekliči",
    "fr": "Annuler",
    "fi": "Peruuta",
    "is": "Hætta við",
    "es": "Cancelar"
  },
  "do_you_really_want_to_skip": {
    "de": "Möchten Sie wirklich abbrechen?",
    "en": "Do you really want to abort?",
    "sk": "Naozaj chcete potratiť?",
    "nl": "Wil je echt aborteren?",
    "ro": "Chiar vrei să avortezi?",
    "cs": "Opravdu chcete přerušit těhotenství?",
    "sl": "Ali res želite prekiniti?",
    "fr": "Voulez-vous vraiment annuler l'examen ?",
    "fi": "Haluatko todella keskeyttää?",
    "is": "Viltu virkilega fara í fóstureyðingu?",
    "es": "¿Realmente quieres abortar?"
  },
  "do_you_really_want_to_skip_the_test": {
    "de": "Möchten Sie die Prüfung wirklich beenden?",
    "en": "Do you really want to abort the exam?",
    "sk": "Naozaj chcete dokončiť skúšku?",
    "nl": "Wil je het examen echt afmaken?",
    "ro": "Chiar vrei să termini examenul?",
    "cs": "Opravdu chcete dokončit zkoušku?",
    "sl": "Ali res želite prekiniti izpit?",
    "fr": "Voulez-vous vraiment annuler l'examen ?",
    "fi": "Haluatko todella keskeyttää testin?",
    "is": "Viltu virkilega hætta við prófið?",
    "es": "¿Realmente quieres abortar el examen?"
  },
  "check": {
    "de": "Überprüfen",
    "en": "Check",
    "sk": "Skontrolujte stránku",
    "nl": "Kijk op",
    "ro": "Verificați",
    "cs": "Podívejte se na stránky",
    "sl": "Preverite",
    "fr": "Vérifiez",
    "fi": "Tarkista",
    "is": "Athugaðu",
    "es": "Verificar"
  },
  "correct": {
    "de": "Richtig",
    "en": "Correct",
    "sk": "Správne",
    "nl": "Correct",
    "ro": "Corect",
    "cs": "Správně",
    "sl": "Popravite",
    "fr": "Correct",
    "fi": "Oikein",
    "is": "Rétt",
    "es": "Correcto"
  },
  "wrong": {
    "de": "Falsch",
    "en": "Wrong",
    "sk": "Omyl",
    "nl": "Fout",
    "ro": "Greșit",
    "cs": "Špatně",
    "sl": "Napaka",
    "fr": "Faux",
    "fi": "Väärin",
    "is": "Rangt",
    "es": "Incorrecto"
  },
  "wrong_next_question": {
    "de": "Falsch! Nächste Frage",
    "en": "Wrong! Next question",
    "sk": "Omyl! K ďalšej otázke",
    "nl": "Fout! Naar de volgende vraag",
    "ro": "Greșit! La următoarea întrebare",
    "cs": "Špatně! K další otázce",
    "sl": "Napaka! Naslednje vprašanje",
    "fr": "Faux ! Question suivante",
    "fi": "Väärin! Seuraava kysymys",
    "is": "Rangt! Næsta spurning",
    "es": "¡Incorrecto! Siguiente pregunta"
  },
  "noch_remaining": {
    "de": "Noch",
    "en": " ",
    "sk": "Stále",
    "nl": "Nog",
    "ro": "Încă",
    "cs": "Stále",
    "sl": " ",
    "fr": " ",
    "fi": " ",
    "is": "",
    "es": " "
  },
  "questions_remaining": {
    "de": "Fragen",
    "en": "questions left",
    "sk": "otázok",
    "nl": "vragen ",
    "ro": "întrebări",
    "cs": "otázek",
    "sl": "ostala vprašanja",
    "fr": "questions restantes",
    "fi": "kysymystä jäljellä",
    "is": "spurningar eftir",
    "es": "preguntas restantes"
  },
  "Exam": {
    "de": "Prüfung starten",
    "en": "Start Exam",
    "sk": "Začiatok skúšky",
    "nl": "Start examen",
    "ro": "Începeți examenul",
    "cs": "Zahájení zkoušky",
    "sl": "Začni preverjanje znanja",
    "fr": "Démarrer l'examen",
    "fi": "Aloita testi",
    "is": "Byrjaðu próf",
    "es": "Comenzar examen"
  },
  "ExamSimulation": {
    "de": "Prüfungssimulation",
    "en": "Examination simulation",
    "sk": "Simulácia skúšky",
    "nl": "Examensimulatie",
    "ro": "Simulare de examen",
    "cs": "Simulace zkoušky",
    "sl": "Simulacija izpita",
    "fr": "Simulation de l'examen",
    "fi": "Harjoitustesti",
    "is": "Próf eftirlíking",
    "es": "Simulación de examen"
  },
  "sendExam": {
    "de": "Prüfung auswerten",
    "en": "Evaluate test",
    "sk": "Vyhodnotiť test",
    "nl": "Test evalueren",
    "ro": "Evaluați testul",
    "cs": "Vyhodnocení testu",
    "sl": "Oceni test",
    "fr": "Évaluer l'examen",
    "fi": "Arvioi testi",
    "is": "Meta próf",
    "es": "Evaluar examen"
  },
  "not_completed_yet": {
    "de": "Sie müssen zuerst eine Kategorie abschließen, um eine Auswertung erhalten zu können.",
    "en": "You must first complete a category to receive an evaluation.",
    "sk": "Ak chcete získať hodnotenie, musíte najprv vyplniť kategóriu.",
    "nl": "U moet eerst een categorie invullen om een evaluatie te ontvangen.",
    "ro": "Pentru a primi o evaluare, trebuie să completați mai întâi o categorie.",
    "cs": "Abyste mohli získat hodnocení, musíte nejprve vyplnit kategorii.",
    "sl": "Za oceno morate najprej izpolniti kategorijo.",
    "fr": "Vous devez d'abord remplir une catégorie pour recevoir une évaluation.",
    "fi": "Sinun tulee ensin suorittaa kategoria, jotta voit saada arvion.",
    "is": "Þú verður fyrst að ljúka flokki til að fá mat.",
    "es": "Primero debes completar una categoría para recibir una evaluación."
  },
  "noQuestionsLeft": {
    "de": "Keine weiteren Fragen übrig.",
    "en": "No further questions left.",
    "sk": "Neostali žiadne ďalšie otázky.",
    "nl": "Geen verdere vragen meer.",
    "ro": "Nu mai sunt alte întrebări.",
    "cs": "Žádné další otázky nezbyly.",
    "sl": "Ni več vprašanj.",
    "fr": "Il ne reste plus de questions.",
    "fi": "Ei enempää kysymyksiä jäljellä.",
    "is": "Engar frekari spurningar eftir.",
    "es": "No quedan más preguntas."
  },
  "allQuestionsDone": {
    "de": "Alle Fragen beantwortet.",
    "en": "All questions answered.",
    "sk": "Všetky otázky boli zodpovedané.",
    "nl": "Alle vragen beantwoord.",
    "ro": "S-a răspuns la toate întrebările.",
    "cs": "Všechny otázky byly zodpovězeny.",
    "sl": "Odgovorjeno na vsa vprašanja.",
    "fr": "Toutes les questions ont été répondues.",
    "fi": "Kaikkiin kysymyksiin on vastattu.",
    "is": "Öllum spurningum svarað.",
    "es": "Todas las preguntas respondidas."
  },
  "next": {
    "de": "Weiter",
    "en": "Continue",
    "sk": "Ďalšie",
    "nl": "Verder",
    "ro": "Mai departe",
    "cs": "Další",
    "sl": "Nadaljuj",
    "fr": "Continuer",
    "fi": "Jatka",
    "is": "Halda áfram",
    "es": "Continuar"
  },
  "close": {
    "de": "Schließen",
    "en": "Close",
    "sk": "Zatvoriť",
    "nl": "Sluiten",
    "ro": "Închideți",
    "cs": "Zavřít",
    "sl": "Zapri",
    "fr": "Fermer",
    "fi": "Sulje",
    "is": "Loka",
    "es": "Cerrar"
  },
  "true": {
    "de": "Richtig",
    "en": "Correct",
    "sk": "Správne",
    "nl": "Correct",
    "ro": "Corect",
    "cs": "Správně",
    "sl": "Pravilno",
    "fr": "Correct",
    "fi": "Oikein",
    "is": "Rétt",
    "es": "Correcto"
  },
  "false": {
    "de": "Falsch",
    "en": "False",
    "sk": "Nesprávne",
    "nl": "Onjuist",
    "ro": "Incorect",
    "cs": "Nesprávné",
    "sl": "Napačno",
    "fr": "Faux",
    "fi": "Väärin",
    "is": "Rangt",
    "es": "Incorrecto"
  },
  "days_remaining": {
    "de": "Tage verbleiben",
    "en": "days remaining",
    "sk": "dní",
    "nl": "dagen",
    "ro": "zile rămase",
    "cs": "dní",
    "sl": "dni ostane",
    "fr": "jours restants",
    "fi": "päivää jäljellä",
    "is": "Dagar eftir",
    "es": "días restantes"
  },
  "day_remaining": {
    "de": "Tag verbleibt",
    "en": "day remaining",
    "sk": "deň",
    "nl": "dag",
    "ro": "zi rămasă",
    "cs": "den",
    "sl": "dan ostane",
    "fr": "jour restant",
    "fi": "päivä jäljellä",
    "is": "dagur eftir",
    "es": "día restante"
  },
  "days_remaining_prev": {
    "de": " ",
    "en": " ",
    "sk": "Zostáva ",
    "nl": "Nog ",
    "ro": " ",
    "cs": "Zbývá ",
    "sl": " ",
    "fr": " ",
    "fi": " ",
    "is": "",
    "es": " "
  },
  "thanks_for_your_order": {
    "de": "Vielen Dank für deine Bestellung",
    "en": "Thank you for your order",
    "sk": "Ďakujeme za vašu objednávku",
    "nl": "Dank u voor uw bestelling",
    "ro": "Vă mulțumim pentru comanda dvs.",
    "cs": "Děkujeme vám za vaši objednávku",
    "sl": "Hvala za vaše naročilo",
    "fr": "Merci pour votre commande",
    "fi": "Kiitoksia tilauksestasi",
    "is": "Takk fyrir pöntunina",
    "es": "Gracias por tu pedido"
  },
  "as_soon_as_your_account_has_been_activate_we_will_inform_you": {
    "de": "Du bekommst gleich eine Email mit den Zugangsdaten und den nächsten Schritten.",
    "en": "You will receive an email with the access data and the next steps.",
    "sk": "Hneď ako bude váš účet aktivovaný, dostanete e-mail s prístupovými údajmi a ďalšími krokmi.",
    "nl": "Zodra uw account is geactiveerd, ontvangt u een e-mail met de toegangsgegevens en de volgende stappen.",
    "ro": "Imediat ce contul dvs. a fost activat, veți primi un e-mail cu datele de acces și pașii următori.",
    "cs": "Jakmile bude váš účet aktivován, obdržíte e-mail s přístupovými údaji a dalšími kroky.",
    "sl": "Ko bo vaš račun aktiviran, boste prejeli e-pošto s podatki za dostop in nadaljnji koraki.",
    "fr": "Dès que votre compte sera activé, vous recevrez un e-mail avec les données d'accès et les prochaines étapes.",
    "fi": "Saat pian sähköpostin, jossa on käyttöoikeustiedot ja seuraavat vaiheet.",
    "is": "Þegar notandareikningurinn þinn hefur verið virkjaður, færðu tölvupóst með aðgangsupplýsingum og næstu skrefum.",
    "es": "Recibirás un correo electrónico con los datos de acceso y los siguientes pasos."
  },
  "login_here_after_creation_of_account": {
    "de": "Nach der Erstellung eines Accounts kannst du dich hier einloggen",
    "en": "After creating an account you can log in here",
    "sk": "Po vytvorení účtu sa môžete prihlásiť tu",
    "nl": "Nadat u een account heeft aangemaakt kunt u hier inloggen",
    "ro": "După ce v-ați creat un cont, vă puteți autentifica aici",
    "cs": "Po vytvoření účtu se můžete přihlásit zde",
    "sl": "Po ustvarjanju računa se lahko prijavite tukaj",
    "fr": "Après avoir créé un compte, vous pouvez vous connecter ici",
    "fi": "Käyttäjätilin luomisen jälkeen voit kirjautua täällä",
    "is": "Eftir að þú hefur búið til reikning geturðu skráð þig inn hér",
    "es": "Después de crear una cuenta, puedes iniciar sesión aquí"
  },
  "the_activation_can_take_up_to_60_minutes": {
    "de": "Die Aktivierung deines Accounts kann bis zu 60 Minuten dauern.",
    "en": "The activation of your account can take up to 60 minutes.",
    "sk": "Aktivácia konta môže trvať až 60 minút.",
    "nl": "Het activeren van uw account kan tot 60 minuten duren.",
    "ro": "Activarea contului dvs. poate dura până la 60 de minute.",
    "cs": "Aktivace účtu může trvat až 60 minut.",
    "sl": "Aktivacija vašega računa lahko traja do 60 minut.",
    "fr": "L'activation de votre compte peut prendre jusqu'à 60 minutes.",
    "fi": "Käyttäjätilisi aktivointi voi kestää jopa 60 minuuttia.",
    "is": "Virkjun reikningsins þíns getur tekið allt að 60 mínútur.",
    "es": "La activación de tu cuenta puede tardar hasta 60 minutos."
  },
  "thank_you_for_your_feedback": {
    "de": "Danke für deine Rückmeldung!",
    "en": "Thanks for your feedback!",
    "sk": "Ďakujeme za vašu spätnú väzbu!",
    "nl": "Dank u voor uw feedback!",
    "ro": "Vă mulțumim pentru feedback-ul dumneavoastră!",
    "cs": "Děkujeme za vaši zpětnou vazbu!",
    "sl": "Hvala za vaše povratne informacije!",
    "fr": "Merci pour vos commentaires !",
    "fi": "Kiitoksia palautteestasi!",
    "is": "Takk fyrir álit þitt!",
    "es": "¡Gracias por tu retroalimentación!"
  },
  "from_where_have_you_heard_from_us": {
    "de": "Wie bist du auf uns aufmerksam geworden?",
    "en": "How did you hear about us?",
    "sk": "Ako ste sa o nás dozvedeli?",
    "nl": "Hoe heb je over ons gehoord?",
    "ro": "Cum ați auzit de noi?",
    "cs": "Jak jste se o nás dozvěděli?",
    "sl": "Kako si slišal za nas?",
    "fr": "Comment avez-vous entendu parler de nous ?",
    "fi": "Kuinka kuulit meistä?",
    "is": "hvernig fréttirðu af okkur?",
    "es": "¿De dónde has oído hablar de nosotros?"
  },
  "contact_us_after_12_hours": {
    "de": "Solltest du innerhalb von 12 Stunden keine Rückmeldung erhalten, gib uns bitte Bescheid.",
    "en": "If you do not hear back within 12 hours, please let us know.",
    "sk": "Ak nedostanete odpoveď do 12 hodín, dajte nám vedieť.",
    "nl": "Als u binnen 12 uur niets van ons hoort, laat het ons dan weten.",
    "ro": "Dacă nu primiți un răspuns în termen de 12 ore, vă rugăm să ne anunțați.",
    "cs": "Pokud se vám do 12 hodin neozveme, dejte nám prosím vědět.",
    "sl": "Če se ne oglasite v 12 urah, nas obvestite.",
    "fr": "Si vous n'avez pas de réponse dans les 12 heures, veuillez nous le faire savoir.",
    "fi": "Jost et kuule meistä takaisin 12 tunnin kuluessa, ole uudelleen yhteydessä meihin.",
    "is": "Ef þú heyrir ekki til baka innan 12 klukkustunda, vinsamlegast láttu okkur vita.",
    "es": "Si no recibes respuesta en 12 horas, avísanos por favor."
  },
  "runtime": {
    "de": "Laufzeit",
    "en": "Runtime",
    "sk": "Trvanie",
    "nl": "Duur",
    "ro": "Doba trvání",
    "cs": "Durata",
    "sl": "Čas delovanja",
    "fr": "Exécution",
    "fi": "Suoritusaika",
    "is": "Runtime",
    "es": "Tiempo de ejecución"
  },
  "prev_question": {
    "de": "Frage zurück",
    "en": "Question back",
    "sk": "Otázka späť",
    "nl": "Vraag terug",
    "ro": "Întrebare înapoi",
    "cs": "Otázka zpět",
    "sl": "Prejšnje vprašanje",
    "fr": "Question précédente",
    "fi": "Kysymys taaksepäin",
    "is": "Spurning til baka",
    "es": "Volver a la pregunta"
  },
  "next_question": {
    "de": "Frage vor",
    "en": "Question forward",
    "sk": "Otázka dopredu",
    "nl": "Vraag voorwaarts",
    "ro": "Întrebare înainte",
    "cs": "Otázka dopředu",
    "sl": "Naslednje vprašanje",
    "fr": "Question précédente",
    "fi": "Kysymy eteenpäin",
    "is": "Spurning áfram",
    "es": "Siguiente pregunta"
  },
  "old": {
    "de": "Alt",
    "en": "Old",
    "sk": "Staré",
    "nl": "Oud",
    "ro": "Vechi",
    "cs": "Starý",
    "sl": "Staro",
    "fr": "Ancienne",
    "fi": "Vanha",
    "is": "Gamalt",
    "es": "Antiguo"
  },
  "favorite_removed_successfully": {
    "de": "Gespeicherte Frage entfernt.",
    "en": "Removed saved question.",
    "sk": "Odstránená uložená otázka.",
    "nl": "Verwijderde opgeslagen vraag.",
    "ro": "Întrebare salvată eliminată.",
    "cs": "Odstraněna uložená otázka.",
    "sl": "Odstranjeno shranjeno vprašanje.",
    "fr": "Suppression de la question sauvegardée.",
    "fi": "Poista tallennettu kysymys",
    "is": "Fjarlægði vistuð spurning.",
    "es": "Pregunta guardada eliminada correctamente."
  },
  "favorite_added_successfully": {
    "de": "Die Frage wurde gespeichert.",
    "en": "Question was saved.",
    "sk": "Otázka bola uložená.",
    "nl": "De vraag is gered.",
    "ro": "Întrebarea a fost salvată.",
    "cs": "Otázka byla uložena.",
    "sl": "Vprašanje je bilo shranjeno.",
    "fr": "La question a été sauvegardée.",
    "fi": "Kysymys tallennettiin",
    "is": "Spurningin var vistuð.",
    "es": "Pregunta guardada correctamente."
  },
  "favorite_questions_label": {
    "de": "Meine gespeicherten Fragen",
    "en": "My saved questions",
    "sk": "Moje uložené otázky",
    "nl": "Mijn opgeslagen vragen",
    "ro": "Întrebările mele salvate",
    "cs": "Moje uložené otázky",
    "sl": "Moja shranjena vprašanja",
    "fr": "Mes questions sauvegardées",
    "fi": "Tallentamani kysymykset",
    "is": "Vistuðu spurningarnar mínar",
    "es": "Mis preguntas guardadas"
  },
  "printExamtoPDF": {
    "de": "Prüfung drucken",
    "en": "Print exam",
    "sk": "Tlač skúšky",
    "nl": "Examen afdrukken",
    "ro": "Tipărire examen",
    "cs": "Zkouška z tisku",
    "sl": "Tiskanje izpita",
    "fr": "Imprimer l'examen",
    "fi": "Tulosta testi",
    "is": "Prentpróf",
    "es": "Imprimir examen"
  },
  "forExamsOnPaper": {
    "de": "PDF Datei erstellen",
    "en": "Create PDF file",
    "sk": "Vytvorenie súboru PDF",
    "nl": "PDF-bestand maken",
    "ro": "Creați un fișier PDF",
    "cs": "Vytvoření souboru PDF",
    "sl": "Ustvari datoteko PDF",
    "fr": "Créer un fichier PDF",
    "fi": "Luo PDF-tiedosto",
    "is": "Búa til PDF skjal",
    "es": "Crear archivo PDF"
  },
  "chooseAllTopicsForPrintedExam": {
    "de": "Wählen Sie alle Themen aus, die in der gedruckten Prüfung enthalten sein sollen.",
    "en": "Select all topics to be included in the printed exam.",
    "sk": "Vyberte všetky témy, ktoré sa majú zahrnúť do tlačenej skúšky.",
    "nl": "Selecteer alle onderwerpen voor het gedrukte examen.",
    "ro": "Selectați toate subiectele care vor fi incluse în examenul tipărit.",
    "cs": "Vyberte všechna témata, která mají být zahrnuta do tištěné zkoušky.",
    "sl": "Izberite vse teme, ki bodo vključene v tiskani izpit.",
    "fr": "Sélectionnez tous les sujets à inclure dans l'examen imprimé.",
    "fi": "Valitse kaikki aiheet, jotka haluat lisätä tulostettuun testiin.",
    "is": "Veldu öll efni til að vera með í prentuðu prófinu.",
    "es": "Selecciona todos los temas que se incluirán en el examen impreso."
  },
  "version": {
    "de": "Version",
    "en": "Version",
    "sk": "Verzia",
    "nl": "Versie",
    "ro": "Versiunea",
    "cs": "Verze",
    "sl": "Verzija",
    "fr": "Version",
    "fi": "Versio",
    "is": "Útgáfa",
    "es": "Versión"
  },
  "ExamPDFgenerator": {
    "de": "Prüfung als PDF erstellen",
    "en": "Create Exam as PDF",
    "sk": "Vytvorenie skúšky vo formáte PDF",
    "nl": "Examen maken als PDF",
    "ro": "Creați examenul ca PDF",
    "cs": "Vytvořit zkoušku ve formátu PDF",
    "sl": "Ustvari izpit kot PDF",
    "fr": "Créer l'examen au format PDF",
    "fi": "Luo testi PDF:nä",
    "is": "Búðu til próf sem PDF",
    "es": "Crear examen como PDF"
  },
  "AGK": {
    "de": "Allgemeine Luftfahrzeugkunde",
    "en": "General aircraft knowledge",
    "sk": "Všeobecné znalosti o lietadlách",
    "nl": "Algemene Vliegtuigkennis",
    "ro": "Cunoștințe generale despre aeronave",
    "cs": "Obecné znalosti o letadlech",
    "sl": "Splošno znanje o zrakoplovih",
    "fr": "Connaissance générale des aéronefs",
    "fi": "Yleistä tietoa ilma-aluksista",
    "is": "Almenn flugvélaþekking",
    "es": "Conocimientos generales de aeronaves"
  },
  "OPR": {
    "de": "Betriebliche Verfahren",
    "en": "Operational procedures",
    "sk": "Prevádzkové psotupy",
    "nl": "Operationele procedures",
    "ro": "Proceduri operaționale",
    "cs": "provozní postupy výknnost",
    "sl": "Operativni postopki",
    "fr": "Procédures opérationnelles",
    "fi": "Operatiiviset menetelmät",
    "is": "Verklagsreglur",
    "es": "Procedimientos operativos"
  },
  "FPP": {
    "de": "Flugleistung und Planung",
    "en": "Flight performance and planning",
    "sk": "Výkonnosť a plánovanie letu",
    "nl": "Vluchtprestaties en -planning",
    "ro": "Performanța și planificarea zborului",
    "cs": "Výkonnost a plánování letu",
    "sl": "Zmogljivost in načrtovanje letenja",
    "fr": "Performances et planification du vol",
    "fi": "Lennon suoritus ja suunnittelu",
    "is": "Flugframmistaða og skipulagning",
    "es": "Rendimiento y planificación de vuelo"
  },
  "PFA": {
    "de": "Grundlagen des Fliegens (Flugzeuge)",
    "en": "Basics of flying (Airplanes)",
    "sk": "základy lietanie (lietadlá)",
    "nl": "Grondbeginselen van het vliegen (Vliegtuigen)",
    "ro": "Bazele zborului (aeronave)",
    "cs": "Základy létání (letadla)",
    "sl": "Osnove letenja (letala)",
    "fr": "Bases du pilotage (Avions)",
    "fi": "Lentämisen perusteet (lentokoneet)",
    "is": "Grunnatriði flugs (flugvélar)",
    "es": "Conceptos básicos de vuelo (Aviones)"
  },
  "PFH": {
    "de": "Grundlagen des Fliegens (Helikopter)",
    "en": "Basics of flying (Helicopters)",
    "sk": "Základy lietanie (helikoptéra)",
    "nl": "Grondbeginselen van het vliegen (helikopter)",
    "ro": "Bazele zborului (elicopter)",
    "cs": "Základy létání (vrtulník)",
    "sl": "Osnove letenja (helikopterji)",
    "fr": "Bases du pilotage (Hélicoptères)",
    "fi": "Lentämisen perusteet (helikopterit)",
    "is": "Grunnatriði flugs (þyrlur)",
    "es": "Conceptos básicos de vuelo (Helicópteros)"
  },
  "PFB": {
    "de": "Grundlagen des Fliegens (Ballone)",
    "en": "Basics of flying (Hot Air Balloons",
    "sk": "Základy lietanie (teplovzdušný balón)",
    "nl": "Grondbeginselen van het vliegen (ballonnen)",
    "ro": "Bazele zborului (baloane)",
    "cs": "Základy létání (balóny)",
    "sl": "Osnove letenja (baloni na vroč zrak)",
    "fr": "Notions de base du vol (Montgolfières)",
    "fi": "Lentämisen perusteet (kuumailmapallo)",
    "is": "Grunnatriði í flugi (loftblöðrur",
    "es": "Conceptos básicos de vuelo (Globos aerostáticos)"
  },
  "ALW": {
    "de": "Luftrecht",
    "en": "Air Law",
    "sk": "Letecké právo",
    "nl": "Luchtrecht",
    "ro": "Legea aerului",
    "cs": "Zákon o ovzduší",
    "sl": "Zračno pravo",
    "fr": "Droit aérien",
    "fi": "Ilmailulainsäädäntö",
    "is": "Loftlög",
    "es": "Ley aérea"
  },
  "HPL": {
    "de": "Menschliches Leistungsvermögen",
    "en": "Human Performance",
    "sk": "Ľudská výkonnosť",
    "nl": "Menselijke prestaties",
    "ro": "Performanța umană",
    "cs": "Výkonnost člověka",
    "sl": "Človeška zmogljivost",
    "fr": "Performance humaine",
    "fi": "Inhimillinen suorituskyky",
    "is": "Mannleg frammistaða",
    "es": "Rendimiento Humano"
  },
  "MET": {
    "de": "Meteorologie",
    "en": "Meteorology",
    "sk": "Meteorológia",
    "nl": "Meteorologie",
    "ro": "Meteorologie",
    "cs": "Meteorologie",
    "sl": "Meteorologija",
    "fr": "Météorologie",
    "fi": "Meteorologia",
    "is": "Veðurfræði",
    "es": "Meteorología"
  },
  "NAV": {
    "de": "Navigation",
    "en": "Navigation",
    "sk": "Navigácia",
    "nl": "Navigatie",
    "ro": "Navigație",
    "cs": "Navigace",
    "sl": "Navigacija",
    "fr": "Navigation",
    "fi": "Navigointi",
    "is": "Leiðsögn",
    "es": "Navegación"
  },
  "COM": {
    "de": "Sprechfunk",
    "en": "Radiotelephony",
    "sk": "Rádiotelefónia",
    "nl": "Radiotelefonie",
    "ro": "Radiotelefonie",
    "cs": "Radiotelefonie",
    "sl": "Radiotelefonija",
    "fr": "Radiotéléphonie",
    "fi": "Radiopuhelinliikenne",
    "is": "Útvarpssímtækni",
    "es": "Radiotelefonía"
  },
  "additional_questions": {
    "de": "Weitere Fragen",
    "en": "Older questions",
    "sk": "Staršie otázky",
    "nl": "Oudere vragen",
    "ro": "Întrebări mai vechi",
    "cs": "Starší otázky",
    "sl": "Starejša vprašanja",
    "fr": "Questions plus anciennes",
    "fi": "Vanhemmat kysymykset",
    "is": "Eldri spurningar",
    "es": "Preguntas antiguas"
  },
  "additional_questions_label": {
    "de": "Weitere unbekannte Fragen anzeigen",
    "en": "Addionally show older questions",
    "sk": "Dodatočne zobraziť staršie otázky",
    "nl": "Toon bovendien oudere vragen",
    "ro": "Afișați în plus întrebări mai vechi",
    "cs": "Dodatečně zobrazit starší otázky",
    "sl": "Dodatno prikaži starejša vprašanja",
    "fr": "Afficher les questions plus anciennes",
    "fi": "Näytä lisäksi vanhemmat kysymykset",
    "is": "Sýndu að auki eldri spurningar",
    "es": "Mostrar adicionalmente preguntas antiguas"
  },
  "additional_questions_icon_hint": {
    "de": "Ähnliche, weitere und ältere Fragen aus dem Archiv",
    "en": "Questions from previous exams and from our own inventory",
    "sk": "Otázky z předchozích zkoušek a z našeho vlastního inventáře",
    "nl": "Vragen van eerdere examens en uit onze eigen voorraad",
    "ro": "Întrebări din examenele anterioare și din propriul nostru inventar",
    "cs": "Otázky z předchozích zkoušek a z našeho vlastního inventáře",
    "sl": "Vprašanja iz prejšnjih izpitov in iz lastne zaloge",
    "fr": "Questions des examens précédents et de notre propre inventaire",
    "fi": "Kysymyksiä aiemmista kokeista ja omasta varastostamme",
    "is": "Spurningar úr fyrrum prófum og úr eigin birgðum",
    "es": "Preguntas de exámenes anteriores y de nuestro inventario"
  },
  "main_ecqb_questions_hint": {
    "de": "Offiziell veröffentlichter Fragenkatalog",
    "en": "Published question catalog",
    "sk": "Zverejnený katalóg otázok",
    "nl": "Gepubliceerde vragenlijst",
    "ro": "Catalog de întrebări publicat",
    "cs": "Publikovaný katalog otázek",
    "sl": "Objavljen katalog vprašanj",
    "fr": "Catalogue de questions publié",
    "fi": "Julkaistu kysymysluettelo",
    "is": "Birt spurningakatalog",
    "es": "Catálogo de preguntas publicadas"
  },
  "recht": {
    "de": "Recht",
    "en": "Recht",
    "sk": "Recht",
    "nl": "Recht",
    "ro": "Recht",
    "cs": "Recht",
    "sl": "Recht",
    "fr": "Recht",
    "fi": "Recht",
    "is": "Recht",
    "es": "Derecho"
  },
  "technik": {
    "de": "Technik",
    "en": "Technik",
    "sk": "Technik",
    "nl": "Technik",
    "ro": "Technik",
    "cs": "Technik",
    "sl": "Technik",
    "fr": "Technik",
    "fi": "Technik",
    "is": "Technik",
    "es": "Técnica"
  },
  "sonderbestimmungen": {
    "de": "Sonderbestiummungen",
    "en": "Sonderbestiummungen",
    "sk": "Sonderbestiummungen",
    "nl": "Sonderbestiummungen",
    "ro": "Sonderbestiummungen",
    "cs": "Sonderbestiummungen",
    "sl": "Sonderbestiummungen",
    "fr": "Sonderbestiummungen",
    "fi": "Sonderbestiummungen",
    "is": "Sonderbestiummungen",
    "es": "Disposiciones especiales"
  },
  "BZF1_BZF2": {
    "de": "BZFI / BZFII ",
    "en": "BZFI / BZFII ",
    "sk": "BZFI / BZFII ",
    "nl": "BZFI / BZFII ",
    "ro": "BZFI / BZFII ",
    "cs": "BZFI / BZFII ",
    "sl": "BZFI / BZFII ",
    "fr": "BZFI / BZFII ",
    "fi": "BZFI / BZFII ",
    "is": "BZFI / BZFII ",
    "es": "BZFI / BZFII"
  },
  "changeQuestionCatalog": {
    "de": "Fragenkatalog ändern",
    "en": "Change question catalog",
    "sk": "Zmeniť katalóg otázok",
    "nl": "Wijzig vragen catalogus",
    "ro": "Schimbați catalogul de întrebări",
    "cs": "Změnit katalog otázek",
    "sl": "Spremeni katalog vprašanj",
    "fr": "Modifier le catalogue de questions",
    "fi": "Muuta kysymyskatalogia",
    "is": "Breyta spurningakatalógum",
    "es": "Cambiar catálogo de preguntas"
  },
  "change_general_settings": {
    "de": "Allgemeine Einstellungen ändern",
    "en": "Change general settings",
    "sk": "Zmeniť všeobecné nastavenia",
    "nl": "Algemene instellingen wijzigen",
    "ro": "Schimbați setările generale",
    "cs": "Změnit obecná nastavení",
    "sl": "Spremeni splošne nastavitve",
    "fr": "Modifier les paramètres généraux",
    "fi": "Muuta yleisiä asetuksia",
    "is": "Breyta almennum stillingum",
    "es": "Cambiar ajustes generales"
  },
  "settings_synchSettings": {
    "de": "Einstellungen jetzt synchronisieren",
    "en": "Sync settings",
    "sk": "Synchronizovať nastavenia",
    "nl": "Synchroniseer instellingen",
    "ro": "Sincronizați setările",
    "cs": "Synchronizovat nastavení",
    "sl": "Sinhroniziraj nastavitve",
    "fr": "Synchroniser les paramètres",
    "fi": "Synkronoi asetukset",
    "is": "Samstilla stillingar",
    "es": "Sincronizar ajustes"
  },
  "settings_synchSettings_in_progress": {
    "de": "Synchronisation läuft",
    "en": "Synchronization is running",
    "sk": "Prebieha synchronizácia",
    "nl": "Synchronisatie is bezig",
    "ro": "Sincronizarea este în curs",
    "cs": "Synchronizace probíhá",
    "sl": "Sinhronizacija poteka",
    "fr": "Synchronisation en cours",
    "fi": "Synkronointi käynnissä",
    "is": "Samstilling í gangi",
    "es": "Sincronización en curso"
  },
  "settings_synchSettings_is_done": {
    "de": "Erfolgreich synchronisiert!",
    "en": "Successfully synchronized!",
    "sk": "Synchronizácia úspešne dokončená!",
    "nl": "Synchronisatie succesvol afgerond!",
    "ro": "Sincronizare cu succes realizată!",
    "cs": "Synchronizace byla úspěšně dokončena!",
    "sl": "Sinhronizacija uspešno končana!",
    "fr": "Synchronisation réussie!",
    "fi": "Synkronointi onnistui!",
    "is": "Samstilling tókst!",
    "es": "¡Sincronización exitosa!"
  },
  "settings_synchSettings_has_error": {
    "de": "Fehler bei der Synchronisation",
    "en": "Error during synchronization",
    "sk": "Chyba počas synchronizácie",
    "nl": "Fout tijdens synchronisatie",
    "ro": "Eroare în timpul sincronizării",
    "cs": "Chyba při synchronizaci",
    "sl": "Napaka med sinhronizacijo",
    "fr": "Erreur lors de la synchronisation",
    "fi": "Virhe synkronoinnin aikana",
    "is": "Villa kom upp við samstillingu",
    "es": "Error durante la sincronización"
  },
  "last_syncronized": {
    "de": "Letzte Synchronisierung",
    "en": "Last synchronization",
    "sk": "Posledná synchronizácia",
    "nl": "Laatste synchronisatie",
    "ro": "Ultima sincronizare",
    "cs": "Poslední synchronizace",
    "sl": "Zadnja sinhronizacija",
    "fr": "Dernière synchronisation",
    "fi": "Viimeisin synkronointi",
    "is": "Síðasta samstilla",
    "es": "Última sincronización"
  },
  "manage_my_flightschool": {
    "de": "Meine Flugschule verwalten",
    "en": "Manage my flightschool",
    "sk": "Spravovať moju leteckú školu",
    "nl": "Mijn vliegschool beheren",
    "ro": "Gestionarea școlii mele de zbor",
    "cs": "Spravovat mou leteckou školu",
    "sl": "Upravljanje moje letalske šole",
    "fr": "Gérer mon école de pilotage",
    "fi": "Hallinnoi lentokoulua",
    "is": "Stjórna flugskólanum mínum",
    "es": "Administrar mi escuela de vuelo"
  },
  "back": {
    "de": "Zurück",
    "en": "Back",
    "sk": "Späť",
    "nl": "Terug",
    "ro": "Înapoi",
    "cs": "Zpět",
    "sl": "Nazaj",
    "fr": "Retour",
    "fi": "Takaisin",
    "is": "Til baka",
    "es": "Atrás"
  },
  "welcome_to_the_ato_dashboard": {
    "de": "Willkommen im ATO Dashboard",
    "en": "Welcome to the ATO dashboard",
    "sk": "Vitajte v ATO nástroji",
    "nl": "Welkom bij het ATO dashboard",
    "ro": "Bun venit în panoul de control ATO",
    "cs": "Vítejte v ATO nástroji",
    "sl": "Dobrodošli v ATO nadzorni plošči",
    "fr": "Bienvenue sur le tableau de bord ATO",
    "fi": "Tervetuloa ATO-ohjauspaneeliin",
    "is": "Velkomin í ATO stjórnborðið",
    "es": "Bienvenido al panel de control de ATO"
  },
  "here_you_get_your_ato_details": {
    "de": "Hier findest du einen aktuellen Überblick über deine Flugschule",
    "en": "Here you find an up-to-date overview of your flight school",
    "sk": "Tu získate aktuálny prehľad o vašej leteckej škole",
    "nl": "Hier krijg je een up-to-date overzicht van je vliegschool",
    "ro": "Aici obții o prezentare generală actualizată a școlii tale de zbor",
    "cs": "Zde získáte aktuální přehled o vaší letecké škole",
    "sl": "Tu dobite posodobljen pregled vaše letalske šole",
    "fr": "Ici, vous obtenez un aperçu à jour de votre école de pilotage",
    "fi": "Täällä saat ajantasaisen yleiskuvan lentokoulustasi",
    "is": "Hér færð þú nýjustu yfirlýsingu yfir flugskólann þinn",
    "es": "Aquí encontrarás una visión general actualizada de tu escuela de vuelo"
  },
  "account_valied_until": {
    "de": "Account gültig bis",
    "en": "Account valid until",
    "sk": "Účet platný do",
    "nl": "Account geldig tot",
    "ro": "Cont valabil până la",
    "cs": "Účet platný do",
    "sl": "Račun veljaven do",
    "fr": "Compte valide jusqu'au",
    "fi": "Tili voimassa asti",
    "is": "Reikningur gildir til",
    "es": "Cuenta válida hasta"
  },
  "days": {
    "de": "Tage",
    "en": "Days",
    "sk": "Dní",
    "nl": "Dagen",
    "ro": "Zile",
    "cs": "Dní",
    "sl": "Dni",
    "fr": "Jours",
    "fi": "Päivää",
    "is": "Dagar",
    "es": "Días"
  },
  "create_a_homework": {
    "de": "Erstelle eine Hausübung",
    "en": "Create a homework",
    "sk": "Vytvoriť úlohu",
    "nl": "Maak huiswerk",
    "ro": "Creați o temă",
    "cs": "Vytvořit úkol",
    "sl": "Ustvarite domačo nalogo",
    "fr": "Créer un devoir",
    "fi": "Luo kotitehtävä",
    "is": "Búa til heimadæmi",
    "es": "Crear una tarea"
  },
  "create_an_exam": {
    "de": "Erstelle eine Prüfung",
    "en": "Create an exam",
    "sk": "Vytvoriť skúšku",
    "nl": "Maak een examen",
    "ro": "Creează un examen",
    "cs": "Vytvořit zkoušku",
    "sl": "Ustvari izpit",
    "fr": "Créer un examen",
    "fi": "Luo tentti",
    "is": "Búa til próf",
    "es": "Crear un examen"
  },
  "create_flightstudent": {
    "de": "Flugschüler hinzufügen",
    "en": "Add flight student",
    "sk": "Pridať študenta pilotáže",
    "nl": "Vliegscholier toevoegen",
    "ro": "Adaugă student pilot",
    "cs": "Přidat studenta pilotáže",
    "sl": "Dodaj študenta letenja",
    "fr": "Ajouter un élève-pilote",
    "fi": "Lisää lento-oppilas",
    "is": "Bæta flugnámsmanni við",
    "es": "Agregar estudiante de vuelo"
  },
  "add_student_with_license": {
    "de": "Einen Flugschüler mit einer PPL Trainer Lizenz hinzufügen",
    "en": "Add a flight student with a PPL trainer license",
    "sk": "Pridajte študenta letu s PPL trénerom licencie",
    "nl": "Voeg een vliegstudent met een PPL-trainerlicentie toe",
    "ro": "Adăugați un student de zbor cu o licență de instructor PPL",
    "cs": "Přidat studenta letu s PPL trenérskou licencí",
    "sl": "Dodaj študenta letenja s PPL licenco trenerja",
    "fr": "Ajouter un étudiant en vol avec une licence de formateur PPL",
    "fi": "Lisää lentokoulutettava, jolla on PPL-kouluttajalisenssi",
    "is": "Bæta við flugnemi með PPL þjálfaraleyfi",
    "es": "Agregar un estudiante de vuelo con licencia de instructor PPL"
  },
  "flightstudent": {
    "de": "Flugschüler",
    "en": "Flight student",
    "sk": "Študent letu",
    "nl": "Vliegstudent",
    "ro": "Student de zbor",
    "cs": "Student letu",
    "sl": "Študent letenja",
    "fr": "Étudiant en vol",
    "fi": "Lentokoulutettava",
    "is": "Flugnemi",
    "es": "Estudiante de vuelo"
  },
  "actions": {
    "de": "Aktionen",
    "en": "Actions",
    "sk": "Akcie",
    "nl": "Acties",
    "ro": "Acțiuni",
    "cs": "Akce",
    "sl": "Dejanja",
    "fr": "Actions",
    "fi": "Toiminnot",
    "is": "Aðgerðir",
    "es": "Acciones"
  },
  "all_categories": {
    "de": "Alle Kategorien",
    "en": "All categories",
    "sk": "Všetky kategórie",
    "nl": "Alle categorieën",
    "ro": "Toate categoriile",
    "cs": "Všechny kategorie",
    "sl": "Vse kategorije",
    "fr": "Toutes les catégories",
    "fi": "Kaikki kategoriat",
    "is": "Allir flokkar",
    "es": "Todas las categorías"
  },
  "add_category": {
    "de": "Kategorie hinzufügen",
    "en": "Add category",
    "sk": "Pridať kategóriu",
    "nl": "Categorie toevoegen",
    "ro": "Adăugați categorie",
    "cs": "Přidat kategorii",
    "sl": "Dodaj kategorijo",
    "fr": "Ajouter une catégorie",
    "fi": "Lisää kategoria",
    "is": "Bæta við flokk",
    "es": "Agregar categoría"
  },
  "category": {
    "de": "Kategorie",
    "en": "Category",
    "sk": "Kategória",
    "nl": "Categorie",
    "ro": "Categorie",
    "cs": "Kategorie",
    "sl": "Kategorija",
    "fr": "Catégorie",
    "fi": "Kategoria",
    "is": "Flokkur",
    "es": "Categoría"
  },
  "add_question": {
    "de": "Frage hinzufügen",
    "en": "Add question",
    "sk": "Pridať otázku",
    "nl": "Vraag toevoegen",
    "ro": "Adăugați întrebare",
    "cs": "Přidat otázku",
    "sl": "Dodaj vprašanje",
    "fr": "Ajouter une question",
    "fi": "Lisää kysymys",
    "is": "Bæta við spurningu",
    "es": "Agregar pregunta"
  },
  "hidden": {
    "de": "Versteckt",
    "en": "Hidden",
    "sk": "Skrytý",
    "nl": "Verborgen",
    "ro": "Ascuns",
    "cs": "Skrytý",
    "sl": "Skrito",
    "fr": "Caché",
    "fi": "Piilotettu",
    "is": "Falið",
    "es": "Oculto"
  },
  "show_this_question_in_ato_only": {
    "de": "Die Frage nur bei Prüfungen der Flugschule anzeigen.",
    "en": "Show the question only during exams of the flight school.",
    "sk": "Zobraziť otázku iba počas skúšok leteckej školy.",
    "nl": "Toon de vraag alleen tijdens examens van de vliegschool.",
    "ro": "Afișați întrebarea doar în timpul examenelor școlii de zbor.",
    "cs": "Zobrazit otázku pouze během zkoušek letecké školy.",
    "sl": "Prikaži vprašanje samo med izpiti letalske šole.",
    "fr": "Afficher la question uniquement pendant les examens de l'école de pilotage.",
    "fi": "Näytä kysymys vain lentokoulun kokeissa.",
    "is": "Sýna spurninguna aðeins í flugskólastöðvunum.",
    "es": "Mostrar la pregunta solo durante los exámenes de la escuela de vuelo."
  },
  "first_answer_in_green_is_correct": {
    "de": "Die erste Antwort (in grün) ist korrekt.",
    "en": "The first answer (in green) is correct.",
    "sk": "Prvá odpoveď (v zelenej farbe) je správna.",
    "nl": "Het eerste antwoord (in groen) is juist.",
    "ro": "Prima răspuns (în verde) este corect.",
    "cs": "První odpověď (v zelené) je správná.",
    "sl": "Prvi odgovor (zelen) je pravilen.",
    "fr": "La première réponse (en vert) est correcte.",
    "fi": "Ensimmäinen vastaus (vihreässä) on oikein.",
    "is": "Fyrsta svarið (í grænum lit) er rétt.",
    "es": "La primera respuesta (en verde) es correcta."
  },
  "answers": {
    "de": "Antworten",
    "en": "Answers",
    "sk": "Odpovede",
    "nl": "Antwoorden",
    "ro": "Răspunsuri",
    "cs": "Odpovědi",
    "sl": "Odgovori",
    "fr": "Réponses",
    "fi": "Vastaukset",
    "is": "Svör",
    "es": "Respuestas"
  },
  "question": {
    "de": "Frage",
    "en": "Question",
    "sk": "Otázka",
    "nl": "Vraag",
    "ro": "Întrebare",
    "cs": "Otázka",
    "sl": "Vprašanje",
    "fr": "Question",
    "fi": "Kysymys",
    "is": "Spurning",
    "es": "Pregunta"
  },
  "create_homework": {
    "de": "Hausübung erstellen",
    "en": "Create homework",
    "sk": "Vytvoriť domáce úlohy",
    "nl": "Huiswerk maken",
    "ro": "Creați teme",
    "cs": "Vytvořit domácí úkol",
    "sl": "Ustvarite domače naloge",
    "fr": "Créer des devoirs",
    "fi": "Luo kotitehtävä",
    "is": "Búa til heimavinna",
    "es": "Crear tarea"
  },
  "search": {
    "de": "Suche",
    "en": "Search",
    "sk": "Vyhľadávanie",
    "nl": "Zoeken",
    "ro": "Căutare",
    "cs": "Vyhledávání",
    "sl": "Iskanje",
    "fr": "Recherche",
    "fi": "Haku",
    "is": "Leita",
    "es": "Buscar"
  },
  "email": {
    "de": "E-Mail",
    "en": "Email",
    "sk": "E-mail",
    "nl": "E-mail",
    "ro": "Email",
    "cs": "Email",
    "sl": "E-pošta",
    "fr": "Email",
    "fi": "Sähköposti",
    "is": "Tölvupóstur",
    "es": "Correo electrónico"
  },
  "flightschool_classes": {
    "de": "Flugschüler Klassen",
    "en": "Student classes",
    "sk": "Študentské triedy",
    "nl": "Studentenklassen",
    "ro": "Clase de studenți",
    "cs": "Studenti třídy",
    "sl": "Študentski razredi",
    "fr": "Classes d'étudiants",
    "fi": "Opiskelijaluokat",
    "is": "Nemendaklassar",
    "es": "Clases de estudiantes"
},
"all_flight_students": {
    "de": "Flugschüler",
    "en": "Flight students",
    "sk": "Študenti letu",
    "nl": "Vliegstudenten",
    "ro": "Studenți de zbor",
    "cs": "Studenti letu",
    "sl": "Študenti letenja",
    "fr": "Élèves pilotes",
    "fi": "Lentokoulun oppilaat",
    "is": "Flugnemendur",
    "es": "Estudiantes de vuelo"
},
"all_question_categories": {
    "de": "Fragen Kategorien",
    "en": "Question categories",
    "sk": "Kategórie otázok",
    "nl": "Vraagcategorieën",
    "ro": "Categorii de întrebări",
    "cs": "Kategorie otázek",
    "sl": "Kategorije vprašanj",
    "fr": "Catégories de questions",
    "fi": "Kysymyskategoriat",
    "is": "Flokkar spurninga",
    "es": "Categorías de preguntas"
  },
  "class": {
    "de": "Klasse",
    "en": "Class",
    "sk": "Trieda",
    "nl": "Klas",
    "ro": "Clasă",
    "cs": "Třída",
    "sl": "Razred",
    "fr": "Classe",
    "fi": "Luokka",
    "is": "Námskeið",
    "es": "Clase"
  },
  "count_of_flightstudents": {
    "de": "Alle Flugschüler",
    "en": "All flight students",
    "sk": "Všetci študenti letectva",
    "nl": "Alle vliegstudie studenten",
    "ro": "Toți studenții de zbor",
    "cs": "Všichni studenti letectví",
    "sl": "Vsi letalski študenti",
    "fr": "Tous les étudiants en pilotage",
    "fi": "Kaikki lentokoulun opiskelijat",
    "is": "Allir nemendur í flugskólanum",
    "es": "Todos los estudiantes de vuelo"
  },
  "category_count": {
    "de": "Fragen Kategorien",
    "en": "Question categories",
    "sk": "Všetky kategórie otázok",
    "nl": "Alle vraagcategorieën",
    "ro": "Toate categoriile de întrebări",
    "cs": "Všechny kategorie otázek",
    "sl": "Vse kategorije vprašanj",
    "fr": "Toutes les catégories de questions",
    "fi": "Kaikki kysymyskategoriat",
    "is": "Allar spurningaflokkar",
    "es": "Todas las categorías de preguntas"
  },
  "questions_count": {
    "de": "Fragen",
    "en": "Customs from flightschool",
    "sk": "Miestne colné procedúry",
    "nl": "Douane van vluchtschool",
    "ro": "Obiceiuri de la școala de zbor",
    "cs": "Celní odbavení na letišti",
    "sl": "Carine letalske šole",
    "fr": "Douanes de l'école de pilotage",
    "fi": "Tulli lentokoululta",
    "is": "Tollur frá flugskóla",
    "es": "Costumbres de la escuela de vuelo"
  },
  "add_student_group": {
    "de": "Klasse hinzufügen",
    "en": "Add class",
    "sk": "Pridať triedu",
    "nl": "Klas toevoegen",
    "ro": "Adaugă o clasă",
    "cs": "Přidat třídu",
    "sl": "Dodaj razred",
    "fr": "Ajouter une classe",
    "fi": "Lisää luokka",
    "is": "Bæta bekk við",
    "es": "Agregar una clase"
  },
  "no_groups_available": {
    "de": "Keine Flugschüler Klasse vorhanden.",
    "en": "No flight student class available.",
    "sk": "Žiadna dostupná trieda pre študentov lietania.",
    "nl": "Geen vliegstudentenklas beschikbaar.",
    "ro": "Nicio clasă de studenți de zbor disponibilă.",
    "cs": "Žádná třída pro studenty letectví není k dispozici.",
    "sl": "Na voljo ni nobenega razreda za letalske študente.",
    "fr": "Aucune classe d'élève pilote disponible.",
    "fi": "Ei lentokoulun oppilasluokkia saatavilla.",
    "is": "Engin flugnemandaklasi í boði.",
    "es": "No hay clases disponibles de estudiantes de vuelo."
  },
  "no_students_available": {
    "de": "Keine Flugschüler vorhanden.",
    "en": "No flight student available.",
    "sk": "Žiadny žiak letu nie je k dispozícii.",
    "nl": "Geen vliegstudent beschikbaar.",
    "ro": "Nu există elev de zbor disponibil.",
    "cs": "Žádný student letu není k dispozici.",
    "sl": "Ni na voljo noben letalski študent.",
    "fr": "Aucun étudiant en vol disponible.",
    "fi": "Ei lento-opiskelijaa saatavilla.",
    "is": "Enginn flugneminn er tiltækur.",
    "es": "No hay estudiantes de vuelo disponibles."
  },
  "no_categorys_available": {
    "de": "Keine Kategorien vorhanden.",
    "en": "No categories available.",
    "sk": "Žiadne kategórie nie sú k dispozícii.",
    "nl": "Geen categorieën beschikbaar.",
    "ro": "Nu există categorii disponibile.",
    "cs": "Žádné kategorie nejsou k dispozici.",
    "sl": "Ni na voljo nobene kategorije.",
    "fr": "Aucune catégorie disponible.",
    "fi": "Ei kategorioita saatavilla.",
    "is": "Engar flokkar eru tiltækir.",
    "es": "No hay categorías disponibles."
  },
  "no_questions_available": {
    "de": "Keine Fragen vorhanden.",
    "en": "No questions available.",
    "sk": "Žiadne otázky nie sú k dispozícii.",
    "nl": "Geen vragen beschikbaar.",
    "ro": "Nu există întrebări disponibile.",
    "cs": "Žádné otázky nejsou k dispozici.",
    "sl": "Ni na voljo nobenega vprašanja.",
    "fr": "Aucune question disponible.",
    "fi": "Ei kysymyksiä saatavilla.",
    "is": "Engar spurningar eru tiltækar.",
    "es": "No hay preguntas disponibles."
  },
  "current_exams": {
    "de": "Aktuelle Prüfungen",
    "en": "Latest exams",
    "sk": "Aktuálne skúšky",
    "nl": "Laatste examens",
    "ro": "Ultimele examene",
    "cs": "Poslední zkoušky",
    "sl": "Zadnji izpiti",
    "fr": "Derniers examens",
    "fi": "Viimeisimmät tentit",
    "is": "Nýjustu prófin",
    "es": "Últimos exámenes"
  },
  "current_homework": {
    "de": "Hausübungen",
    "en": "Homework",
    "sk": "Domáca úloha",
    "nl": "Huiswerk",
    "ro": "Tema pentru acasă",
    "cs": "Domácí úkol",
    "sl": "Domača naloga",
    "fr": "Devoirs",
    "fi": "Läksyt",
    "is": "Heimavinna",
    "es": "Tarea"
  },
  "no_exams_available": {
    "de": "Keine aktiven Prüfungen vorhanden.",
    "en": "No active exams available.",
    "sk": "Nie sú k dispozícii žiadne aktívne skúšky.",
    "nl": "Geen actieve examens beschikbaar.",
    "ro": "Nu sunt disponibile examene active.",
    "cs": "Nejsou k dispozici žádné aktivní zkoušky.",
    "sl": "Ni aktivnih izpitov ni na voljo.",
    "fr": "Aucun examen actif disponible.",
    "fi": "Ei ole aktiivisia tenttejä saatavilla.",
    "is": "Engin virk próf eru í boði.",
    "es": "No hay exámenes activos disponibles."
  },
  "no_own_exams_available": {
    "de": "Keine eigenen Prüfungen vorhanden.",
    "en": "No own exams available.",
    "sk": "Žiadne vlastné skúšky nie sú k dispozícii.",
    "nl": "Geen eigen examens beschikbaar.",
    "ro": "Nu există examene proprii disponibile.",
    "cs": "Žádné vlastní zkoušky nejsou k dispozici.",
    "sl": "Lastni izpiti niso na voljo.",
    "fr": "Aucun examen personnel disponible.",
    "fi": "Ei omia kokeita saatavilla.",
    "is": "Engin eigin próf tiltæk.",
    "es": "No hay exámenes propios disponibles."
  },
  "homework": {
    "de": "Hausaufgaben",
    "en": "Homework",
    "sk": "Domáce úlohy",
    "nl": "Huiswerk",
    "ro": "Teme de casă",
    "cs": "Domácí úkoly",
    "sl": "Domace naloge",
    "fr": "Devoirs",
    "fi": "Kotitehtävät",
    "is": "Heimavinna",
    "es": "Tarea"
  },
  "exams": {
    "de": "Prüfungen",
    "en": "Exams",
    "sk": "Skúšky",
    "nl": "Examens",
    "ro": "Examene",
    "cs": "Zkoušky",
    "sl": "Izpiti",
    "fr": "Examens",
    "fi": "Tentit",
    "is": "Próf",
    "es": "Exámenes"
  },
  "all_exams": {
    "de": "Alle Prüfungen anzeigen",
    "en": "Show all exams",
    "sk": "Zobraziť všetky skúšky",
    "nl": "Toon alle examens",
    "ro": "Afișați toate examenele",
    "cs": "Zobrazit všechny zkoušky",
    "sl": "Pokaži vse izpite",
    "fr": "Afficher tous les examens",
    "fi": "Näytä kaikki kokeet",
    "is": "Sýna allar próf",
    "es": "Mostrar todos los exámenes"
  },
  "all_homework": {
    "de": "Alle Hausübungen anzeigen",
    "en": "Show all home exercises",
    "sk": "Zobraziť všetky domáce cvičenia",
    "nl": "Toon alle huiswerkoefeningen",
    "ro": "Afișați toate temele pentru acasă",
    "cs": "Zobrazit všechny domácí úkoly",
    "sl": "Pokaži vse domače naloge",
    "fr": "Afficher tous les devoirs",
    "fi": "Näytä kaikki läksyt",
    "is": "Sýna allan heimavinna",
    "es": "Mostrar todos los ejercicios en casa"
  },
  "all_own_exams": {
    "de": "Alle eigenen Prüfungen anzeigen",
    "en": "Show all own exams",
    "sk": "Zobraziť všetky vlastné skúšky",
    "nl": "Toon alle eigen examens",
    "ro": "Afișați toate examenele proprii",
    "cs": "Zobrazit všechny vlastní zkoušky",
    "sl": "Prikaži vse lastne izpite",
    "fr": "Afficher tous les examens personnels",
    "fi": "Näytä kaikki omat kokeet",
    "is": "Sýna öll eigin próf",
    "es": "Mostrar todos los exámenes propios"
  },
  "language": {
    "de": "Sprache",
    "en": "Language",
    "sk": "Jazyk",
    "nl": "Taal",
    "ro": "Limba",
    "cs": "Jazyk",
    "sl": "Jezik",
    "fr": "Langue",
    "fi": "Kieli",
    "is": "Tungumál",
    "es": "Idioma"
  },
  "no_saved_questions_available": {
    "de": "Keine gespeicherten Fragen vorhanden.",
    "en": "No saved questions available.",
    "sk": "Žiadne uložené otázky nie sú k dispozícii.",
    "nl": "Geen opgeslagen vragen beschikbaar.",
    "ro": "Nu sunt disponibile întrebări salvate.",
    "cs": "Žádné uložené otázky nejsou k dispozici.",
    "sl": "Ni shranjenih vprašanj na voljo.",
    "fr": "Aucune question sauvegardée disponible.",
    "fi": "Ei tallennettuja kysymyksiä saatavilla.",
    "is": "Engar vistaðar spurningar eru í boði.",
    "es": "No hay preguntas guardadas disponibles."
  },
  "saved_questions": {
    "de": "Gespeicherte Fragen",
    "en": "Saved questions",
    "sk": "Uložené otázky",
    "nl": "Opgeslagen vragen",
    "ro": "Întrebări salvate",
    "cs": "Uložené otázky",
    "sl": "Shranjena vprašanja",
    "fr": "Questions sauvegardées",
    "fi": "Tallennetut kysymykset",
    "is": "Vistaðar spurningar",
    "es": "Preguntas guardadas"
  },
  "saved_question": {
    "de": "Gespeicherte Frage",
    "en": "Saved question",
    "sk": "Uložená otázka",
    "nl": "Opgeslagen vraag",
    "ro": "Întrebare salvată",
    "cs": "Uložená otázka",
    "sl": "Shranjeno vprašanje",
    "fr": "Question sauvegardée",
    "fi": "Tallennettu kysymys",
    "is": "Vista spurningu",
    "es": "Pregunta guardada"
  },
  "under_construction": {
    "de": "Für diese Frage wird noch an einer ausführlichen Erklärung gearbeitet.",
    "en": "We are currently working on a detailed explanation for this question.",
    "sk": "Momentálne pracujeme na podrobnom vysvetlení tejto otázky.",
    "nl": "We werken momenteel aan een gedetailleerde uitleg voor deze vraag.",
    "ro": "Lucrăm în prezent la o explicație detaliată pentru această întrebare.",
    "cs": "Právě pracujeme na podrobném vysvětlení této otázky.",
    "sl": "Trenutno delamo na podrobnem pojasnilu za to vprašanje.",
    "fr": "Nous travaillons actuellement sur une explication détaillée pour cette question.",
    "fi": "Työstämme tällä hetkellä yksityiskohtaista selitystä tähän kysymykseen.",
    "is": "Við erum núna að vinna að ítarlegri útskýringu á þessari spurningu.",
    "es": "Actualmente estamos trabajando en una explicación detallada para esta pregunta."
  },
  "under_construction_help": {
    "de": "Wenn du bereits eine genauere Erklärung hast, freuen wir uns auf deine Unterstützung.",
    "en": "If you already have a more detailed explanation, we look forward to your support.",
    "sk": "Ak už máte podrobnejšie vysvetlenie, tešíme sa na vašu podporu.",
    "nl": "Als u al een meer gedetailleerde uitleg heeft, kijken we uit naar uw steun.",
    "ro": "Dacă aveți deja o explicație mai detaliată, așteptăm cu nerăbdare sprijinul dumneavoastră.",
    "cs": "Pokud již máte podrobnější vysvětlení, těšíme se na vaši podporu.",
    "sl": "Če že imate bolj podrobno pojasnilo, se veselimo vaše podpore.",
    "fr": "Si vous avez déjà une explication plus détaillée, nous attendons avec impatience votre soutien.",
    "fi": "Jos sinulla on jo yksityiskohtaisempi selitys, odotamme tukeasi.",
    "is": "Ef þú hefur nú þegar skýringu sem er nánari, hlökkum við til stuðnings þíns.",
    "es": "Si ya tienes una explicación más detallada, esperamos contar con tu apoyo."
  },
  "no_homework_available": {
    "de": "Keine aktiven Hausübungen vorhanden.",
    "en": "No active homework available.",
    "sk": "Nie sú k dispozícii žiadne aktívne domáce úlohy.",
    "nl": "Geen actief huiswerk beschikbaar.",
    "ro": "Nu sunt disponibile teme active.",
    "cs": "Nejsou k dispozici žádné aktivní domácí úkoly.",
    "sl": "Ni aktiven domačih nalog za opraviti.",
    "fr": "Aucun devoir actif disponible.",
    "fi": "Ei ole aktiivisia läksyjä saatavilla.",
    "is": "Engin virk heimavinna í boði.",
    "es": "No hay tareas activas disponibles."
  },
  "all_current_homeworks": {
    "de": "Aktuelle Hausübungen",
    "en": "Current homework",
    "sk": "Všetky aktuálne domáce úlohy",
    "nl": "Alle huidige huiswerk",
    "ro": "Toate temele actuale",
    "cs": "Všechny aktuální domácí úkoly",
    "sl": "Vse trenutne domače naloge",
    "fr": "Tous les devoirs en cours",
    "fi": "Kaikki nykyiset läksyt",
    "is": "Allt núverandi heimavinna",
    "es": "Todas las tareas actuales"
  },
  "overview_of_all_exams_from_past": {
    "de": "Übersicht über alle abgeschlossenen Prüfungen.",
    "en": "Overview of all completed exams.",
    "sk": "Prehľad všetkých dokončených skúšok.",
    "nl": "Overzicht van alle voltooide examens.",
    "ro": "Prezentare generală a tuturor examenelor finalizate.",
    "cs": "Přehled všech dokončených zkoušek.",
    "sl": "Pregled vseh končanih izpitov.",
    "fr": "Aperçu de tous les examens terminés.",
    "fi": "Yleiskatsaus kaikista suoritetuista kokeista.",
    "is": "Yfirsýn yfir öllum lokiðum prófum.",
    "es": "Resumen de todos los exámenes completados."
  },
  "overview_of_all_homeworks_from_past": {
    "de": "Übersicht über alle abgeschlossenen Hausübungen.",
    "en": "Overview of all completed home exercises.",
    "sk": "Prehľad všetkých dokončených domácich cvičení.",
    "nl": "Overzicht van alle voltooide huiswerkoefeningen.",
    "ro": "Prezentare generală a tuturor temelor pentru acasă finalizate.",
    "cs": "Přehled všech dokončených domácích cvičení.",
    "sl": "Pregled vseh končanih domačih vaj.",
    "fr": "Aperçu de tous les exercices à domicile terminés.",
    "fi": "Yleiskatsaus kaikista suoritetuista kotiharjoituksista.",
    "is": "Yfirsýn yfir öllum lokiðum heimavinnum.",
    "es": "Resumen de todos los ejercicios en casa completados."
  },
  "create_new_class": {
    "de": "Neue Klasse erstellen",
    "en": "Create new class",
    "sk": "Vytvoriť novú triedu",
    "nl": "Maak nieuwe klas aan",
    "ro": "Creează o clasă nouă",
    "cs": "Vytvořit novou třídu",
    "sl": "Ustvari novo razred",
    "fr": "Créer une nouvelle classe",
    "fi": "Luo uusi luokka",
    "is": "Búa til nýtt bekk",
    "es": "Crear nueva clase"
  },
  "create_new_class_hint": {
    "de": "Erstellen Sie eine neue Klasse (z.B. 'Sommer 2025') und verlinke die Flugschüler mit dieser.",
    "en": "Create a new class (e.g. 'Summer 2025') and link the student pilots to it.",
    "sk": "Vytvorte novú triedu (napr. 'Leto 2025') a prepojte s ňou študentov-pilotov.",
    "nl": "Maak een nieuwe klas aan (bijv. 'Zomer 2025') en koppel de studentpiloten eraan.",
    "ro": "Creați o nouă clasă (de exemplu, 'Vara 2025') și legați piloții studenți de aceasta.",
    "cs": "Vytvořte novou třídu (např. 'Léto 2025') a propojte s ní studenty piloty.",
    "sl": "Ustvarite novo razred (npr. 'Poletje 2025') in povežite študentske pilote z njim.",
    "fr": "Créez une nouvelle classe (par exemple, 'Été 2025') et liez les élèves-pilotes à celle-ci.",
    "fi": "Luo uusi luokka (esim. 'Kesä 2025') ja linkitä opiskelijalentäjät siihen.",
    "is": "Búðu til nýja bekk (td 'Sumar 2025') og tengdu flugnemana við hann.",
    "es": "Crea una nueva clase (por ejemplo, 'Verano 2025') y vincula los estudiantes pilotos a ella."
  },
  "save": {
    "de": "Speichern",
    "en": "Save",
    "sk": "Uložiť",
    "nl": "Opslaan",
    "ro": "Salvează",
    "cs": "Uložit",
    "sl": "Shrani",
    "fr": "Enregistrer",
    "fi": "Tallenna",
    "is": "Vista",
    "es": "Guardar"
  },
  "link_flight_student": {
    "de": "Neuen Flugschüler hinzufügen",
    "en": "Add new flight student",
    "sk": "Pripojiť nového študenta pilotáže",
    "nl": "Nieuwe vliegscholier koppelen",
    "ro": "Legați un nou student pilot",
    "cs": "Přidat nového studenta pilotáže",
    "sl": "Poveži novega študenta letenja",
    "fr": "Lier un nouvel élève-pilote",
    "fi": "Liitä uusi lento-oppilas",
    "is": "Tengja nýjan flugnámsmann",
    "es": "Vincular un nuevo estudiante de vuelo"
  },
  "link_flight_student_hint_1": {
    "de": "Erstellen und verknüpfen Sie Ihre Flugschüler. Der Flugschüler erhält eine Email zur Bestätigung.",
    "en": "Create and link your flight students. The student pilot receives a confirmation email.",
    "sk": "Vytvorte a prepojte svojich študentov lietania. Študent pilot dostane e-mail s potvrdením.",
    "nl": "Koppel uw vliegscholieren en koppel ze. De vliegscholier ontvangt een bevestigings-e-mail.",
    "ro": "Creați și conectați-vă piloții de zbor. Studentul pilot primește un e-mail de confirmare.",
    "cs": "Vytvořte a propojte své studenty piloty. Student pilot obdrží potvrzovací e-mail.",
    "sl": "Ustvarite in povežite svoje pilote letenja. Študent pilot prejme potrditveno e-poštno sporočilo.",
    "fr": "Créez et liez vos élèves-pilotes. L'élève-pilote reçoit un e-mail de confirmation.",
    "fi": "Luo ja linkitä lento-oppilaasi. Lento-oppilas saa vahvistussähköpostin.",
    "is": "Búðu til og tengdu flugnámsmenn þína. Flugnámsmaðurinn fær staðfestingatölvupóst.",
    "es": "Crea y vincula a tus estudiantes de vuelo. El alumno piloto recibe un correo electrónico de confirmación."
  },
  "link_flight_student_hint_2": {
    "de": "Wenn der Flugschüler noch keinen eigenen PPL Trainer Zugang hat, wird automatisch ein neuer Account für ihn erstellt.",
    "en": "If the student pilot does not yet have their own PPL trainer account, a new account is automatically created for them.",
    "sk": "Ak študent pilotáže nemá vlastný PPL Trainer prístup, automaticky sa preňho vytvorí nový účet.",
    "nl": "Als de vliegscholier nog geen eigen PPL-traineraccount heeft, wordt er automatisch een nieuw account voor hem aangemaakt.",
    "ro": "Dacă studentul pilot nu are încă un cont propriu de antrenor PPL, se va crea automat un cont nou pentru el.",
    "cs": "Pokud student pilotáže nemá vlastní PPL Trainer přístup, automaticky se pro něj vytvoří nový účet.",
    "sl": "Če študent letenja še nima lastnega PPL Trainer dostopa, se mu bo samodejno ustvaril nov račun.",
    "fr": "Si l'élève-pilote n'a pas encore son propre compte PPL Trainer, un nouveau compte est automatiquement créé pour lui.",
    "fi": "Jos lento-oppilaalla ei ole vielä omaa PPL Trainer -tiliä, hänelle luodaan automaattisesti uusi tili.",
    "is": "Ef flugnámsmaðurinn hefur ekki enn sitt eigið PPL Trainer reikning, verður sjálfkrafa búið til nýr reikningur fyrir hann.",
    "es": "Si el estudiante piloto aún no tiene su propia cuenta de entrenador PPL, se creará automáticamente una nueva cuenta para él."
  },
  "ppltrainer_account_is_required_by_students": {
    "de": "Um einen Flugschüler zu verknüpfen, ist ein PPL Trainer Account vom Flugschüler notwendig.",
    "en": "In order to link a student pilot, a PPL trainer account is required from the student pilot.",
    "sk": "Na prepojenie študenta pilota je potrebný účet PPL Trainer od študenta pilota.",
    "nl": "Om een leerlingpiloot te koppelen is een PPL Trainer-account van de leerlingpiloot vereist.",
    "ro": "Pentru a lega un pilot de student, este necesar un cont de PPL Trainer de la pilotul de student.",
    "cs": "Pro propojení studenta pilota je vyžadován účet PPL Trainer od studenta pilota.",
    "sl": "Za povezavo študentskega pilota je potreben račun PPL Trainer od študentskega pilota.",
    "fr": "Pour lier un pilote étudiant, un compte de PPL Trainer est requis par le pilote étudiant.",
    "fi": "Opiskelijalentäjän linkittämiseksi opiskelijalentäjän on oltava PPL Trainer -tili.",
    "is": "Til að tengja námsflugmann þarf að hafa PPL Trainer reikning frá námsflugmanni.",
    "es": "Para vincular a un piloto estudiante, se requiere una cuenta de PPL Trainer del piloto estudiante."
  },
  "first_create_a_class": {
    "de": "Zuerst eine neue Klasse anlegen.",
    "en": "Create a new class first.",
    "sk": "Najprv vytvorte novú triedu.",
    "nl": "Maak eerst een nieuwe klas aan.",
    "ro": "Creați mai întâi o nouă clasă.",
    "cs": "Nejprve vytvořte novou třídu.",
    "sl": "Najprej ustvarite novo razred.",
    "fr": "Créez d'abord une nouvelle classe.",
    "fi": "Luo ensin uusi luokka.",
    "is": "Búa til nýja bekk fyrst.",
    "es": "Crea una nueva clase primero."
  },
  "provide_first_and_last_name": {
    "de": "Bitte gib den Vorname und Nachname an.",
    "en": "Please enter the first name and last name.",
    "sk": "Zadajte křestné meno a priezvisko.",
    "nl": "Vul de voornaam en achternaam in.",
    "ro": "Introduceți prenumele și numele de familie.",
    "cs": "Zadejte křestní jméno a příjmení.",
    "sl": "Vnesite ime in priimek.",
    "fr": "Veuillez entrer le prénom et le nom de famille.",
    "fi": "Kirjoita etunimi ja sukunimi.",
    "is": "Vinsamlegast sláðu inn fornafn og eftirnafn.",
    "es": "Por favor ingrese el nombre y apellido."
  },
  "first_and_last_name": {
    "de": "Vor- und Nachname",
    "en": "First and last name",
    "sk": "Meno a priezvisko",
    "nl": "Voornaam en achternaam",
    "ro": "Prenume și nume de familie",
    "cs": "Jméno a příjmení",
    "sl": "Ime in priimek",
    "fr": "Prénom et nom",
    "fi": "Etunimi ja sukunimi",
    "is": "Fornafn og eftirnafn",
    "es": "Nombre y apellido"
  },
  "an_account_must_already_be_linked": {
    "de": "Ein Account muss bereits mit der E-Mail Adresse vorhanden sein!",
    "en": "An account must already exist with the e-mail address!",
    "sk": "Účet už musí existovať s touto e-mailovou adresou!",
    "nl": "Er moet al een account bestaan met het opgegeven e-mailadres!",
    "ro": "Un cont trebuie să existe deja cu adresa de e-mail furnizată!",
    "cs": "Účet s tímto e-mailovým adresem již musí existovat!",
    "sl": "Račun mora že obstajati s podano e-poštnim naslovom!",
    "fr": "Un compte doit déjà exister avec l'adresse e-mail fournie !",
    "fi": "Tilillä täytyy jo olla aktiivinen PPL Trainer -tunnus tällä sähköpostiosoitteella!",
    "is": "Reikia, kad sé til staðar með þessu tölvupósti!",
    "es": "¡Ya debe existir una cuenta con la dirección de correo electrónico!"
  },
  "in_which_class_a_user_should_be": {
    "de": "Gib die Klasse an, in welcher der User sein soll.",
    "en": "Specify the class in which the user should be.",
    "sk": "Určte triedu, v ktorej by mal byť používateľ.",
    "nl": "Specificeer de klas waarin de gebruiker moet zijn.",
    "ro": "Specificați clasa în care utilizatorul ar trebui să fie.",
    "cs": "Specifikujte třídu, ve které by měl být uživatel.",
    "sl": "Navedite razred, v katerem naj bi bil uporabnik.",
    "fr": "Précisez la classe dans laquelle l'utilisateur doit être.",
    "fi": "Määritä luokka, jossa käyttäjän tulisi olla.",
    "is": "Tilgreinið stund í hversu notandi ætti að vera.",
    "es": "Especifique la clase en la que debe estar el usuario."
  },
  "create_new_category": {
    "de": "Neue Kategorie erstellen",
    "en": "Create new category",
    "sk": "Vytvoriť novú kategóriu",
    "nl": "Maak nieuwe categorie aan",
    "ro": "Creează o categorie nouă",
    "cs": "Vytvořit novou kategorii",
    "sl": "Ustvarite novo kategorijo",
    "fr": "Créer une nouvelle catégorie",
    "fi": "Luo uusi kategoria",
    "is": "Búa til nýja flokk",
    "es": "Crear nueva categoría"
  },
  "create_new_category_hint": {
    "de": "Erstellen Sie eine neue Kategorie (z.B. 'Meteorologie' oder 'Training DE-CMX') und verlinken Sie Ihre eigenen Flugschule-Fragen mit der jeweiligen Kategorie.",
    "en": "Create a new category (e.g. 'Meteorology' or 'Training DE-CMX') and link your custom flight school questions to the respective category.",
    "sk": "Vytvorte novú kategóriu (napr. 'Meteorológia' alebo 'Výcvik DE-CMX') a prepojte svoje vlastné otázky zo školy letectva s príslušnou kategóriou.",
    "nl": "Maak een nieuwe categorie aan (bijv. 'Meteorologie' of 'Training DE-CMX') en koppel uw eigen vragen van de vliegschool aan de betreffende categorie.",
    "ro": "Creează o categorie nouă (ex: 'Meteorologie' sau 'Training DE-CMX') și leagă-ți propriile întrebări din școala de zbor de categoria respectivă.",
    "cs": "Vytvořte novou kategorii (např. 'Meteorologie' nebo 'Výcvik DE-CMX') a připojte své vlastní otázky z letecké školy ke příslušné kategorii.",
    "sl": "Ustvarite novo kategorijo (npr. 'Meteorologija' ali 'Izobraževanje DE-CMX') in povežite svoja lastna vprašanja iz letalske šole z ustrezno kategorijo.",
    "fr": "Créez une nouvelle catégorie (par exemple 'Météorologie' ou 'Formation DE-CMX') et liez vos questions personnalisées de l'école de pilotage à la catégorie respective.",
    "fi": "Luo uusi kategoria (esim. 'Meteorologia' tai 'Training DE-CMX') ja linkitä omat lento-opiston kysymyksesi vastaavaan kategoriaan.",
    "is": "Búðu til nýjan flokk (td. 'Veðurfræði' eða 'Þjálfun DE-CMX') og tengdu flugskóla spurningarnar þínar við viðkomandi flokk.",
    "es": "Cree una nueva categoría (por ejemplo, 'Meteorología' o 'Entrenamiento DE-CMX') y vincule sus preguntas personalizadas de la escuela de vuelo a la categoría correspondiente."
  },
  "first_create_a_new_category": {
    "de": "Zuerst eine neue Kategorie hinzufügen.",
    "en": "First add a new category.",
    "sk": "Najprv pridajte novú kategóriu.",
    "nl": "Voeg eerst een nieuwe categorie toe.",
    "ro": "Mai întâi adăugați o nouă categorie.",
    "cs": "Nejprve přidejte novou kategorii.",
    "sl": "Najprej dodajte novo kategorijo.",
    "fr": "Ajoutez d'abord une nouvelle catégorie.",
    "fi": "Lisää ensin uusi kategoria.",
    "is": "Bættu fyrst við nýrri flokk.",
    "es": "Primero agregue una nueva categoría."
  },
  "create_new_question": {
    "de": "Neue Frage erstellen",
    "en": "Create new question",
    "sk": "Vytvoriť novú otázku",
    "nl": "Nieuwe vraag aanmaken",
    "ro": "Creați o nouă întrebare",
    "cs": "Vytvořit novou otázku",
    "sl": "Ustvarite novo vprašanje",
    "fr": "Créer une nouvelle question",
    "fi": "Luo uusi kysymys",
    "is": "Búa til nýja spurningu",
    "es": "Crear nueva pregunta"
  },
  "create_new_question_hint": {
    "de": "Erstellen Sie eine neue Frage, welche für Ihre Flugschüler sofort zur Verfügung gestellt wird.",
    "en": "Create a new question, which will be made available to your student pilots immediately.",
    "sk": "Vytvorte novú otázku, ktorá bude okamžite k dispozícii vašim študentom-pilotom.",
    "nl": "Maak een nieuwe vraag aan die onmiddellijk beschikbaar wordt gesteld aan uw student-piloten.",
    "ro": "Creați o nouă întrebare, care va fi disponibilă imediat pentru elevii piloți.",
    "cs": "Vytvořte novou otázku, která bude okamžitě k dispozici vašim studentům pilotům.",
    "sl": "Ustvarite novo vprašanje, ki bo takoj na voljo vašim študentom pilotom.",
    "fr": "Créez une nouvelle question qui sera immédiatement mise à disposition de vos étudiants pilotes.",
    "fi": "Luo uusi kysymys, joka on välittömästi saatavilla oppilaspiloteillesi.",
    "is": "Búðu til nýja spurningu sem verður í boði strax fyrir flugnemana þína.",
    "es": "Cree una nueva pregunta, que estará disponible para sus estudiantes pilotos de inmediato."
  },
  "correct_answer": {
    "de": "Richtige Antwort",
    "en": "Correct answer",
    "sk": "Správna odpoveď",
    "nl": "Juiste antwoord",
    "ro": "Răspuns corect",
    "cs": "Správná odpověď",
    "sl": "Pravilen odgovor",
    "fr": "Bonne réponse",
    "fi": "Oikea vastaus",
    "is": "Rétt svar",
    "es": "Respuesta correcta"
  },
  "other_answer": {
    "de": "Andere Antwort",
    "en": "Other answer",
    "sk": "Iná odpoveď",
    "nl": "Andere antwoord",
    "ro": "Alt răspuns",
    "cs": "Jiná odpověď",
    "sl": "Drugi odgovor",
    "fr": "Autre réponse",
    "fi": "Muu vastaus",
    "is": "Annað svar",
    "es": "Otra respuesta"
  },
  "show_question_only_for_exams_from_ato": {
    "de": "Frage nur bei offiziellen Prüfungen von der ATO anzeigen",
    "en": "Show question only for official exams from the ATO",
    "sk": "Zobraziť otázku iba pre oficiálne skúšky od ATO",
    "nl": "Toon vraag alleen voor officiële examens van de ATO",
    "ro": "Afișați întrebarea doar pentru examenele oficiale de la ATO",
    "cs": "Zobrazovat otázku pouze pro oficiální zkoušky od ATO",
    "sl": "Prikaži vprašanje samo za uradne izpite iz ATO",
    "fr": "Afficher la question uniquement pour les examens officiels de l'ATO",
    "fi": "Näytä kysymys vain ATO:n virallisissa kokeissa",
    "is": "Sýna spurningu aðeins fyrir opinberar próf frá ATO",
    "es": "Mostrar pregunta solo para exámenes oficiales de ATO"
  },
  "only_show_on_exams_of_ato_questions": {
    "de": "Nur bei Prüfungen von der Flugschule anzeigen",
    "en": "Show only for exams from the flight school",
    "sk": "Zobraziť len pre skúšky z lietadlovej školy",
    "nl": "Alleen weergeven voor examens van de vliegschool",
    "ro": "Afișați doar la examenele de la școala de zbor",
    "cs": "Zobrazit pouze pro zkoušky z letecké školy",
    "sl": "Prikaži samo za izpite iz letalske šole",
    "fr": "Afficher uniquement pour les examens de l'école de pilotage",
    "fi": "Näytä vain lentokoulun kokeita varten",
    "is": "Birta aðeins fyrir próf frá flugsalanum",
    "es": "Mostrar solo para exámenes de la escuela de vuelo"
  },
  "image": {
    "de": "Bild",
    "en": "Image",
    "sk": "Obrázok",
    "nl": "Afbeelding",
    "ro": "Imagine",
    "cs": "Obrázek",
    "sl": "Slika",
    "fr": "Image",
    "fi": "Kuva",
    "is": "Mynd",
    "es": "Imagen"
  },
  "create_new_homework": {
    "de": "Neue Hausübung erstellen",
    "en": "Create new homework",
    "sk": "Vytvoriť nové domáce úlohy",
    "nl": "Nieuwe huiswerkopdracht maken",
    "ro": "Creați o nouă temă pentru acasă",
    "cs": "Vytvořit novou domácí úlohu",
    "sl": "Ustvarite novo domačo nalogo",
    "fr": "Créer une nouvelle mission pour la maison",
    "fi": "Luo uusi kotitehtävä",
    "is": "Búa til nýja heimaverkefnið",
    "es": "Crear nueva tarea"
  },
  "choose_class": {
    "de": "Klasse auswählen",
    "en": "Choose class",
    "sk": "Vyberte triedu",
    "nl": "Kies klas",
    "ro": "Alegeți clasa",
    "cs": "Vyberte třídu",
    "sl": "Izberite razred",
    "fr": "Choisissez la classe",
    "fi": "Valitse luokka",
    "is": "Veldu bekk",
    "es": "Elegir clase"
  },
  "choose_class_hint": {
    "de": "Für welche Klasse soll eine neue Hausübung erstellt werden?",
    "en": "For which class should a new homework assignment be created?",
    "sk": "Pre ktorú triedu sa má vytvoriť nová domáca úloha?",
    "nl": "Voor welke klas moet een nieuwe huiswerkopdracht worden gemaakt?",
    "ro": "Pentru ce clasă ar trebui creată o nouă temă pentru acasă?",
    "cs": "Pro kterou třídu má být vytvořena nová domácí úloha?",
    "sl": "Za kateri razred naj se ustvari novo domačo nalogo?",
    "fr": "Pour quelle classe doit être créée une nouvelle tâche à domicile?",
    "fi": "Mikä luokka tarvitsee uuden kotitehtävän?",
    "is": "Fyrir hvaða bekk ætti ný heimavinna að vera búin til?",
    "es": "¿Para qué clase se debe crear una nueva tarea?"
  },
  "choose_categories": {
    "de": "Kategorien auswählen",
    "en": "Choose categories",
    "sk": "Vyberte kategórie",
    "nl": "Kies categorieën",
    "ro": "Alegeți categoriile",
    "cs": "Vyberte kategorie",
    "sl": "Izberite kategorije",
    "fr": "Choisissez les catégories",
    "fi": "Valitse kategoriat",
    "is": "Veldu flokka",
    "es": "Elegir categorías"
  },
  "which_categories_should_be_done_as_homework": {
    "de": "Welche Kategorie(n) sollen als Hausübung erledigt werden?",
    "en": "What categories should be completed as homework?",
    "sk": "Ktoré kategórie by mali byť dokončené ako domáca úloha?",
    "nl": "Welke categorieën moeten als huiswerk worden voltooid?",
    "ro": "Ce categorii ar trebui completate ca teme pentru acasă?",
    "cs": "Které kategorie by měly být dokončeny jako domácí úkoly?",
    "sl": "Katere kategorije naj bodo opravljene kot domače naloge?",
    "fr": "Quelles catégories doivent être complétées en tant que devoirs?",
    "fi": "Mitkä kategoriat tulee suorittaa kotitehtävinä?",
    "is": "Hverir flokkar ættu að vera kláraðir sem heimavinna?",
    "es": "¿Qué categorías deben completarse como tarea?"
  },
  "choose_enddate": {
    "de": "Enddatum auswählen",
    "en": "Select end date",
    "sk": "Vybrať dátum ukončenia",
    "nl": "Selecteer einddatum",
    "ro": "Selectați data de încheiere",
    "cs": "Vybrat datum ukončení",
    "sl": "Izberite končni datum",
    "fr": "Sélectionnez la date de fin",
    "fi": "Valitse loppupäivämäärä",
    "is": "Veldu lokadaginn",
    "es": "Seleccionar fecha de finalización"
  },
  "by_when_must_the_homework_be_done": {
    "de": "Bis wann muss die Hausübung erledigt sein?",
    "en": "By when must the homework be done?",
    "sk": "Do kedy musí byť domáca úloha hotová?",
    "nl": "Voor wanneer moet het huiswerk klaar zijn?",
    "ro": "Până când trebuie să fie terminat temele?",
    "cs": "Do kdy musí být úkol hotov?",
    "sl": "Do kdaj mora biti domača naloga končana?",
    "fr": "Jusqu'à quand devez-vous terminer les devoirs ?",
    "fi": "Milloin kotitehtävät on tehtävä?",
    "is": "Fyrir hvaða dag verður heimavinna kláruð?",
    "es": "¿Para cuándo debe completarse la tarea?"
  },
  "create_a_new_exam": {
    "de": "Neue Prüfung erstellen",
    "en": "Create new exam",
    "sk": "Vytvoriť nový skúšobný test",
    "nl": "Maak nieuwe toets",
    "ro": "Creați un nou test",
    "cs": "Vytvořit nový test",
    "sl": "Ustvarite nov test",
    "fr": "Créer un nouvel examen",
    "fi": "Luo uusi tentti",
    "is": "Búa til nýja próf",
    "es": "Crear nuevo examen"
  },
  "online": {
    "de": "Online",
    "en": "Online",
    "sk": "Online",
    "nl": "Online",
    "ro": "Online",
    "cs": "Online",
    "sl": "Spletno",
    "fr": "En ligne",
    "fi": "Online",
    "is": "Netið",
    "es": "En línea"
  },
  "start_exam_online_or_set_it_available_for_students": {
    "de": "Eine Prüfung für eine Klasse oder einzelne Flugschüler starten.",
    "en": "Start an exam for a class or individual flight students.",
    "sk": "Spustiť skúšku pre triedu alebo jednotlivých leteckých študentov.",
    "nl": "Start een examen voor een klas of individuele vliegstudenten.",
    "ro": "Porniți un examen pentru o clasă sau pentru elevii individuali de zbor.",
    "cs": "Spustit zkoušku pro třídu nebo jednotlivé letecké studenty.",
    "sl": "Začnite izpit za razred ali posamezne študente letenja.",
    "fr": "Commencer un examen pour une classe ou des élèves de pilotage individuels.",
    "fi": "Käynnistä tentti luokalle tai yksittäisille lentäjäopiskelijoille.",
    "is": "Hefja próf fyrir bekk eða einstaka flugnemendur.",
    "es": "Inicie un examen para una clase o estudiantes de vuelo individuales."
  },
  "all_exams_contains_secret_flightschool_questions": {
    "de": "Alle Prüfungen beinhalten zusätzlich auch die 'geheimen Fragen' der Flugschule.",
    "en": "All exams additionally include the 'secret questions' of the flight school.",
    "sk": "Všetky skúšky dodatočne obsahujú aj 'tajné otázky' leteckej školy.",
    "nl": "Alle examens bevatten bovendien de 'geheime vragen' van de vliegschool.",
    "ro": "Toate examenele includ în plus 'întrebările secrete' ale școlii de zbor.",
    "cs": "Všechny zkoušky navíc obsahují 'tajné otázky' letecké školy.",
    "sl": "Vse izpite vključujejo tudi 'skrivna vprašanja' letalske šole.",
    "fr": "Tous les examens comprennent également les 'questions secrètes' de l'école de pilotage.",
    "fi": "Kaikki tentit sisältävät lisäksi lentokoulun 'salaiset kysymykset'.",
    "is": "All prófin innihalda einnig 'leynispurningar' flugskólans.",
    "es": "Todos los exámenes incluyen además las 'preguntas secretas' de la escuela de vuelo."
  },
  "start_exam": {
    "de": "Prüfung hier starten",
    "en": "Start exam here",
    "sk": "Spustiť skúšku teraz",
    "nl": "Start examen nu",
    "ro": "Începe examenul acum",
    "cs": "Spustit zkoušku nyní",
    "sl": "Začni izpit zdaj",
    "fr": "Commencer l'examen maintenant",
    "fi": "Aloita tentti nyt",
    "is": "Hefja próf núna",
    "es": "Iniciar examen aquí"
  },
  "create_class_exam": {
    "de": "Klassenprüfung erstellen",
    "en": "Create class exam",
    "sk": "Vytvoriť triednu skúšku",
    "nl": "Maak een klas examen",
    "ro": "Creați un examen de clasă",
    "cs": "Vytvořit třídní zkoušku",
    "sl": "Ustvari izpit za razred",
    "fr": "Créer un examen de classe",
    "fi": "Luo luokan tentti",
    "is": "Búa til próf fyrir bekk",
    "es": "Crear examen de clase"
  },
  "print_a_new_exam": {
    "de": "Eine Prüfung fertig ausdrucken.",
    "en": "Print a new exam.",
    "sk": "Vytlačiť novú skúšku.",
    "nl": "Print een nieuw examen.",
    "ro": "Tipăriți un nou examen.",
    "cs": "Vytisknout novou zkoušku.",
    "sl": "Natisnite novo preizkus.",
    "fr": "Imprimer un nouvel examen.",
    "fi": "Tulosta uusi tentti.",
    "is": "Prenta nýja pró.f",
    "es": "Imprimir un nuevo examen."
  },
  "print": {
    "de": "Ausdrucken",
    "en": "Print",
    "sk": "Tlačiť",
    "nl": "Afdrukken",
    "ro": "Imprimare",
    "cs": "Tisknout",
    "sl": "Natisni",
    "fr": "Imprimer",
    "fi": "Tulosta",
    "is": "Prenta",
    "es": "Imprimir"
  },
  "print_exam": {
    "de": "Prüfung ausdrucken",
    "en": "Print exam",
    "sk": "Vytlačiť skúšku",
    "nl": "Print examen",
    "ro": "Imprimă examenul",
    "cs": "Tisknout zkoušku",
    "sl": "Natisni izpit",
    "fr": "Imprimer l'examen",
    "fi": "Tulosta tentti",
    "is": "Prenta próf",
    "es": "Imprimir examen"
  },
  "use_a_template": {
    "de": "Eine Prüfungsvorlage verwenden",
    "en": "Use a template",
    "sk": "Použiť šablónu",
    "nl": "Gebruik een sjabloon",
    "ro": "Utilizați un șablon",
    "cs": "Použít šablonu",
    "sl": "Uporabi predlogo",
    "fr": "Utiliser un modèle",
    "fi": "Käytä mallia",
    "is": "Notaðu forskrift",
    "es": "Usar una plantilla"
  },
  "template": {
    "de": "Vorlagen",
    "en": "Template",
    "sk": "Šablóna",
    "nl": "Sjabloon",
    "ro": "Șablon",
    "cs": "Šablona",
    "sl": "Predloga",
    "fr": "Modèle",
    "fi": "Malli",
    "is": "Forskrift",
    "es": "Plantilla"
  },
  "create_template": {
    "de": "Vorlage erstellen",
    "en": "Create template",
    "sk": "Vytvoriť šablónu",
    "nl": "Sjabloon maken",
    "ro": "Creează șablon",
    "cs": "Vytvořit šablonu",
    "sl": "Ustvari predlogo",
    "fr": "Créer un modèle",
    "fi": "Luo malli",
    "is": "Búa til forskrift",
    "es": "Crear plantilla"
  },
  "search_results": {
    "de": "Ergebnisse",
    "en": "Results",
    "sk": "Výsledky",
    "nl": "Resultaten",
    "ro": "Rezultate",
    "cs": "Výsledky",
    "sl": "Rezultati",
    "fr": "Résultats",
    "fi": "Tulokset",
    "is": "Niðurstöður",
    "es": "Resultados"
  },
  "create_exam_for_certain_students": {
    "de": "Prüfung für gewisse Schüler",
    "en": "Exam for certain students",
    "sk": "Skúška pre určitých študentov",
    "nl": "Examen voor bepaalde studenten",
    "ro": "Examen pentru anumiți elevi",
    "cs": "Zkouška pro určité studenty",
    "sl": "Izpit za določene študente",
    "fr": "Examen pour certains étudiants",
    "fi": "Tentti tietyille opiskelijoille",
    "is": "Próf fyrir ákveðna nemendur",
    "es": "Examen para estudiantes específicos"
  },
  "choose_student": {
    "de": "Flugschüler auswählen",
    "en": "Select students",
    "sk": "Vybrať študentov",
    "nl": "Selecteer studenten",
    "ro": "Selectați elevi",
    "cs": "Vyberte studenty",
    "sl": "Izberi študente",
    "fr": "Sélectionner des étudiants",
    "fi": "Valitse opiskelijoita",
    "is": "Velja nemendur",
    "es": "Seleccionar estudiantes"
  },
  "choose_student_hint": {
    "de": "Für welche Flugschüler soll die Hausübung erstellt werden?",
    "en": "For which students the homework should be set up?",
    "sk": "Pre ktorých študentov sa má domáca úloha nastaviť?",
    "nl": "Voor welke studenten moet het huiswerk worden opgezet?",
    "ro": "Pentru care elevi ar trebui să se pregătească temele?",
    "cs": "Pro které studenty má být domácí úkol nastaven?",
    "sl": "Za katere študente naj bo dodelana domača naloga?",
    "fr": "Pour quels étudiants les devoirs doivent être établis ?",
    "fi": "Mille opiskelijoille läksyt pitäisi antaa?",
    "is": "Fyrir hvaða nemendur á að setja heimavinnu upp?",
    "es": "¿Para qué estudiantes se debe configurar la tarea?"
  },
  "create_class_homework": {
    "de": "Für die ganze Klasse",
    "en": "For the whole class",
    "sk": "Pre celú triedu",
    "nl": "Voor de hele klas",
    "ro": "Pentru întreaga clasă",
    "cs": "Pro celou třídu",
    "sl": "Za celotni razred",
    "fr": "Pour toute la classe",
    "fi": "Koko luokalle",
    "is": "Fyrir alla bekkinn",
    "es": "Para toda la clase"
  },
  "create_homework_for_certain_students": {
    "de": "Für einzelne Schüler",
    "en": "For individual students",
    "sk": "Pre jednotlivých študentov",
    "nl": "Voor individuele studenten",
    "ro": "Pentru elevi individuali",
    "cs": "Pro jednotlivé studenty",
    "sl": "Za posamezne študente",
    "fr": "Pour les étudiants individuels",
    "fi": "Yksittäisille opiskelijoille",
    "is": "Fyrir einstaka nemendur",
    "es": "Para estudiantes individuales"
  },
  "which_kind_of_homework_should_be_done": {
    "de": "Für welche Klasse soll eine neue Hausübung angelegt werden?",
    "en": "For which class should a new homework be created?",
    "sk": "Pre akú triedu by sa mala vytvoriť nová domáca úloha?",
    "nl": "Voor welke klas moet er een nieuwe huiswerkopdracht worden gemaakt?",
    "ro": "Pentru ce clasă ar trebui creată o nouă temă?",
    "cs": "Pro kterou třídu by se měla vytvořit nová domácí úloha?",
    "sl": "Za katero razred bi morala biti ustvarjena nova domača naloga?",
    "fr": "Pour quelle classe une nouvelle tâche doit-elle être créée ?",
    "fi": "Millelle luokalle uusi kotitehtävä tulisi luoda?",
    "is": "Fyrir hvaða bekk skal ný heimavinna vera búin til?",
    "es": "¿Para qué clase se debería crear una nueva tarea?"
  },
  "search_results_minimum": {
    "de": "Bitte mindestens 3 Zeichen eingeben.",
    "en": "Please enter at least 3 characters.",
    "sk": "Prosím, zadajte aspoň 3 znaky.",
    "nl": "Voer minimaal 3 tekens in.",
    "ro": "Introduceți cel puțin 3 caractere.",
    "cs": "Zadejte prosím alespoň 3 znaky.",
    "sl": "Vnesite vsaj 3 znake.",
    "fr": "Veuillez saisir au moins 3 caractères.",
    "fi": "Kirjoita vähintään 3 merkkiä.",
    "is": "Vinsamlegast sláðu inn að minnsta kosti 3 stafi.",
    "es": "Por favor ingrese al menos 3 caracteres."
  },
  "search_questions": {
    "de": "Alle Fragen durchsuchen",
    "en": "Search all questions",
    "sk": "Prehľadať všetky otázky",
    "nl": "Zoek alle vragen",
    "ro": "Caută toate întrebările",
    "cs": "Hledat všechny otázky",
    "sl": "Išči vsa vprašanja",
    "fr": "Rechercher toutes les questions",
    "fi": "Etsi kaikki kysymykset",
    "is": "Leita í öllum spurningum",
    "es": "Buscar todas las preguntas"
  },
  "search_for_question_and_ids": {
    "de": "Durchsuchbar sind Fragen und Fragen-IDs",
    "en": "Questions and question IDs are searchable",
    "sk": "Otázky a identifikátory otázok sú vyhľadateľné",
    "nl": "Vragen en vraag-ID's zijn doorzoekbaar",
    "ro": "Întrebările și ID-urile întrebărilor sunt căutabile",
    "cs": "Otázky a identifikátory otázek jsou vyhledatelné",
    "sl": "Vprašanja in ID-ji vprašanj so iskalni",
    "fr": "Les questions et les identifiants de questions sont recherchables",
    "fi": "Kysymykset ja kysymys tunnukset ovat haettavissa",
    "is": "Spurningar og auðkenni spurninga eru leitandi",
    "es": "Las preguntas y los identificadores de preguntas son buscables"
  },
  "account_is_not_linked_to_any_flightschool": {
    "de": "Der Account ist mit keiner Flugschule verknüpft.",
    "en": "The account is not linked to any flight school.",
    "sk": "Účet nie je prepojený s žiadnou leteckou školou.",
    "nl": "Het account is niet gekoppeld aan een vliegschool.",
    "ro": "Contul nu este legat de nicio școală de zbor.",
    "cs": "Účet není propojen s žádnou leteckou školou.",
    "sl": "Račun ni povezan z nobeno letalsko šolo.",
    "fr": "Le compte n'est pas lié à une école de pilotage.",
    "fi": "Tiliä ei ole liitetty mihinkään lentokouluun.",
    "is": "Aðgangurinn er ekki tengdur neinni flugskóla.",
    "es": "La cuenta no está vinculada a ninguna escuela de vuelo."
  },
  "super_user": {
    "de": "Admin",
    "en": "Admin",
    "sk": "Super užívateľ",
    "nl": "Supergebruiker",
    "ro": "Super utilizator",
    "cs": "Super uživatel",
    "sl": "Super uporabnik",
    "fr": "Super utilisateur",
    "fi": "Superkäyttäjä",
    "is": "Yfirnotandi",
    "es": "Admin"
  },
  "this_is_a_super_user": {
    "de": "Gibt an, ob der Account sämtliche Fragen dieser Flugschule sehen darf. Nicht aktivieren, wenn es sich um einen Flugschüler Account handelt.",
    "en": "Specifies whether the account is allowed to see all questions of this flight school. Do not activate if the account is a student pilot account!",
    "sk": "Určuje, či má účet oprávnenie vidieť všetky otázky tejto leteckej školy. Neaktivujte, ak je účet účtom študenta pilota!",
    "nl": "Specificeert of het account alle vragen van deze vliegschool mag zien. Niet activeren als het account een student-piloot account is!",
    "ro": "Specifică dacă contul are permisiunea de a vedea toate întrebările acestei școli de zbor. Nu activați dacă contul este un cont de elev pilot!",
    "cs": "Určuje, zda má účet oprávnění vidět všechny otázky této letecké školy. Neaktivujte, pokud je účet účtem studenta pilota!",
    "sl": "Določa, ali ima račun dovoljenje za ogled vseh vprašanj te letalske šole. Ne aktivirajte, če je račun račun učenca pilota!",
    "fr": "Indique si le compte est autorisé à voir toutes les questions de cette école de pilotage. Ne pas activer si le compte est un compte d'élève pilote !",
    "fi": "Määrittää, onko tilillä oikeus nähdä tämän lentokoulun kaikki kysymykset. Älä aktivoi, jos tili on opiskelijalentäjän tili!",
    "is": "Tilgreinir hvort reikningurinn hafi heimild til að sjá allar spurningarnar þessa flugskóla. Ekki virkja ef reikningurinn er námsflugmannsreikningur!",
    "es": "Especifica si la cuenta tiene permiso para ver todas las preguntas de esta escuela de vuelo. ¡No active si la cuenta es de un estudiante piloto!"
  },
  "admin_description_adding_student": {
    "de": "Aktiviere den Benutzer als Administrator für den ATO Zugang, nicht für normale Flugschülerkonten aktivieren.",
    "en": "Activate the user as an administrator for other ATO accounts, do not activate for normal student pilot accounts.",
    "sk": "Aktivujte používateľa ako administrátora pre ďalšie ATO účty, neaktivujte pre bežné účty študentov pilotov.",
    "nl": "Activeer de gebruiker als beheerder voor andere ATO-accounts, activeer niet voor normale student-piloot accounts.",
    "ro": "Activați utilizatorul ca administrator pentru alte conturi ATO, nu activați pentru conturi normale de elevi piloți.",
    "cs": "Aktivujte uživatele jako administrátora pro další ATO účty, neaktivujte pro běžné účty studentů pilotů.",
    "sl": "Aktivirajte uporabnika kot administratorja za druge ATO račune, ne aktivirajte za običajne račune študentov pilotov.",
    "fr": "Activez l'utilisateur en tant qu'administrateur pour d'autres comptes ATO, ne l'activez pas pour les comptes normaux d'élèves-pilotes.",
    "fi": "Aktivoi käyttäjä ylläpitäjänä muille ATO-tileille, älä aktivoi tavallisille oppilaslentäjien tileille.",
    "is": "Virkið notandann sem stjórnandi fyrir aðra ATO reikninga, ekki virkja fyrir venjulega nemendapilot reikninga.",
    "es": "Activa al usuario como administrador para otras cuentas ATO, no lo actives para cuentas normales de estudiantes piloto."
  },
  "manage_all_atos": {
    "de": "Flugschulen verwalten",
    "en": "Manage all flightschools",
    "sk": "Spravovať všetky letecké školy",
    "nl": "Beheer alle vliegscholen",
    "ro": "Gestionați toate școlile de zbor",
    "cs": "Spravovat všechny letecké školy",
    "sl": "Upravljajte vse letalske šole",
    "fr": "Gérer toutes les écoles de pilotage",
    "fi": "Hallinnoi kaikkia lentokouluja",
    "is": "Stjórna öllum flugskólum",
    "es": "Administrar todas las escuelas de vuelo"
  },
  "manage_answers": {
    "de": "Antwort-Erklärungen verwalten",
    "en": "Manage answer explanations",
    "sk": "Spravovať vysvetlenia odpovedí",
    "nl": "Beheer antwoordverklaringen",
    "ro": "Gestionați explicațiile răspunsurilor",
    "cs": "Spravovat vysvětlení odpovědí",
    "sl": "Upravljajte razlage odgovorov",
    "fr": "Gérer les explications des réponses",
    "fi": "Hallinnoi vastauksen selityksiä",
    "is": "Stjórna svaraskýringum",
    "es": "Gestionar explicaciones de respuestas"
  },
  "manage_answers_description": {
    "de": "Hier kannst du die Antwort-Erklärungen verwalten",
    "en": "Here you can manage the answer explanations",
    "sk": "Tu môžete spravovať vysvetlenia odpovedí",
    "nl": "Hier kunt u de antwoordverklaringen beheren",
    "ro": "Aici puteți gestiona explicațiile răspunsurilor",
    "cs": "Zde můžete spravovat vysvětlení odpovědí",
    "sl": "Tukaj lahko upravljate razlage odgovorov",
    "fr": "Ici, vous pouvez gérer les explications des réponses",
    "fi": "Täällä voit hallinnoida vastauksen selityksiä",
    "is": "Hér getur þú stjórnað svaraskýringum",
    "es": "Aquí puedes gestionar las explicaciones de respuestas"
  },
  "here_you_get_all_ato_details": {
    "de": "Hier kannst du alle verfügbaren Flugschulen verwalten",
    "en": "Here you can manage all available flightschools",
    "sk": "Tu môžete spravovať všetky dostupné letecké školy",
    "nl": "Hier kun je alle beschikbare vliegscholen beheren",
    "ro": "Aici puteți gestiona toate școlile de zbor disponibile",
    "cs": "Zde můžete spravovat všechny dostupné letecké školy",
    "sl": "Tukaj lahko upravljate vse razpoložljive letalske šole",
    "fr": "Ici, vous pouvez gérer toutes les écoles de pilotage disponibles",
    "fi": "Täällä voit hallita kaikkia käytettävissä olevia lentokouluja",
    "is": "Hér getur þú stjórnað öllum tiltækjum flugskólum",
    "es": "Aquí puede administrar todas las escuelas de vuelo disponibles"
  },
  "create_new_ato": {
    "de": "Neue Flugschule anlegen",
    "en": "Add new flightschool",
    "sk": "Pridať novú leteckú školu",
    "nl": "Nieuwe vliegschool toevoegen",
    "ro": "Adăugați o nouă școală de zbor",
    "cs": "Přidat novou leteckou školu",
    "sl": "Dodaj novo letalsko šolo",
    "fr": "Ajouter une nouvelle école de pilotage",
    "fi": "Lisää uusi lentokoulu",
    "is": "Bæta við nýrri flugskóla",
    "es": "Agregar nueva escuela de vuelo"
  },
  "all_atos": {
    "de": "Alle Flugschulen",
    "en": "All flightschools",
    "sk": "Všetky letecké školy",
    "nl": "Alle vliegscholen",
    "ro": "Toate școlile de zbor",
    "cs": "Všechny letecké školy",
    "sl": "Vse letalske šole",
    "fr": "Toutes les écoles de pilotage",
    "fi": "Kaikki lentokoulut",
    "is": "Allar flugskólar",
    "es": "Todas las escuelas de vuelo"
  },
  "contact": {
    "de": "Kontakt",
    "en": "Contact",
    "sk": "Kontakt",
    "nl": "Contact",
    "ro": "Contact",
    "cs": "Kontakt",
    "sl": "Kontakt",
    "fr": "Contact",
    "fi": "Yhteystiedot",
    "is": "Hafa samband",
    "es": "Contacto"
  },
  "select_ato": {
    "de": "Flugschule auswählen",
    "en": "Select flightschool",
    "sk": "Vyberte leteckú školu",
    "nl": "Selecteer vliegschool",
    "ro": "Selectați școala de zbor",
    "cs": "Vyberte leteckou školu",
    "sl": "Izberite letalsko šolo",
    "fr": "Sélectionner une école de pilotage",
    "fi": "Valitse lentokoulu",
    "is": "Velja flugskóla",
    "es": "Seleccionar escuela de vuelo"
  },
  "feature_in_development": {
    "de": "Dieses Feature ist derzeit noch in Arbeit aber in Kürze verfügbar!",
    "en": "This feature is currently in development but available soon!",
    "sk": "Táto funkcia je momentálne vo vývoji, ale čoskoro bude k dispozícii!",
    "nl": "Deze functie is momenteel in ontwikkeling maar binnenkort beschikbaar!",
    "ro": "Această funcționalitate este în prezent în dezvoltare, dar va fi disponibilă în curând!",
    "cs": "Tato funkce je momentálně ve vývoji, ale brzy bude k dispozici!",
    "sl": "Ta funkcionalnost je trenutno v razvoju, vendar kmalu na voljo!",
    "fr": "Cette fonctionnalité est actuellement en développement mais bientôt disponible !",
    "fi": "Tämä ominaisuus on parhaillaan kehitteillä, mutta pian saatavilla!",
    "is": "Þessi möguleiki er núverandi í þróun en verður fljótt aðgengilegur!",
    "es": "¡Esta función está actualmente en desarrollo pero estará disponible pronto!"
  },
  "homework_found": {
    "de": "Hausübung gefunden!",
    "en": "Homework found!",
    "sk": "Nájdené domáce úlohy!",
    "nl": "Huiswerk gevonden!",
    "ro": "Tema găsită!",
    "cs": "Domácí úkol nalezen!",
    "sl": "Najdena domača naloga!",
    "fr": "Devoirs trouvés !",
    "fi": "Löydetty läksy!",
    "is": "Leiðangur fundinn!",
    "es": "¡Tarea encontrada!"
  },
  "homework_found_description": {
    "de": "Deine Flugschule hat eine Hausübung erstellt, die du noch abschließen musst.",
    "en": "Your flight school has created a homework assignment for you to complete.",
    "sk": "Vaša letecká škola vám vytvorila domácu úlohu, ktorú musíte dokončiť.",
    "nl": "Je vliegschool heeft huiswerk voor je aangemaakt dat je moet voltooien.",
    "ro": "Școala ta de zbor a creat o temă pentru tine pe care trebuie să o completezi.",
    "cs": "Vaše letecká škola vytvořila domácí úkol, který musíte dokončit.",
    "sl": "Vaša letalska šola je ustvarila domačo nalogo, ki jo morate zaključiti.",
    "fr": "Votre école de pilotage a créé un devoir que vous devez compléter.",
    "fi": "Lentokoulusi on luonut läksyn, jonka sinun on suoritettava.",
    "is": "Flugsalan þitt hefur búið til heimavinna sem þú þarft að klára.",
    "es": "Su escuela de vuelo ha creado una tarea para que la complete."
  },
  "not_now": {
    "de": "Jetzt nicht",
    "en": "Not now",
    "sk": "Nie teraz",
    "nl": "Niet nu",
    "ro": "Nu acum",
    "cs": "Nyní ne",
    "sl": "Ne zdaj",
    "fr": "Pas maintenant",
    "fi": "Ei nyt",
    "is": "Ekkert núna",
    "es": "Ahora no"
  },
  "exam_found": {
    "de": "Prüfung gefunden!",
    "en": "Exam found!",
    "sk": "Nájdené domáce úlohy!",
    "nl": "Huiswerk gevonden!",
    "ro": "Tema găsită!",
    "cs": "Domácí úkol nalezen!",
    "sl": "Najdena domača naloga!",
    "fr": "Devoirs trouvés !",
    "fi": "Löydetty läksy!",
    "is": "Leiðangur fundinn!",
    "es": "¡Examen encontrado!"
  },
  "exam_found_description": {
    "de": "Deine Flugschule hat eine Prüfung erstellt, die du noch abschließen musst.",
    "en": "Your flight school has created an exam for you to complete.",
    "sk": "Vaša letecká škola vám vytvorila domácu úlohu, ktorú musíte dokončiť.",
    "nl": "Je vliegschool heeft huiswerk voor je aangemaakt dat je moet voltooien.",
    "ro": "Școala ta de zbor a creat o temă pentru tine pe care trebuie să o completezi.",
    "cs": "Vaše letecká škola vytvořila domácí úkol, který musíte dokončit.",
    "sl": "Vaša letalska šola je ustvarila domačo nalogo, ki jo morate zaključiti.",
    "fr": "Votre école de pilotage a créé un devoir que vous devez compléter.",
    "fi": "Lentokoulusi on luonut läksyn, jonka sinun on suoritettava.",
    "is": "Flugsalan þitt hefur búið til heimavinna sem þú þarft að klára.",
    "es": "Su escuela de vuelo ha creado un examen para que lo complete."
  },
  "show_homework": {
    "de": "Hausübung anzeigen",
    "en": "Show homework",
    "sk": "Zobraziť domáce úlohy",
    "nl": "Huiswerk weergeven",
    "ro": "Afișează temele",
    "cs": "Zobrazit úkoly",
    "sl": "Prikaži domačo nalogo",
    "fr": "Afficher les devoirs",
    "fi": "Näytä läksyt",
    "is": "Sýna heimavinna",
    "es": "Mostrar tarea"
  },
  "show_exam": {
    "de": "Prüfung anzeigen",
    "en": "Show exam",
    "sk": "Zobraziť domáce úlohy",
    "nl": "Huiswerk weergeven",
    "ro": "Afișează temele",
    "cs": "Zobrazit úkoly",
    "sl": "Prikaži domačo nalogo",
    "fr": "Afficher les devoirs",
    "fi": "Näytä läksyt",
    "is": "Sýna heimavinna",
    "es": "Mostrar examen"
  },
  "homework_simulation": {
    "de": "Meine Hausübungen",
    "en": "My Homework",
    "sk": "Moje domáce úlohy",
    "nl": "Mijn huiswerk",
    "ro": "Temele mele",
    "cs": "Moje úkoly",
    "sl": "Moja domača naloga",
    "fr": "Mes devoirs",
    "fi": "Minun läksyni",
    "is": "Mínar heimavinur",
    "es": "Mi tarea"
  },
  "finish_homework": {
    "de": "Hausübung speichern",
    "en": "Save homework",
    "sk": "Uložiť domácu úlohu",
    "nl": "Huiswerk opslaan",
    "ro": "Salvați temele",
    "cs": "Uložit úkol",
    "sl": "Shrani domačo nalogo",
    "fr": "Enregistrer les devoirs",
    "fi": "Tallenna läksyt",
    "is": "Vista heimavinna",
    "es": "Guardar tarea"
  },
  "what_is_your_flightschool_name": {
    "de": "Bei welcher Flugschule machst du deine Ausbildung?",
    "en": "At which flight school do you do your training?",
    "sk": "Na ktorej leteckej škole robíte svoje školenie?",
    "nl": "Bij welke vliegschool volg je je training?",
    "ro": "La ce școală de zbor faceți instruirea?",
    "cs": "Na které letecké škole provádíte své vzdělávání?",
    "sl": "Na kateri letalski šoli opravljate svoje usposabljanje?",
    "fr": "Dans quelle école de pilotage suivez-vous votre formation?",
    "fi": "Missä lentokoulussa suoritat koulutuksesi?",
    "is": "Hvar ertu í flugskólanum þínum?",
    "es": "¿En qué escuela de vuelo haces tu entrenamiento?"
  },
  "default_categories_will_not_be_shown": {
    "de": "Default Kategorien wie Luftrecht, Meteorologie, ... werden hier nicht angezeigt.",
    "en": "Default categories like air law, meteorology, ... will not be shown in the list.",
    "sk": "Predvolené kategórie ako letecké právo, meteorológia, ... sa v zozname nezobrazia.",
    "nl": "Standaardcategorieën zoals luchtrecht, meteorologie, ... worden niet weergegeven in de lijst.",
    "ro": "Categoriile implicite precum dreptul aerian, meteorologia, ... nu vor fi afișate în listă.",
    "cs": "Výchozí kategorie jako letecké právo, meteorologie, ... se v seznamu nezobrazí.",
    "sl": "Privzete kategorije, kot so letalstvo, meteorologija, ... v seznamu ne bodo prikazane.",
    "fr": "Les catégories par défaut comme le droit aérien, la météorologie, ... ne seront pas affichées dans la liste.",
    "fi": "Oletuskategoriat, kuten ilmailuoikeus, meteorologia, ... eivät näy luettelossa.",
    "is": "Sjálfgefnar flokkar eins og loftlögfræði, veðurfræði, ... verða ekki sýndir á lista.",
    "es": "Las categorías predeterminadas como ley aérea, meteorología, ... no se mostrarán en la lista."
  },
  "do_you_wanna_close_without_saving": {
    "de": "Möchten Sie das Fenster ohne Speichern schließen?",
    "en": "Do you want to close the window without saving?",
    "sk": "Chcete zavrieť okno bez uloženia?",
    "nl": "Wilt u het venster sluiten zonder op te slaan?",
    "ro": "Doriți să închideți fereastra fără a salva?",
    "cs": "Chcete zavřít okno bez uložení?",
    "sl": "Ali želite zapreti okno brez shranjevanja?",
    "fr": "Voulez-vous fermer la fenêtre sans enregistrer ?",
    "fi": "Haluatko sulkea ikkunan tallentamatta?",
    "is": "Viltu loka gluggann án þess að vista?",
    "es": "¿Desea cerrar la ventana sin guardar?"
  },
  "confirm_deleting_student": {
    "de": "Sind Sie sicher, dass Sie diesen Schüler löschen möchten?",
    "en": "Are you sure you want to delete this student?",
    "sk": "Ste si istý, že chcete tohto študenta vymazať?",
    "nl": "Weet u zeker dat u deze student wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți acest student?",
    "cs": "Opravdu chcete tohoto studenta smazat?",
    "sl": "Ste prepričani, da želite izbrisati tega študenta?",
    "fr": "Êtes-vous sûr de vouloir supprimer cet étudiant ?",
    "fi": "Haluatko varmasti poistaa tämän opiskelijan?",
    "is": "Ertu viss um að þú viljir eyða þessum nemanda?",
    "es": "¿Estás seguro de que deseas eliminar a este estudiante?"
  },
  "confirm_deleting_category": {
    "de": "Sind Sie sicher, dass Sie diese Kategorie löschen möchten?",
    "en": "Are you sure you want to delete this category?",
    "sk": "Ste si istý, že chcete túto kategóriu vymazať?",
    "nl": "Weet u zeker dat u deze categorie wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți această categorie?",
    "cs": "Opravdu chcete tuto kategorii smazat?",
    "sl": "Ste prepričani, da želite izbrisati to kategorijo?",
    "fr": "Êtes-vous sûr de vouloir supprimer cette catégorie ?",
    "fi": "Haluatko varmasti poistaa tämän kategorian?",
    "is": "Ertu viss um að þú viljir eyða þessari flokk?",
    "es": "¿Estás seguro de que deseas eliminar esta categoría?"
  },
  "confirm_deleting_group": {
    "de": "Sind Sie sicher, dass Sie diese Klasse löschen möchten?",
    "en": "Are you sure you want to delete this class?",
    "sk": "Ste si istý, že chcete túto skupinu vymazať?",
    "nl": "Weet u zeker dat u deze groep wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți acest grup?",
    "cs": "Opravdu chcete tuto skupinu smazat?",
    "sl": "Ste prepričani, da želite izbrisati to skupino?",
    "fr": "Êtes-vous sûr de vouloir supprimer ce groupe ?",
    "fi": "Haluatko varmasti poistaa tämän ryhmän?",
    "is": "Ertu viss um að þú viljir eyða þessari hópi?",
    "es": "¿Estás seguro de que deseas eliminar esta clase?"
  },
  "confirm_deleting_question": {
    "de": "Sind Sie sicher, dass Sie diese Frage löschen möchten?",
    "en": "Are you sure you want to delete this question?",
    "sk": "Ste si istý, že chcete túto otázku vymazať?",
    "nl": "Weet u zeker dat u deze vraag wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți această întrebare?",
    "cs": "Opravdu chcete tuto otázku smazat?",
    "sl": "Ste prepričani, da želite izbrisati to vprašanje?",
    "fr": "Êtes-vous sûr de vouloir supprimer cette question ?",
    "fi": "Haluatko varmasti poistaa tämän kysymyksen?",
    "is": "Ertu viss um að þú viljir eyða þessari spurningu?",
    "es": "¿Estás seguro de que deseas eliminar esta pregunta?"
  },
  "confirm_deleting_homework": {
    "de": "Sind Sie sicher, dass Sie diese Hausaufgabe löschen möchten?",
    "en": "Are you sure you want to delete this homework?",
    "sk": "Ste si istý, že chcete túto domácu úlohu vymazať?",
    "nl": "Weet u zeker dat u deze huiswerkopdracht wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți această temă pentru acasă?",
    "cs": "Opravdu chcete tuto domácí úlohu smazat?",
    "sl": "Ste prepričani, da želite izbrisati to domačo nalogo?",
    "fr": "Êtes-vous sûr de vouloir supprimer ce devoir ?",
    "fi": "Haluatko varmasti poistaa tämän läksyn?",
    "is": "Ertu viss um að þú viljir eyða þessari heimaverkefni?",
    "es": "¿Estás seguro de que deseas eliminar esta tarea?"
  },
  "confirm_deleting_answer": {
    "de": "Sind Sie sicher, dass Sie diese Erklärung löschen möchten?",
    "en": "Are you sure you want to delete this explanation?",
    "sk": "Ste si istý, že chcete odstrániť toto vysvetlenie?",
    "nl": "Weet u zeker dat u deze verklaring wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți această explicație?",
    "cs": "Jste si jistý, že chcete smazat toto vysvětlení?",
    "sl": "Ste prepričani, da želite izbrisati to pojasnilo?",
    "fr": "Êtes-vous sûr de vouloir supprimer cette explication?",
    "fi": "Oletko varma, että haluat poistaa tämän selityksen?",
    "is": "Ertu viss um að þú viljir eyða þessari skýringu?",
    "es": "¿Estás seguro de que deseas eliminar esta explicación?"
  },
  "date-is-in-past": {
    "de": "Diese Hausübung ist abgelaufen. Bitte löschen Sie sie.",
    "en": "This homework assignment has expired. Please delete it.",
    "sk": "Táto domáca úloha vypršala. Prosím, vymažte ju.",
    "nl": "Deze huiswerkopdracht is verlopen. Verwijder deze alstublieft.",
    "ro": "Această temă pentru acasă a expirat. Vă rugăm să o ștergeți.",
    "cs": "Tato domácí úloha již vypršela. Prosím, smažte ji.",
    "sl": "Ta domača naloga je potekla. Prosim, izbrišite jo.",
    "fr": "Ce devoir est expiré. Veuillez le supprimer.",
    "fi": "Tämä läksy on vanhentunut. Poista se, ole hyvä.",
    "is": "Þessi heimaverkefni er útrunninn. Vinsamlegast eyddu því.",
    "es": "Esta tarea ha vencido. Por favor, elimínela."
  },
  "homework_has_been_completed": {
    "de": "Diese Hausübung ist abgeschlossen",
    "en": "This homework is completed",
    "sk": "Táto domáca úloha je ukončená",
    "nl": "Dit huiswerk is voltooid",
    "ro": "Această temă pentru acasă este completată",
    "cs": "Tato domácí úloha je dokončena",
    "sl": "Ta domača naloga je zaključena",
    "fr": "Ce devoir est terminé",
    "fi": "Tämä läksy on suoritettu",
    "is": "Þessi heimavinna er lokið",
    "es": "Esta tarea está completada"
  },
  "ppl_preliminary_examination": {
    "de": "PPL Vorprüfung",
    "en": "PPL Preliminary Examination",
    "sk": "PPL Predbežné skúšky",
    "nl": "PPL Vooronderzoek",
    "ro": "Examen preliminar PPL",
    "cs": "PPL Předběžné zkoušky",
    "sl": "PPL Predhodni izpit",
    "fr": "Examen préliminaire PPL",
    "fi": "PPL Alustava tutkinto",
    "is": "PPL Forskoðunarefni",
    "es": "Examen Preliminar de PPL"
  },
  "student_insights": {
    "de": "Insights für",
    "en": "Insights for",
    "sk": "Závery pre",
    "nl": "Inzichten voor",
    "ro": "Informații pentru",
    "cs": "Náhledy pro",
    "sl": "Vpogledi za",
    "fr": "Aperçus pour",
    "fi": "Näkymät kohteelle",
    "is": "Innsýnir fyrir",
    "es": "Perspectivas para"
  },
  "student_details": {
    "de": "Flugschüler Details",
    "en": "Flightstudent details",
    "sk": "Podrobnosti študenta",
    "nl": "Details voor vliegstudent",
    "ro": "Detalii pentru studentul de zbor",
    "cs": "Podrobnosti o studentovi",
    "sl": "Podrobnosti za študenta",
    "fr": "Détails de l'élève-pilote",
    "fi": "Lentäjäopiskelijan tiedot",
    "is": "Upplýsingar um flugnemandi",
    "es": "Detalles del estudiante de vuelo"
  },
  "get_homework_statistics": {
    "de": "Hausübung Statistiken anzeigen",
    "en": "Show homework statistics",
    "sk": "Zobraziť štatistiky domácich úloh",
    "nl": "Huiswerkstatistieken weergeven",
    "ro": "Afișați statistici pentru temele pentru acasă",
    "cs": "Zobrazit statistiky domácích úkolů",
    "sl": "Prikaži statistiko domačih nalog",
    "fr": "Afficher les statistiques des devoirs",
    "fi": "Näytä läksyjen tilastotiedot",
    "is": "Sýna heimavinna stærðfræði",
    "es": "Mostrar estadísticas de tareas"
  },
  "homework_statistics": {
    "de": "Hausübung Statistik",
    "en": "Homework statistics",
    "sk": "Štatistiky domácich úloh",
    "nl": "Huiswerkstatistieken",
    "ro": "Statistici pentru temele pentru acasă",
    "cs": "Statistiky domácích úkolů",
    "sl": "Statistika domačih nalog",
    "fr": "Statistiques des devoirs",
    "fi": "Läksyjen tilastotiedot",
    "is": "Heimavinna stærðfræði",
    "es": "Estadísticas de tareas"
  },
  "exam_statistics": {
    "de": "Prüfung Statistik",
    "en": "Exam statistics",
    "sk": "Štatistiky skúšok",
    "nl": "Examenstatistieken",
    "ro": "Statistici examen",
    "cs": "Statistiky zkoušek",
    "sl": "Statistika izpitov",
    "fr": "Statistiques de l'examen",
    "fi": "Tenttistatiistiikka",
    "is": "Prófstærðfræði",
    "es": "Estadísticas del examen"
  },
  "exam_statistics_description": {
    "de": "Übersicht aller Flugschüler zu dieser Prüfung",
    "en": "Overview of all flight students to this exam",
    "sk": "Prehľad všetkých študentov letu pre túto skúšku",
    "nl": "Overzicht van alle vliegstudenten voor dit examen",
    "ro": "Prezentare generală a tuturor elevilor de zbor pentru acest examen",
    "cs": "Přehled všech leteckých studentů pro tuto zkoušku",
    "sl": "Pregled vseh študentov letenja za to izpit",
    "fr": "Vue d'ensemble de tous les étudiants en pilotage pour cet examen",
    "fi": "Yleiskatsaus kaikista lentävistä opiskelijoista tähän tenttiin",
    "is": "Yfirsýn yfir öllum flugnemendum á þessu prófi",
    "es": "Resumen de todos los estudiantes de vuelo en este examen"
  },
  "homework_statistics_description": {
    "de": "Übersicht aller Flugschüler zu dieser Hausübung",
    "en": "Overview of all flight students to this homework",
    "sk": "Prehľad všetkých leteckých študentov k tejto domácej úlohe",
    "nl": "Overzicht van alle vliegstudenten voor dit huiswerk",
    "ro": "Prezentare generală a tuturor studenților de zbor la această temă pentru acasă",
    "cs": "Přehled všech leteckých studentů k této domácí úloze",
    "sl": "Pregled vseh letalskih študentov do te domače naloge",
    "fr": "Vue d'ensemble de tous les élèves-pilotes de ce devoir",
    "fi": "Yleiskatsaus kaikista lentäjäopiskelijoista tähän läksyyn",
    "is": "Yfirlit yfir öllum flugnemum þessi heimavinna",
    "es": "Resumen de todos los estudiantes de vuelo en esta tarea"
  },
  "full_count_for_exam_description": {
    "de": "Wie viele Fragen sollen pro Kategorie verwendet werden (maximale Anzahl)?",
    "en": "How many questions should be used per category (maximum number)?",
    "sk": "Koľko otázok by malo byť použitých na kategóriu (maximálny počet)?",
    "nl": "Hoeveel vragen moeten per categorie worden gebruikt (maximaal aantal)?",
    "ro": "Câte întrebări ar trebui să fie utilizate pe categorie (număr maxim)?",
    "cs": "Kolik otázek by mělo být použito na kategorii (maximální počet)?",
    "sl": "Koliko vprašanj naj bo uporabljenih na kategorijo (največje število)?",
    "fr": "Combien de questions devraient être utilisées par catégorie (nombre maximal) ?",
    "fi": "Kuinka monta kysymystä tulisi käyttää kategoriaa kohden (enimmäismäärä)?",
    "is": "Hversu margar spurningar á að nota á hverja flokk (hámarksfjöldi)?",
    "es": "¿Cuántas preguntas deben usarse por categoría (número máximo)?"
  },
  "full_count_for_homework": {
    "de": "Fragenanzahl pro Kategorie",
    "en": "Number of questions per category",
    "sk": "Počet otázok na kategóriu",
    "nl": "Aantal vragen per categorie",
    "ro": "Numărul total de întrebări pe categorie",
    "cs": "Celkový počet otázek na kategorii",
    "sl": "Skupno število vprašanj na kategorijo",
    "fr": "Nombre total de questions par catégorie",
    "fi": "Kysymysten määrä yhteensä per kategoria",
    "is": "Heildarfjöldi spurninga í hverjum flokki",
    "es": "Número de preguntas por categoría"
  },
  "minimum_exam_percentage": {
    "de": "Mindestprozentsatz",
    "en": "Minimum percentage",
    "sk": "Minimálny percentuálny podiel",
    "nl": "Minimaal percentage",
    "ro": "Procent minim",
    "cs": "Minimální procento",
    "sl": "Minimalni odstotek",
    "fr": "Pourcentage minimum",
    "fi": "Vähimmäisprosentti",
    "is": "Lágmarksprósentuhlutur",
    "es": "Porcentaje mínimo"
  },
  "full_minimum_exam_percentage": {
    "de": "Wieviel Prozent müssen erreicht werden, damit das Ergebnis positiv ist?",
    "en": "What percentage must be achieved for the result to be positive?",
    "sk": "Koľko percent musí byť dosiahnutých v teste, aby bol pozitívny?",
    "nl": "Welk percentage moet worden behaald in de test om deze positief te laten zijn?",
    "ro": "Ce procent trebuie atins în test pentru ca acesta să fie pozitiv?",
    "cs": "Kolik procent musí být dosaženo v testu, aby byl pozitivní?",
    "sl": "Koliko odstotkov je treba doseči na testu, da bo ta pozitiven?",
    "fr": "Quel pourcentage doit être atteint dans le test pour qu'il soit positif?",
    "fi": "Kuinka suuri prosenttiosuus on saavutettava testissä, jotta se olisi positiivinen?",
    "is": "Hvaða prósentum þarf að ná í prófinu til að það sé jákvætt?",
    "es": "¿Qué porcentaje se debe alcanzar para que el resultado sea positivo?"
  },
  "percentage": {
    "de": "Prozent",
    "en": "Percentage",
    "sk": "Percento",
    "nl": "Percentage",
    "ro": "Procent",
    "cs": "Procento",
    "sl": "Odstotek",
    "fr": "Pourcentage",
    "fi": "Prosenttiosuus",
    "is": "Prósent",
    "es": "Porcentaje"
  },
  "number": {
    "de": "Anzahl",
    "en": "Number",
    "sk": "Počet",
    "nl": "Aantal",
    "ro": "Număr",
    "cs": "Počet",
    "sl": "Število",
    "fr": "Nombre",
    "fi": "Luku",
    "is": "Fjöldi",
    "es": "Número"
  },
  "NVFR": {
    "de": "Nachtsichtflug (NVFR)",
    "en": "Night VFR",
    "sk": "NVFR",
    "nl": "NVFR",
    "ro": "NVFR",
    "cs": "NVFR",
    "sl": "NVFR",
    "fr": "NVFR",
    "fi": "NVFR",
    "is": "NVFR",
    "es": "VFR nocturno"
  },
  "first_add_a_group": {
    "de": "Zuerst eine neue Klasse hinzufügen.",
    "en": "First create a new class.",
    "sk": "Najprv pridajte novú triedu.",
    "nl": "Maak eerst een nieuwe klas aan.",
    "ro": "Creați mai întâi o nouă clasă.",
    "cs": "Nejprve vytvořte novou třídu.",
    "sl": "Najprej dodajte nov razred.",
    "fr": "Créez d'abord une nouvelle classe.",
    "fi": "Luo ensin uusi luokka.",
    "is": "Búðu fyrst til nýjan bekk.",
    "es": "Crea primero una nueva clase."
  },
  "choose_class_exam_hint": {
    "de": "Für welche Klasse soll die Prüfung erstellt werden?",
    "en": "For which class should a new exam assignment be created?",
    "sk": "Pre ktorú triedu sa má vytvoriť nová skúšková úloha?",
    "nl": "Voor welke klas moet een nieuwe examenopdracht worden gemaakt?",
    "ro": "Pentru ce clasă ar trebui creată o nouă sarcină de examinare?",
    "cs": "Pro kterou třídu má být vytvořena nová zkoušková úloha?",
    "sl": "Za kateri razred naj se ustvari nova izpitna naloga?",
    "fr": "Pour quelle classe doit être créée une nouvelle tâche d'examen ?",
    "fi": "Mikä luokka tarvitsee uuden kokeen tehtävän?",
    "is": "Fyrir hvaða bekk á að búa til nýja prófsverkefni?",
    "es": "¿Para qué clase se debe crear una nueva asignación de examen?"
  },
  "choose_student_exam_hint": {
    "de": "Für welche Flugschüler soll die Prüfung erstellt werden?",
    "en": "For which students should the exam be set up?",
    "sk": "Pre ktorých študentov sa má skúška nastaviť?",
    "nl": "Voor welke studenten moet het examen worden opgezet?",
    "ro": "Pentru care elevi ar trebui să fie inițiat examenul?",
    "cs": "Pro které studenty má být zkouška nastavena?",
    "sl": "Za katere študente naj se določi izpit?",
    "fr": "Pour quels étudiants les examens doivent-ils être mis en place ?",
    "fi": "Mille opiskelijoille koe tulisi järjestää?",
    "is": "Fyrir hvaða nemendur á að setja prófið upp?",
    "es": "¿Para qué estudiantes se debe configurar el examen?"
  },
  "by_when_must_the_exam_be_done": {
    "de": "Bis wann sollte die Prüfung aktiv sein?",
    "en": "By when should the exam be active?",
    "sk": "Do kedy musí byť skúška aktívna?",
    "nl": "Voor wanneer moet het examen actief zijn?",
    "ro": "Până când trebuie să fie activ examenul?",
    "cs": "Do kdy musí být zkouška aktivní?",
    "sl": "Do kdaj mora biti izpit aktiven?",
    "fr": "Jusqu'à quand l'examen doit-il être actif ?",
    "fi": "Milloin tentin tulisi olla aktiivinen?",
    "is": "Fyrir hvaða dag verður prófið vera virkt?",
    "es": "¿Para cuándo debe estar activo el examen?"
  },
  "which_categories_should_be_done_as_exam": {
    "de": "Welche Kategorie(n) sollen in der Prüfung erledigt werden?",
    "en": "Which categories should be completed in the exam?",
    "sk": "Ktoré kategórie by mali byť dokončené v skúške?",
    "nl": "Welke categorieën moeten in het examen worden voltooid?",
    "ro": "Ce categorii ar trebui completate în examen?",
    "cs": "Které kategorie by měly být dokončeny ve zkoušce?",
    "sl": "Katere kategorije naj bodo opravljene v izpitu?",
    "fr": "Quelles catégories doivent être complétées dans l'examen?",
    "fi": "Mitkä kategoriat tulee suorittaa tentissä?",
    "is": "Hverir flokkar ættu að vera kláraðir í prófinu?",
    "es": "¿Qué categorías deben completarse en el examen?"
  },
  "all_current_exams": {
    "de": "Aktuelle Prüfungen",
    "en": "Current exams",
    "sk": "Všetky aktuálne skúšky",
    "nl": "Alle huidige examens",
    "ro": "Toate examenele actuale",
    "cs": "Všechny aktuální zkoušky",
    "sl": "Vse trenutne izpite",
    "fr": "Tous les examens en cours",
    "fi": "Kaikki nykyiset kokeet",
    "is": "Allar núverandi próf",
    "es": "Todos los exámenes actuales"
  },
  "create_exam": {
    "de": "Prüfung erstellen",
    "en": "Create an exam",
    "sk": "Vytvoriť skúšku",
    "nl": "Maak een examen",
    "ro": "Creați un examen",
    "cs": "Vytvořit zkoušku",
    "sl": "Ustvari izpit",
    "fr": "Créer un examen",
    "fi": "Luo tentti",
    "is": "Búa til próf",
    "es": "Crear un examen"
  },
  "due_to_hours": {
    "de": "Aktiv bis",
    "en": "Active until",
    "sk": "Aktívne do",
    "nl": "Actief tot",
    "ro": "Activ până la",
    "cs": "Aktivní do",
    "sl": "Aktivno do",
    "fr": "Actif jusqu'à",
    "fi": "Aktiivinen asti",
    "is": "Giltir til",
    "es": "Activo hasta"
  },
  "create_an_answer": {
    "de": "Erstelle eine Antwort",
    "en": "Create an answer",
    "sk": "Vytvoriť odpoveď",
    "nl": "Maak een antwoord",
    "ro": "Creați un răspuns",
    "cs": "Vytvořit odpověď",
    "sl": "Ustvari odgovor",
    "fr": "Créer une réponse",
    "fi": "Luo vastaus",
    "is": "Búðu til svar",
    "es": "Crear una respuesta"
  },
  "add_new_answer_explanation": {
    "de": "Erklärung hinzufügen",
    "en": "Add explanation",
    "sk": "Pridať vysvetlenie",
    "nl": "Uitleg toevoegen",
    "ro": "Adăugare explicație",
    "cs": "Přidat vysvětlení",
    "sl": "Dodaj pojasnilo",
    "fr": "Ajouter une explication",
    "fi": "Lisää selitys",
    "is": "Bæta útskýringu við",
    "es": "Agregar explicación"
  },
  "suggest_explanation": {
    "de": "Erklärung vorschlagen",
    "en": "Suggest explanation",
    "sk": "Navrhnúť vysvetlenie",
    "nl": "Verklaring voorstellen",
    "ro": "Sugerează explicația",
    "cs": "Navrhnout vysvětlení",
    "sl": "Predlagaj razlago",
    "fr": "Suggérer une explication",
    "fi": "Ehdota selitystä",
    "is": "Stinga upp á útskýringu",
    "es": "Sugerir explicación"
  },
  "all_question_explanations": {
    "de": "Erklärungen zu Fragen",
    "en": "Question explanations",
    "sk": "Všetky vysvetlenia k otázkam",
    "nl": "Alle vraagverklaringen",
    "ro": "Toate explicațiile la întrebări",
    "cs": "Všechna vysvětlení k otázkám",
    "sl": "Vsa pojasnila k vprašanjem",
    "fr": "Toutes les explications des questions",
    "fi": "Kaikki kysymyksen selitykset",
    "is": "Allar spurninga skýringar",
    "es": "Todas las explicaciones de las preguntas"
  },
  "create_new_answer_explanation": {
    "de": "Neue Antwort Erklärung erstellen",
    "en": "Create new answer explanation",
    "sk": "Vytvoriť nové vysvetlenie odpovede",
    "nl": "Maak nieuwe uitleg",
    "ro": "Creați o nouă explicație pentru răspuns",
    "cs": "Vytvořit nové vysvětlení odpovědi",
    "sl": "Ustvari novo pojasnilo odgovora",
    "fr": "Créer une nouvelle explication de réponse",
    "fi": "Luo uusi vastauksen selitys",
    "is": "Búa til nýja svarskýringu",
    "es": "Crear nueva explicación de respuesta"
  },
  "help_students_with_your_custom_explanation": {
    "de": "Unterstütze die Flugschüler mit einer detaillierten Erklärung für bestimmte Fragen.",
    "en": "Support your student pilots with a detailed explanation for certain questions.",
    "sk": "Podporte svojich študentov pilotov s podrobným vysvetlením pre určité otázky.",
    "nl": "Ondersteun uw student-piloten met een gedetailleerde uitleg voor bepaalde vragen.",
    "ro": "Susțineți piloții studenți cu o explicație detaliată pentru anumite întrebări.",
    "cs": "Podpořte své studenty piloty podrobným vysvětlením určitých otázek.",
    "sl": "Podprite svoje študentske pilote s podrobnim pojasnilom za določena vprašanja.",
    "fr": "Soutenez vos pilotes étudiants avec une explication détaillée pour certaines questions.",
    "fi": "Tukekaa opiskelijalentojenne koulutusta yksityiskohtaisilla selityksillä tiettyihin kysymyksiin.",
    "is": "Stuðlið nemendapilóta ykkur með nákvæmu útskýringu ákveðinna spurninga.",
    "es": "Apoye a sus pilotos estudiantes con una explicación detallada para ciertas preguntas."
  },
  "answer_assistance": {
    "de": "Antwort Erklärungen",
    "en": "Answer Explanations",
    "sk": "Vysvetlenie odpovedí",
    "nl": "Antwoordverklaringen",
    "ro": "Explicațiile răspunsurilor",
    "cs": "Vysvětlení odpovědí",
    "sl": "Razlage odgovorov",
    "fr": "Explications des réponses",
    "fi": "Vastausten selitykset",
    "is": "Svarskýringar",
    "es": "Explicaciones de respuestas"
  },
  "get_exam_statistics": {
    "de": "Prüfungen Statistiken anzeigen",
    "en": "Show exam statistics",
    "sk": "Zobraziť štatistiky skúšok",
    "nl": "Toon examenstatistieken",
    "ro": "Afișați statistici ale examenelor",
    "cs": "Zobrazit statistiky zkoušek",
    "sl": "Prikaži statistiko izpitov",
    "fr": "Afficher les statistiques des examens",
    "fi": "Näytä tenttien tilastot",
    "is": "Sýna prófastaða",
    "es": "Mostrar estadísticas del examen"
  },
  "exam_has_been_completed": {
    "de": "Diese Prüfung ist abgeschlossen",
    "en": "This exam is completed",
    "sk": "Táto skúška je ukončená",
    "nl": "Dit examen is voltooid",
    "ro": "Acest examen este completat",
    "cs": "Tato zkouška je dokončena",
    "sl": "Ta izpit je zaključen",
    "fr": "Cet examen est terminé",
    "fi": "Tämä tentti on suoritettu",
    "is": "Þetta próf er lokið",
    "es": "Este examen está completado"
  },
  "confirm_deleting_exam": {
    "de": "Sind Sie sicher, dass Sie diese Prüfung löschen möchten?",
    "en": "Are you sure you want to delete this exam?",
    "sk": "Ste si istý, že chcete túto skúšku vymazať?",
    "nl": "Weet u zeker dat u dit examen wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți acest examen?",
    "cs": "Opravdu chcete tuto zkoušku smazat?",
    "sl": "Ste prepričani, da želite izbrisati ta izpit?",
    "fr": "Êtes-vous sûr de vouloir supprimer cet examen ?",
    "fi": "Oletko varma, että haluat poistaa tämän tentin?",
    "is": "Ertu viss um að þú viljir eyða þessari prófi?",
    "es": "¿Estás seguro de que deseas eliminar este examen?"
  },
  "oClock": {
    "de": "Uhr",
    "en": "o'clock",
    "sk": "hodina",
    "nl": "uur",
    "ro": "fix",
    "cs": "hodin",
    "sl": "ura",
    "fr": "heure",
    "fi": "kello",
    "is": "klukkan",
    "es": "en punto"
  },
  "archive": {
    "de": "Archiv",
    "en": "Archive",
    "sk": "Archív",
    "nl": "Archief",
    "ro": "Arhivă",
    "cs": "Archiv",
    "sl": "Arhiv",
    "fr": "Archives",
    "fi": "Arkisto",
    "is": "Skjalasafn",
    "es": "Archivo"
  },
  "Homework_archive": {
    "de": "Hausübungen Archiv",
    "en": "Homework archive",
    "sk": "Archív domácich úloh",
    "nl": "Huiswerkarchief",
    "ro": "Arhivă teme pentru acasă",
    "cs": "Archiv domácích úkolů",
    "sl": "Arhiv domačih nalog",
    "fr": "Archives des devoirs",
    "fi": "Läksyjen arkisto",
    "is": "Skjalasafn heimavinna",
    "es": "Archivo de tareas"
  },
  "Exam_archive": {
    "de": "Prüfungen Archiv",
    "en": "Exams archive",
    "sk": "Archív skúšok",
    "nl": "Examenarchief",
    "ro": "Arhivă de examene",
    "cs": "Archiv zkoušek",
    "sl": "Arhiv izpitov",
    "fr": "Archives des examens",
    "fi": "Tenttien arkisto",
    "is": "Skjalasafn prófa",
    "es": "Archivo de exámenes"
  },
  "ended_on": {
    "de": "Beendet am",
    "en": "Ended on",
    "sk": "Skončené dňa",
    "nl": "Beëindigd op",
    "ro": "S-a încheiat la",
    "cs": "Ukončeno",
    "sl": "Končano dne",
    "fr": "Terminé le",
    "fi": "Päättyi",
    "is": "Lokið þann",
    "es": "Finalizado el"
  },
  "own_exams": {
    "de": "Eigene Prüfungen",
    "en": "Own exams",
    "sk": "Vlastné skúšky",
    "nl": "Eigen examens",
    "ro": "Examene proprii",
    "cs": "Vlastní zkoušky",
    "sl": "Lastni izpiti",
    "fr": "Examens personnels",
    "fi": "Omat kokeet",
    "is": "Eigin próf",
    "es": "Exámenes propios"
  },
  "no_enddate": {
    "de": "Kein Enddatum",
    "en": "No end date",
    "sk": "Žiadne dátum ukončenia",
    "nl": "Geen einddatum",
    "ro": "Fără dată de încheiere",
    "cs": "Bez data konce",
    "sl": "Brez datuma konca",
    "fr": "Pas de date de fin",
    "fi": "Ei päättymispäivää",
    "is": "Engin lokadagsetning",
    "es": "Sin fecha de finalización"
  }, 
  "show_correct_answer": {
    "de": "Hilfe",
    "en": "Help",
    "sk": "Pomoc",
    "nl": "Help",
    "ro": "Ajutor",
    "cs": "Nápověda",
    "sl": "Pomoč",
    "fr": "Aide",
    "fi": "Apu",
    "is": "Hjálp",
    "es": "Ayuda"
  },
  "id_number": {
    "de": "ID",
    "en": "Number",
    "sk": "Identifikačné číslo",
    "nl": "ID-nummer",
    "ro": "Număr de identificare",
    "cs": "Identifikační číslo",
    "sl": "Identifikacijska številka",
    "fr": "Numéro d'identification",
    "fi": "Tunnistenumero",
    "is": "Auðkennissnúmer",
    "es": "Número de identificación"
  },
  "explanation": {
    "de": "Erklärung",
    "en": "Explanation",
    "sk": "Vysvetlenie",
    "nl": "Uitleg",
    "ro": "Explicație",
    "cs": "Vysvětlení",
    "sl": "Pojasnilo",
    "fr": "Explication",
    "fi": "Selitys",
    "is": "Útskýring",
    "es": "Explicación"
  },
  "no_answers_available": {
    "de": "Keine Antworterklärungen verfügbar",
    "en": "No answer explanations available",
    "sk": "Žiadne dostupné vysvetlenia odpovedí",
    "nl": "Geen antwoordverklaringen beschikbaar",
    "ro": "Nu există explicații disponibile pentru răspunsuri",
    "cs": "Žádná dostupná vysvětlení odpovědí",
    "sl": "Ni razpoložljivih pojasnil odgovorov",
    "fr": "Aucune explication de réponse disponible",
    "fi": "Ei saatavilla olevia vastausten selityksiä",
    "is": "Engin útskýringar fyrir svar eru tiltækar",
    "es": "No hay explicaciones de respuestas disponibles"
  },
  "show_next_question": {
    "de": "Nächste Frage",
    "en": "Next question",
    "sk": "Ďalšia otázka",
    "nl": "Volgende vraag",
    "ro": "Următoarea întrebare",
    "cs": "Další otázka",
    "sl": "Naslednje vprašanje",
    "fr": "Question suivante",
    "fi": "Seuraava kysymys",
    "is": "Næsta spurning",
    "es": "Siguiente pregunta"
  },
  "thank_you_for_your_review": {
    "de": "Danke für dein Feedback!",
    "en": "Thank you for your feedback!",
    "sk": "Ďakujeme za vašu spätnú väzbu!",
    "nl": "Bedankt voor je feedback!",
    "ro": "Vă mulțumim pentru feedback!",
    "cs": "Děkujeme za vaši zpětnou vazbu!",
    "sl": "Hvala za vaše mnenje!",
    "fr": "Merci pour votre avis!",
    "fi": "Kiitos palautteestasi!",
    "is": "Takk fyrir ábendinguna þína!",
    "es": "¡Gracias por tus comentarios!"
  },
  "content_is_generated_by_ai": {
    "de": "Diese Hilfe wurde noch nicht auf Korrektheit überprüft und mithilfe von KI erstellt.",
    "en": "This content has not yet been checked for correctness and was created with the help of AI.",
    "sk": "Zobrazený obsah nebol zatiaľ skontrolovaný na správnosť a bol vytvorený pomocou umelej inteligencie.",
    "nl": "De getoonde inhoud is nog niet op correctheid gecontroleerd en is gemaakt met behulp van AI.",
    "ro": "Conținutul afișat nu a fost încă verificat pentru corectitudine și a fost creat cu ajutorul AI.",
    "cs": "Zobrazený obsah zatím nebyl zkontrolován na správnost a byl vytvořen pomocí AI.",
    "sl": "Prikazana vsebina še ni bila preverjena glede pravilnosti in je bila ustvarjena s pomočjo umetne inteligence.",
    "fr": "Le contenu affiché n'a pas encore été vérifié pour s'assurer qu'il est correct et a été créé à l'aide de l'IA.",
    "fi": "Näytetty sisältöä ei ole vielä tarkistettu oikeellisuuden varmistamiseksi ja se on luotu tekoälyn avulla.",
    "is": "Innihaldið sem sýnt hefur ekki verið skoðað fyrir réttindi og var búið til með hjálp AI.",
    "es": "El contenido mostrado aún no ha sido verificado para su corrección y ha sido creado con la ayuda de IA."
  },
  "provide_ai_feedback": {
    "de": "Feedback geben",
    "en": "Provide feedback",
    "sk": "Poskytnúť spätnú väzbu",
    "nl": "Feedback geven",
    "ro": "Oferiți feedback",
    "cs": "Poskytnout zpětnou vazbu",
    "sl": "Podajte povratne informacije",
    "fr": "Donner votre avis",
    "fi": "Anna palautetta",
    "is": "Gefa ábendingar",
    "es": "Dar tu opinión"
  },
  "send_feedback": {
    "de": "Feedback senden",
    "en": "Send feedback",
    "sk": "Odoslať spätnú väzbu",
    "nl": "Feedback verzenden",
    "ro": "Trimiteți feedback",
    "cs": "Odeslat zpětnou vazbu",
    "sl": "Pošljite povratne informacije",
    "fr": "Envoyer votre avis",
    "fi": "Lähetä palautetta",
    "is": "Senda ábendingar",
    "es": "Enviar comentarios"
  },
  "feedback_for_ai_content": {
    "de": "Feedback zum PPL Trainer Content",
    "en": "Feedback on the PPL Trainer Content",
    "sk": "Spätná väzba na obsah PPL Trénera",
    "nl": "Feedback voor de PPL Trainer-inhoud",
    "ro": "Feedback pentru conținutul PPL Trainer",
    "cs": "Zpětná vazba na obsah trenéra PPL",
    "sl": "Povratne informacije o vsebini trenerja PPL",
    "fr": "Retour sur le contenu du PPL Trainer",
    "fi": "Palaute PPL Trainer -sisällöstä",
    "is": "Endurgjöf á efni PPL Trainer",
    "es": "Comentarios sobre el contenido del Entrenador PPL"
  },
  "was_the_ai_content_helpful": {
    "de": "War der Content hilfreich?",
    "en": "Was the content helpful?",
    "sk": "Bol obsah užitočný?",
    "nl": "Was de inhoud nuttig?",
    "ro": "A fost util conținutul?",
    "cs": "Byl obsah užitečný?",
    "sl": "Je bila vsebina koristna?",
    "fr": "Le contenu était-il utile?",
    "fi": "Oliko sisältö hyödyllistä?",
    "is": "Var efnið gagnlegt?",
    "es": "¿Fue útil el contenido?"
  },
  "meh-frown-feedback-response": {
    "de": "Was ist schiefgelaufen?",
    "en": "What went wrong?",
    "sk": "Čo sa pokazilo?",
    "nl": "Wat is er misgegaan?",
    "ro": "Ce s-a întâmplat?",
    "cs": "Co šlo špatně?",
    "sl": "Kaj je šlo narobe?",
    "fr": "Qu'est-ce qui s'est mal passé?",
    "fi": "Mitä meni pieleen?",
    "is": "Hvað fór úrskeiðis?",
    "es": "¿Qué salió mal?"
  },
  "good-feedback-response": {
    "de": "Schön, das zu hören. Was fandest du hilfreich?",
    "en": "Nice to hear that. What did you find helpful?",
    "sk": "Potešuje ma to počuť. Čo ste považovali za užitočné?",
    "nl": "Fijn om te horen. Wat vond je nuttig?",
    "ro": "Frumos să aud asta. Ce ți s-a părut util?",
    "cs": "Rádo to slyším. Co jste považovali za užitečné?",
    "sl": "Lepo je slišati to. Kaj ste našli koristnega?",
    "fr": "Content de l'entendre. Qu'avez-vous trouvé utile?",
    "fi": "Kiva kuulla. Mitä pidit hyödyllisenä?",
    "is": "Gott að heyra það. Hvað fannstu gagnlegt?",
    "es": "Qué bueno escuchar eso. ¿Qué encontraste útil?"
  },
  "not_verified": {
    "de": "Nicht bestätigt",
    "en": "Not verified",
    "sk": "Neoverené",
    "nl": "Niet geverifieerd",
    "ro": "Neverificat",
    "cs": "Neověřené",
    "sl": "Nepotrjeno",
    "fr": "Non vérifié",
    "fi": "Ei vahvistettu",
    "is": "Staðfest ekki",
    "es": "No verificado"
  },
  "select_question": {
    "de": "Frage auswählen",
    "en": "Select question",
    "sk": "Vybrať otázku",
    "nl": "Selecteer vraag",
    "ro": "Selectați întrebarea",
    "cs": "Vybrat otázku",
    "sl": "Izberi vprašanje",
    "fr": "Sélectionner une question",
    "fi": "Valitse kysymys",
    "is": "Veljaðu spurningu",
    "es": "Seleccionar pregunta"
  },
  "scenarios": {
    "de": "Szenarien",
    "en": "Scenarios",
    "sk": "Scenáre",
    "nl": "Scenario's",
    "ro": "Scenarii",
    "cs": "Scénáře",
    "sl": "Scenariji",
    "fr": "Scénarios",
    "fi": "Skenaariot",
    "is": "Atburðarásir",
    "es": "Escenarios"
  },
  "add_new_scenario": {
    "de": "Neues Szenario hinzufügen",
    "en": "Add new scenario",
    "sk": "Pridať nové scenárium",
    "nl": "Voeg nieuw scenario toe",
    "ro": "Adăugați un scenariu nou",
    "cs": "Přidat nový scénář",
    "sl": "Dodaj novo scenarij",
    "fr": "Ajouter un nouveau scénario",
    "fi": "Lisää uusi skenaario",
    "is": "Bæta við nýju atburðarás",
    "es": "Agregar nuevo escenario"
  },
  "create_new_scenario": {
    "de": "Neues Szenario erstellen",
    "en": "Create new scenario",
    "sk": "Vytvoriť nové scenárium",
    "nl": "Nieuw scenario maken",
    "ro": "Creați un nou scenariu",
    "cs": "Vytvořit nový scénář",
    "sl": "Ustvari novo scenarij",
    "fr": "Créer un nouveau scénario",
    "fi": "Luo uusi skenaario",
    "is": "Búa til nýtt atburðarás",
    "es": "Crear nuevo escenario"
  },
  "scenario_adding_description": {
    "de": "Mit einem Szenario kannst du deine eigenen Trainingsabläufe erstellen, indem du sämtliche Fragen für ein komplexes Szenario zusammenfügst.",
    "en": "With a scenario, you can create your own training sequences by combining all the questions for a complex scenario.",
    "sk": "S scenáriom môžete vytvárať vlastné tréninkové postupy kombinovaním všetkých otázok pre zložité scenáre.",
    "nl": "Met een scenario kunt u uw eigen trainingsreeksen maken door alle vragen voor een complex scenario te combineren.",
    "ro": "Cu un scenariu, puteți crea propriile secvențe de antrenament combinând toate întrebările pentru un scenariu complex.",
    "cs": "S scénářem můžete vytvářet vlastní tréninkové sekvence spojením všech otázek pro složité scénáře.",
    "sl": "Z scenarijem lahko ustvarite svoje lastne usposabljanje sekvence z združevanjem vseh vprašanj za kompleksne scenarije.",
    "fr": "Avec un scénario, vous pouvez créer vos propres séquences de formation en combinant toutes les questions pour un scénario complexe.",
    "fi": "Skenaariolla voit luoda omat koulutussekvenssisi yhdistämällä kaikki kysymykset monimutkaiseen skenaarioon.",
    "is": "Með atburðarás getur þú búið til eigin þjálfunaröðir með því að sameina allar spurningar fyrir flókna atburðarásir.",
    "es": "Con un escenario, puedes crear tus propias secuencias de entrenamiento combinando todas las preguntas para un escenario complejo."
  },
  "scenario_adding_description_part2": {
    "de": "Die Flugschüler bekommen exakt diese ausgewählten Fragen in chronologischer Reihenfolge angezeigt.",
    "en": "The student pilots are shown exactly these selected questions in chronological order.",
    "sk": "Študenti piloti vidia presne tieto vybrané otázky v chronologickom poradí.",
    "nl": "De student-piloten worden precies deze geselecteerde vragen in chronologische volgorde getoond.",
    "ro": "Piloții studenți văd exact aceste întrebări selectate în ordine cronologică.",
    "cs": "Studenti piloti jsou zobrazeni přesně tyto vybrané otázky v chronologickém pořadí.",
    "sl": "Študentski piloti so prikazani ravno ti izbrani vprašanja v kronološkem vrstnem redu.",
    "fr": "Les pilotes étudiants voient exactement ces questions sélectionnées dans l'ordre chronologique.",
    "fi": "Opiskelijalentäjät näkevät juuri nämä valitut kysymykset aikajärjestyksessä.",
    "is": "Námsflugmenn sjá nákvæmlega þessar valdar spurningar í tímaröð.",
    "es": "Los pilotos estudiantes ven exactamente estas preguntas seleccionadas en orden cronológico."
  },
  "question_will_be_shown_cronologically": {
    "de": "Die Fragen werden im PPL Trainer anschließend chronologisch angezeigt.",
    "en": "The questions are displayed chronologically within the PPL Trainer.",
    "sk": "Otázky sa zobrazujú chronologicky v rámci PPL trénera.",
    "nl": "De vragen worden chronologisch weergegeven binnen de PPL Trainer.",
    "ro": "Întrebările sunt afișate cronologic în cadrul trainerului PPL.",
    "cs": "Otázky jsou zobrazeny chronologicky v rámci PPL trenéra.",
    "sl": "Vprašanja so prikazana kronološko v okviru trenerja PPL.",
    "fr": "Les questions sont affichées chronologiquement dans le PPL Trainer.",
    "fi": "Kysymykset näytetään kronologisesti PPL Trainerin sisällä.",
    "is": "Spurningarnar eru birtar á tímanum innan PPL Trainer.",
    "es": "Las preguntas se muestran cronológicamente dentro del PPL Trainer."
  },
  "add_scenario": {
    "de": "Szenario hinzufügen",
    "en": "Add scenario",
    "sk": "Pridať scenár",
    "nl": "Scenario toevoegen",
    "ro": "Adăugați scenariu",
    "cs": "Přidat scénář",
    "sl": "Dodaj scenarij",
    "fr": "Ajouter un scénario",
    "fi": "Lisää skenaario",
    "is": "Bæta við atburðarás",
    "es": "Agregar escenario"
  },
  "title_of_scenario": {
    "de": "Titel des Szenarios",
    "en": "Title of the scenario",
    "sk": "Názov scenára",
    "nl": "Titel van het scenario",
    "ro": "Titlul scenariului",
    "cs": "Název scénáře",
    "sl": "Naslov scenarija",
    "fr": "Titre du scénario",
    "fi": "Skenaarion otsikko",
    "is": "Titill á sinni",
    "es": "Título del escenario"
  },
  "description_of_scenario": {
    "de": "Beschreibe des Szenario kurz, damit man einen kurzen Überblick über das Szenario bekommt.",
    "en": "Describe the scenario briefly to give a brief overview of the scenario.",
    "sk": "Stručne opíšte scenár, aby ste získali stručný prehľad o scenáriu.",
    "nl": "Beschrijf het scenario kort om een ​​korte samenvatting van het scenario te geven.",
    "ro": "Descrieți scenariul pe scurt pentru a oferi o scurtă prezentare a scenariului.",
    "cs": "Popište scénář stručně, abyste získali stručný přehled o scénáři.",
    "sl": "Opis scenarija na kratko, da dobite kratek pregled scenarija.",
    "fr": "Décrivez brièvement le scénario pour donner un bref aperçu du scénario.",
    "fi": "Kuvaile skenaario lyhyesti saadaksesi lyhyen yleiskatsauksen skenaariosta.",
    "is": "Lýsið skenaríóið í stuttu máli til að gefa stutta yfirlýsingu um skenaríóið.",
    "es": "Describa brevemente el escenario para dar una breve descripción del escenario."
  },
  "select_questions": {
    "de": "Fragen auswählen",
    "en": "Choose Questions",
    "sk": "Vyberte otázky",
    "nl": "Kies vragen",
    "ro": "Alegeți întrebări",
    "cs": "Vyberte otázky",
    "sl": "Izberite vprašanja",
    "fr": "Choisissez des questions",
    "fi": "Valitse kysymykset",
    "is": "Veldu spurningar",
    "es": "Seleccionar preguntas"
  },
  "add_another_question": {
    "de": "Weitere Frage hinzufügen",
    "en": "Add another question",
    "sk": "Pridať ďalšiu otázku",
    "nl": "Voeg een andere vraag toe",
    "ro": "Adăugați o altă întrebare",
    "cs": "Přidat další otázku",
    "sl": "Dodajte še eno vprašanje",
    "fr": "Ajouter une autre question",
    "fi": "Lisää toinen kysymys",
    "is": "Bættu við annarri spurningu",
    "es": "Agregar otra pregunta"
  },
  "no_questions_selected": {
    "de": "Es sind noch keine Fragen vorhanden.",
    "en": "There are no questions available at the moment.",
    "sk": "Momentálne nie sú k dispozícii žiadne otázky.",
    "nl": "Er zijn op dit moment geen vragen beschikbaar.",
    "ro": "În acest moment nu sunt disponibile întrebări.",
    "cs": "Momentálně nejsou k dispozici žádné otázky.",
    "sl": "Trenutno ni na voljo nobenih vprašanj.",
    "fr": "Il n'y a actuellement aucune question disponible.",
    "fi": "Tällä hetkellä ei ole kysymyksiä saatavilla.",
    "is": "Engar spurningar eru tiltækar í augnablikinu.",
    "es": "No hay preguntas disponibles en este momento."
  },
  "add_question_to_scenario": {
    "de": "Frage zum Szenario hinzufügen",
    "en": "Add question to scenario",
    "sk": "Pridať otázku do scenára",
    "nl": "Vraag toevoegen aan scenario",
    "ro": "Adăugați întrebare la scenariu",
    "cs": "Přidat otázku do scénáře",
    "sl": "Dodaj vprašanje k scenariju",
    "fr": "Ajouter une question au scénario",
    "fi": "Lisää kysymys skenaarioon",
    "is": "Bæta spurningu við aðstæðu",
    "es": "Agregar pregunta al escenario"
  },
  "add_short_new_scenario": {
    "de": "Szenario hinzufügen",
    "en": "Add scenario",
    "sk": "Pridať scénar",
    "nl": "Scenario toevoegen",
    "ro": "Adăugare scenariu",
    "cs": "Přidat scénář",
    "sl": "Dodaj scenarij",
    "fr": "Ajouter un scénario",
    "fi": "Lisää skenaario",
    "is": "Bæta við aðstæðu",
    "es": "Agregar escenario"
  },
  "no_scenario_available": {
    "de": "Kein Szenario nicht verfügbar",
    "en": "No scenario available",
    "sk": "Žiadny scénar nie je k dispozícii",
    "nl": "Geen scenario beschikbaar",
    "ro": "Niciun scenariu disponibil",
    "cs": "Žádný scénář není k dispozici",
    "sl": "Ni scenarija na voljo",
    "fr": "Aucun scénario disponible",
    "fi": "Ei skenaarioita saatavilla",
    "is": "Engin aðstæða tiltæk",
    "es": "Ningún escenario disponible"
  },
  "confirm_deleting_scenario": {
    "de": "Sind Sie sicher, dass sie dieses Szenario löschen möchten?",
    "en": "Are you sure you want to delete this scenario?",
    "sk": "Ste si istí, že chcete tento scénar zmazať?",
    "nl": "Weet u zeker dat u dit scenario wilt verwijderen?",
    "ro": "Sunteți sigur că doriți să ștergeți acest scenariu?",
    "cs": "Jste si jisti, že chcete tento scénář smazat?",
    "sl": "Ste prepričani, da želite izbrisati ta scenarij?",
    "fr": "Êtes-vous sûr de vouloir supprimer ce scénario?",
    "fi": "Oletko varma, että haluat poistaa tämän skenaarion?",
    "is": "Ertu viss um að þú viljir eyða þessari aðstæðu?",
    "es": "¿Estás seguro de que quieres eliminar este escenario?"
  },
  "scenario": {
    "de": "Szenario",
    "en": "Scenario",
    "sk": "Scenár",
    "nl": "Scenario",
    "ro": "Scenariu",
    "cs": "Scénář",
    "sl": "Scenarij",
    "fr": "Scénario",
    "fi": "Skenaario",
    "is": "Hugmynd",
    "es": "Escenario"
  },
  "number_short": {
    "de": "Nr",
    "en": "No",
    "sk": "Nr",
    "nl": "Nr",
    "ro": "Nr",
    "cs": "Nr",
    "sl": "Št",
    "fr": "No",
    "fi": "No",
    "is": "Nr",
    "es": "Núm"
  },
  "go_scenario": {
    "de": "Szenario starten",
    "en": "Start scenario",
    "sk": "Spustiť scenár",
    "nl": "Scenario starten",
    "ro": "Pornirea scenariului",
    "cs": "Spustit scénář",
    "sl": "Začni scenarij",
    "fr": "Démarrer le scénario",
    "fi": "Käynnistä skenaario",
    "is": "Byrja hugmynd",
    "es": "Iniciar escenario"
  },
  "description": {
    "de": "Beschreibung",
    "en": "Description",
    "sk": "Popis",
    "nl": "Beschrijving",
    "ro": "Descriere",
    "cs": "Popis",
    "sl": "Opis",
    "fr": "Description",
    "fi": "Kuvaus",
    "is": "Lýsing",
    "es": "Descripción"
  },
  "de": {
    "de": "Deutsch",
    "en": "German",
    "sk": "Nemecký",
    "nl": "Duits",
    "ro": "Germană",
    "cs": "Německý",
    "sl": "Nemško",
    "fr": "Allemand",
    "fi": "Saksa",
    "is": "Þýska",
    "es": "Alemán"
  },
  "en": {
    "de": "Englisch",
    "en": "English",
    "sk": "Anglický",
    "nl": "Engels",
    "ro": "Engleză",
    "cs": "Anglický",
    "sl": "Angleško",
    "fr": "Anglais",
    "fi": "Englanti",
    "is": "Enska",
    "es": "Inglés"
  },
  "sk": {
    "de": "Slowakisch",
    "en": "Slovak",
    "sk": "Slovenský",
    "nl": "Slowaaks",
    "ro": "Slovacă",
    "cs": "Slovenský",
    "sl": "Slovaško",
    "fr": "Slovaque",
    "fi": "Slovakki",
    "is": "Slóvakíska",
    "es": "Eslovaco"
  },
  "nl": {
    "de": "Niederländisch",
    "en": "Dutch",
    "sk": "Holandský",
    "nl": "Nederlands",
    "ro": "Olandeză",
    "cs": "Nizozemský",
    "sl": "Nizozemščina",
    "fr": "Néerlandais",
    "fi": "Hollantilainen",
    "is": "Hollenska",
    "es": "Holandés"
  },
  "ro": {
    "de": "Rumänisch",
    "en": "Romanian",
    "sk": "Rumunsky",
    "nl": "Roemeens",
    "ro": "Română",
    "cs": "Rumunština",
    "sl": "Romunski",
    "fr": "Roumain",
    "fi": "Romanialainen",
    "is": "Rúmenska",
    "es": "Rumano"
  },
  "cs": {
    "de": "Tschechisch",
    "en": "Czech",
    "sk": "Český",
    "nl": "Tsjechisch",
    "ro": "Cehă",
    "cs": "Čeština",
    "sl": "Češki",
    "fr": "Tchèque",
    "fi": "Tšekki",
    "is": "Tékkneska",
    "es": "Checo"
  },
  "sl": {
    "de": "Slowenisch",
    "en": "Slovenian",
    "sk": "Slovinský",
    "nl": "Sloveens",
    "ro": "Slovenă",
    "cs": "Slovinština",
    "sl": "Slovenski",
    "fr": "Slovène",
    "fi": "Sloveeni",
    "is": "Slóvenska",
    "es": "Esloveno"
  },
  "fr": {
    "de": "Französisch",
    "en": "French",
    "sk": "Francúzsky",
    "nl": "Frans",
    "ro": "Franceză",
    "cs": "Francouzština",
    "sl": "Francoščina",
    "fr": "Français",
    "fi": "Ranskalainen",
    "is": "Franska",
    "es": "Francés"
  },
  "fi": {
    "de": "Finnisch",
    "en": "Finnish",
    "sk": "Fínsky",
    "nl": "Fins",
    "ro": "Finlandeză",
    "cs": "Finština",
    "sl": "Finska",
    "fr": "Finnois",
    "fi": "Suomalainen",
    "is": "Finnska",
    "es": "Finlandés"
  },
  "is": {
    "de": "Isländisch",
    "en": "Icelandic",
    "sk": "Islandský",
    "nl": "IJslands",
    "ro": "Islandeză",
    "cs": "Islandština",
    "sl": "Islandski",
    "fr": "Islandais",
    "fi": "Islantilainen",
    "is": "Íslenska",
    "es": "Islandés"
  },
  "es": {
    "de": "Spanisch",
    "en": "Spanish",
    "sk": "Španielsky",
    "nl": "Spaans",
    "ro": "Spaniolă",
    "cs": "Španělština",
    "sl": "Španski",
    "fr": "Espagnol",
    "fi": "Espanjalainen",
    "is": "Spænska",
    "es": "Español"
  },
  "document": {
    "de": "Dokument",
    "en": "Document",
    "sk": "Dokument",
    "nl": "Document",
    "ro": "Document",
    "cs": "Dokument",
    "sl": "Dokument",
    "fr": "Document",
    "fi": "Asiakirja",
    "is": "Skjal",
    "es": "Documento"
  },
  "documents": {
    "de": "Dokumente",
    "en": "Documents",
    "sk": "Dokumenty",
    "nl": "Documenten",
    "ro": "Documente",
    "cs": "Dokumenty",
    "sl": "Dokumenti",
    "fr": "Documents",
    "fi": "Asiakirjat",
    "is": "Skjöl",
    "es": "Documentos"
  },
  "upload_new_document": {
    "de": "Dokument uploaden",
    "en": "Upload document",
    "sk": "Nahrať dokument",
    "nl": "Document uploaden",
    "ro": "Încărcați documentul",
    "cs": "Nahrát dokument",
    "sl": "Naloži dokument",
    "fr": "Télécharger le document",
    "fi": "Lataa asiakirja",
    "is": "Hladdu upp skjali",
    "es": "Subir documento"
  },
  "save_document": {
    "de": "Dokument speichern",
    "en": "Save document",
    "sk": "Uložiť dokument",
    "nl": "Document opslaan",
    "ro": "Salvează documentul",
    "cs": "Uložit dokument",
    "sl": "Shrani dokument",
    "fr": "Enregistrer le document",
    "fi": "Tallenna asiakirja",
    "is": "Vista skjal",
    "es": "Guardar documento"
  },
  "new_document_description": {
    "de": "Ein Dokument kann als Anhang zu bestimmten Fragen oder zu verschiedenen Kategorien hinzugefügt werden.",
    "en": "A document can be added as an attachment to certain questions or to different categories.",
    "sk": "Dokument môže byť pripojený ako príloha k určitým otázkam alebo k rôznym kategóriám.",
    "nl": "Een document kan als bijlage worden toegevoegd aan bepaalde vragen of aan verschillende categorieën.",
    "ro": "Un document poate fi adăugat ca atașament la anumite întrebări sau la diferite categorii.",
    "cs": "Dokument může být přidán jako příloha k určitým otázkám nebo k různým kategoriím.",
    "sl": "Dokument je mogoče dodati kot prilogo določenim vprašanjem ali različnim kategorijam.",
    "fr": "Un document peut être ajouté en tant que pièce jointe à certaines questions ou à différentes catégories.",
    "fi": "Asiakirja voidaan lisätä liitteenä tietyille kysymyksille tai eri kategorioille.",
    "is": "Skjal má bæta við sem viðhengi við ákveðnar spurningar eða í mismunandi flokka.",
    "es": "Un documento se puede añadir como un adjunto a ciertas preguntas o a diferentes categorías."
  },
  "links": {
    "de": "Verknüpfungen",
    "en": "Links",
    "sk": "Odkazy",
    "nl": "Links",
    "ro": "Link-uri",
    "cs": "Odkazy",
    "sl": "Povezave",
    "fr": "Liens",
    "fi": "Linkit",
    "is": "Tenglar",
    "es": "Enlaces"
  },
  "no_documents_available": {
    "de": "Keine Dokumente vorhanden.",
    "en": "No documents available.",
    "sk": "Žiadne dokumenty nie sú k dispozícii.",
    "nl": "Geen documenten beschikbaar.",
    "ro": "Nu există documente disponibile.",
    "cs": "Žádné dokumenty nejsou k dispozici.",
    "sl": "Ni razpoložljivih dokumentov.",
    "fr": "Aucun document disponible.",
    "fi": "Ei asiakirjoja saatavilla.",
    "is": "Engin skjöl í boði.",
    "es": "No hay documentos disponibles."
  },
  "choose_a_file_to_upload": {
    "de": "Wähle ein Dokument oder ziehe es hier her",
    "en": "Choose a document or drag & drop it here",
    "sk": "Vyberte dokument alebo ho presuňte sem",
    "nl": "Kies een document of sleep het hierheen",
    "ro": "Alegeți un document sau trageți-l și plasați-l aici",
    "cs": "Vyberte dokument nebo ho sem přetáhněte",
    "sl": "Izberite dokument ali ga povlecite sem",
    "fr": "Choisissez un document ou glissez-le ici",
    "fi": "Valitse asiakirja tai vedä ja pudota se tähän",
    "is": "Veldu skjal eða dragðu og slepptu því hér",
    "es": "Elija un documento o arrástrelo aquí"
  },
  "browse_file": {
    "de": "Dokument suchen",
    "en": "Browse File",
    "sk": "Prehľadať súbor",
    "nl": "Blader door bestand",
    "ro": "Răsfoiește fișierul",
    "cs": "Procházet soubor",
    "sl": "Brskanje po datoteki",
    "fr": "Parcourir le fichier",
    "fi": "Selaa tiedostoa",
    "is": "Flettu í gegnum skjal",
    "es": "Buscar archivo"
  },
  "jpg_png_pdf_file_list": {
    "de": "JPEG, PNG, PDF bis 5MB",
    "en": "JPEG, PNG, PDF up to 5MB",
    "sk": "JPEG, PNG, PDF do 5MB",
    "nl": "JPEG, PNG, PDF tot 5MB",
    "ro": "JPEG, PNG, PDF până la 5MB",
    "cs": "JPEG, PNG, PDF až do 5MB",
    "sl": "JPEG, PNG, PDF do 5MB",
    "fr": "JPEG, PNG, PDF jusqu'à 5MB",
    "fi": "JPEG, PNG, PDF enintään 5 Mt",
    "is": "JPEG, PNG, PDF allt að 5MB",
    "es": "JPEG, PNG, PDF hasta 5MB"
  },
  "change_document": {
    "de": "Dokument ändern",
    "en": "Change document",
    "sk": "Zmeniť dokument",
    "nl": "Wijzig document",
    "ro": "Schimbați documentul",
    "cs": "Změnit dokument",
    "sl": "Spremeni dokument",
    "fr": "Modifier le document",
    "fi": "Muuta asiakirjaa",
    "is": "Breyta skjali",
    "es": "Cambiar documento"
  },
  "assign_possible_questions_and_categories": {
    "de": "Fragen und Kategorien zuweisen",
    "en": "Assign questions and categories",
    "sk": "Priradiť otázky a kategórie",
    "nl": "Vragen en categorieën toewijzen",
    "ro": "Atribuiți întrebări și categorii",
    "cs": "Přiřadit otázky a kategorie",
    "sl": "Dodeli vprašanja in kategorije",
    "fr": "Attribuer des questions et des catégories",
    "fi": "Määritä kysymykset ja kategoriat",
    "is": "Úthluta spurningum og flokkum",
    "es": "Asignar preguntas y categorías"
  },
  "select_specific_questions_to_show_the_document_in": {
    "de": "Wähle konkrete Fragen oder Kategorien aus, in denen das Dokument angezeigt werden soll.",
    "en": "Select specific questions or categories in which the document should be displayed.",
    "sk": "Vyberte konkrétne otázky alebo kategórie, v ktorých sa má dokument zobraziť.",
    "nl": "Selecteer specifieke vragen of categorieën waarin het document moet worden weergegeven.",
    "ro": "Selectați întrebări sau categorii specifice în care ar trebui să fie afișat documentul.",
    "cs": "Vyberte konkrétní otázky nebo kategorie, ve kterých má být dokument zobrazen.",
    "sl": "Izberite specifična vprašanja ali kategorije, v katerih naj bo dokument prikazan.",
    "fr": "Sélectionnez des questions ou des catégories spécifiques dans lesquelles le document doit être affiché.",
    "fi": "Valitse tietyt kysymykset tai kategoriat, joissa asiakirja näytetään.",
    "is": "Veldu sérstakar spurningar eða flokka þar sem skjalið ætti að birtast.",
    "es": "Seleccione preguntas o categorías específicas en las que se debe mostrar el documento."
  },
  "add_another_category": {
    "de": "Weitere Kategorie hinzufügen",
    "en": "Add another category",
    "sk": "Pridať ďalšiu kategóriu",
    "nl": "Nog een categorie toevoegen",
    "ro": "Adăugați o altă categorie",
    "cs": "Přidat další kategorii",
    "sl": "Dodaj drugo kategorijo",
    "fr": "Ajouter une autre catégorie",
    "fi": "Lisää toinen kategoria",
    "is": "Bæta við öðrum flokki",
    "es": "Añadir otra categoría"
  },
  "no_categories_selected": {
    "de": "Es sind noch keine Kategorien vorhanden.",
    "en": "There are no categories available at the moment.",
    "sk": "Momentálne nie sú k dispozícii žiadne kategórie.",
    "nl": "Er zijn momenteel geen categorieën beschikbaar.",
    "ro": "Nu există categorii disponibile momentan.",
    "cs": "V tuto chvíli nejsou k dispozici žádné kategorie.",
    "sl": "Trenutno ni na voljo nobenih kategorij.",
    "fr": "Aucune catégorie disponible pour le moment.",
    "fi": "Kategorioita ei ole tällä hetkellä saatavilla.",
    "is": "Engir flokkar eru tiltækir eins og er.",
    "es": "No hay categorías disponibles en este momento."
  },
  "select_category": {
    "de": "Kategorie auswählen",
    "en": "Select category",
    "sk": "Vybrať kategóriu",
    "nl": "Selecteer categorie",
    "ro": "Selectați categoria",
    "cs": "Vyberte kategorii",
    "sl": "Izberi kategorijo",
    "fr": "Sélectionner une catégorie",
    "fi": "Valitse kategoria",
    "is": "Veldu flokk",
    "es": "Seleccionar categoría"
  },
  "select_question_description": {
    "de": "Wähle einzeln die Fragen aus, in denen dieses Dokument angezeigt werden soll.",
    "en": "Individually select the questions in which this document is to be displayed.",
    "sk": "Individuálne vyberte otázky, v ktorých sa má tento dokument zobraziť.",
    "nl": "Selecteer individueel de vragen waarin dit document moet worden weergegeven.",
    "ro": "Selectați individual întrebările în care trebuie să fie afișat acest document.",
    "cs": "Individuálně vyberte otázky, ve kterých má být tento dokument zobrazen.",
    "sl": "Posamično izberite vprašanja, v katerih naj bo ta dokument prikazan.",
    "fr": "Sélectionnez individuellement les questions dans lesquelles ce document doit être affiché.",
    "fi": "Valitse yksittäin kysymykset, joissa tämä asiakirja tulee näyttää.",
    "is": "Veldu einstakar spurningar þar sem þetta skjal á að birtast.",
    "es": "Seleccione individualmente las preguntas en las que se debe mostrar este documento."
  },
  "select_category_description": {
    "de": "Wähle einzeln die Kategorien aus, in denen dieses Dokument angezeigt werden soll.",
    "en": "Individually select the categories in which this document is to be displayed.",
    "sk": "Individuálne vyberte kategórie, v ktorých sa má tento dokument zobraziť.",
    "nl": "Selecteer individueel de categorieën waarin dit document moet worden weergegeven.",
    "ro": "Selectați individual categoriile în care trebuie să fie afișat acest document.",
    "cs": "Individuálně vyberte kategorie, ve kterých má být tento dokument zobrazen.",
    "sl": "Posamično izberite kategorije, v katerih naj bo ta dokument prikazan.",
    "fr": "Sélectionnez individuellement les catégories dans lesquelles ce document doit être affiché.",
    "fi": "Valitse yksittäin kategoriat, joissa tämä asiakirja tulee näyttää.",
    "is": "Veldu einstaka flokka þar sem þetta skjal á að birtast.",
    "es": "Seleccione individualmente las categorías en las que se debe mostrar este documento."
  },
  "attachments": {
    "de": "Anhänge",
    "en": "Attachments",
    "sk": "Prílohy",
    "nl": "Bijlagen",
    "ro": "Atașamente",
    "cs": "Přílohy",
    "sl": "Priloge",
    "fr": "Pièces jointes",
    "fi": "Liitteet",
    "is": "Viðhengi",
    "es": "Archivos adjuntos"
  },
  "januar": {
    "de": "Jänner",
    "en": "January",
    "sk": "Január",
    "nl": "Januari",
    "ro": "Ianuarie",
    "cs": "Leden",
    "sl": "Januar",
    "fr": "Janvier",
    "fi": "Tammikuu",
    "is": "Janúar",
    "es": "Enero"
  },
  "februar": {
    "de": "Februar",
    "en": "February",
    "sk": "Február",
    "nl": "Februari",
    "ro": "Februarie",
    "cs": "Únor",
    "sl": "Februar",
    "fr": "Février",
    "fi": "Helmikuu",
    "is": "Febrúar",
    "es": "Febrero"
  },
  "märz": {
    "de": "März",
    "en": "March",
    "sk": "Marec",
    "nl": "Maart",
    "ro": "Martie",
    "cs": "Březen",
    "sl": "Marec",
    "fr": "Mars",
    "fi": "Maaliskuu",
    "is": "Mars",
    "es": "Marzo"
  },
  "april": {
    "de": "April",
    "en": "April",
    "sk": "Apríl",
    "nl": "April",
    "ro": "Aprilie",
    "cs": "Duben",
    "sl": "April",
    "fr": "Avril",
    "fi": "Huhtikuu",
    "is": "Apríl",
    "es": "Abril"
  },
  "mai": {
    "de": "Mai",
    "en": "May",
    "sk": "Máj",
    "nl": "Mei",
    "ro": "Mai",
    "cs": "Květen",
    "sl": "Maj",
    "fr": "Mai",
    "fi": "Toukokuu",
    "is": "Maí",
    "es": "Mayo"
  },
  "juni": {
    "de": "Juni",
    "en": "June",
    "sk": "Jún",
    "nl": "Juni",
    "ro": "Iunie",
    "cs": "Červen",
    "sl": "Junij",
    "fr": "Juin",
    "fi": "Kesäkuu",
    "is": "Júní",
    "es": "Junio"
  },
  "juli": {
    "de": "Juli",
    "en": "July",
    "sk": "Júl",
    "nl": "Juli",
    "ro": "Iulie",
    "cs": "Červenec",
    "sl": "Julij",
    "fr": "Juillet",
    "fi": "Heinäkuu",
    "is": "Júlí",
    "es": "Julio"
  },
  "august": {
    "de": "August",
    "en": "August",
    "sk": "August",
    "nl": "Augustus",
    "ro": "August",
    "cs": "Srpen",
    "sl": "Avgust",
    "fr": "Août",
    "fi": "Elokuu",
    "is": "Ágúst",
    "es": "Agosto"
  },
  "september": {
    "de": "September",
    "en": "September",
    "sk": "September",
    "nl": "September",
    "ro": "Septembrie",
    "cs": "Září",
    "sl": "September",
    "fr": "Septembre",
    "fi": "Syyskuu",
    "is": "September",
    "es": "Septiembre"
  },
  "oktober": {
    "de": "Oktober",
    "en": "October",
    "sk": "Október",
    "nl": "Oktober",
    "ro": "Octombrie",
    "cs": "Říjen",
    "sl": "Oktober",
    "fr": "Octobre",
    "fi": "Lokakuu",
    "is": "Október",
    "es": "Octubre"
  },
  "november": {
    "de": "November",
    "en": "November",
    "sk": "November",
    "nl": "November",
    "ro": "Noiembrie",
    "cs": "Listopad",
    "sl": "November",
    "fr": "Novembre",
    "fi": "Marraskuu",
    "is": "Nóvember",
    "es": "Noviembre"
  },
  "dezember": {
    "de": "Dezember",
    "en": "December",
    "sk": "December",
    "nl": "December",
    "ro": "Decembrie",
    "cs": "Prosinec",
    "sl": "December",
    "fr": "Décembre",
    "fi": "Joulukuu",
    "is": "Desember",
    "es": "Diciembre"
  },
  "custom_question_ppl_trainer": {
    "de": "PPL Trainer",
    "en": "PPL Trainer",
    "sk": "PPL Trainer",
    "nl": "PPL Trainer",
    "ro": "PPL Trainer",
    "cs": "PPL Trainer",
    "sl": "PPL Trainer",
    "fr": "PPL Trainer",
    "fi": "PPL Trainer",
    "is": "PPL Trainer",
    "es": "PPL Trainer"
  },
  "Important_information": {
    "de": "Kostenlos bis Jahresende*",
    "en": "Free until the end of the year*",
    "sk": "Bezplatne do konca roka*",
    "nl": "Gratis tot het einde van het jaar*",
    "ro": "Gratuit până la sfârșitul anului*",
    "cs": "Zdarma až do konce roku*",
    "sl": "Brezplačno do konca leta*",
    "fr": "Gratuit jusqu'à la fin de l'année*",
    "fi": "Ilmainen vuoden loppuun asti*",
    "is": "Ókeypis til loka ársins*",
    "es": "Gratis hasta fin de año*"
  },
  "only_x_days_left": {
    "de": "Noch {{x}} Tage!",
    "en": "Only {{x}} days left!",
    "sk": "Iba {{x}} dní zostáva!",
    "nl": "Nog {{x}} dagen over!",
    "ro": "Mai sunt doar {{x}} zile!",
    "cs": "Pouze {{x}} dní zbývá!",
    "sl": "Še {{x}} dni!",
    "fr": "Il reste seulement {{x}} jours !",
    "fi": "Vain {{x}} päivää jäljellä!",
    "is": "Aðeins {{x}} dagar eftir!",
    "es": "¡Solo quedan {{x}} días!"
  },
  "x_days_left_button": {
    "de": "{{x}} Tage kostenlos anfordern*",
    "en": "Request {{x}} days free*",
    "sk": "Požiadajte o {{x}} dní zdarma*",
    "nl": "Vraag {{x}} dagen gratis aan*",
    "ro": "Cere {{x}} zile gratuite*",
    "cs": "Požádejte o {{x}} dní zdarma*",
    "sl": "Zahtevajte {{x}} dni brezplačno*",
    "fr": "Demander {{x}} jours gratuits*",
    "fi": "Pyydä {{x}} päivää ilmaiseksi*",
    "is": "Panta {{x}} daga ókeypis*",
    "es": "Solicita {{x}} días gratis*"
  },
  "shop_info_3": {
    "de": "Sag uns noch deine E-Mail-Adresse. Wir melden uns bei dir!",
    "en": "Just leave us your email address, and we will contact you!",
    "sk": "Stačí nám zanechať svoju e-mailovú adresu a my sa vám ozveme!",
    "nl": "Laat ons gewoon je e-mailadres achter en wij nemen contact met je op!",
    "ro": "Lăsați-ne adresa dvs. de e-mail și vă vom contacta!",
    "cs": "Stačí nám zanechat váš e-mail a my se vám ozveme!",
    "sl": "Pustite nam svoj e-poštni naslov in kontaktirali vas bomo!",
    "fr": "Laissez-nous simplement votre adresse e-mail et nous vous contacterons !",
    "fi": "Jätä meille sähköpostiosoitteesi, niin otamme sinuun yhteyttä!",
    "is": "Láttu okkur vita af netfanginu þínu og við höfum samband við þig!",
    "es": "Déjanos tu dirección de correo electrónico y nos pondremos en contacto contigo."
  },
  "email-address": {
    "de": "E-Mail Adresse",
    "en": "Email Address",
    "sk": "E-mailová adresa",
    "nl": "E-mailadres",
    "ro": "Adresă de e-mail",
    "cs": "E-mailová adresa",
    "sl": "E-poštni naslov",
    "fr": "Adresse e-mail",
    "fi": "Sähköpostiosoite",
    "is": "Netfang",
    "es": "Dirección de correo electrónico"
  },
  "email_error": {
    "de": "Fehler beim Senden.",
    "en": "Error sending.",
    "sk": "Chyba pri odosielaní.",
    "nl": "Fout bij verzenden.",
    "ro": "Eroare la trimitere.",
    "cs": "Chyba při odesílání.",
    "sl": "Napaka pri pošiljanju.",
    "fr": "Erreur lors de l'envoi.",
    "fi": "Virhe lähetyksessä.",
    "is": "Villa við sendingu.",
    "es": "Error al enviar."
  },
  "thanks_for_register": {
    "de": "Vielen Dank für deine Anmeldung!",
    "en": "Thank you for registering!",
    "sk": "Ďakujeme za registráciu!",
    "nl": "Bedankt voor je registratie!",
    "ro": "Mulțumim pentru înregistrare!",
    "cs": "Děkujeme za registraci!",
    "sl": "Hvala za registracijo!",
    "fr": "Merci pour votre inscription!",
    "fi": "Kiitos rekisteröitymisestäsi!",
    "is": "Takk fyrir skráninguna!",
    "es": "¡Gracias por registrarte!"
  },
  "your_are_already_on_the_radar": {
    "de": "Du bist schon auf dem Radar - Wir melden uns in Kürze!",
    "en": "You're already on the radar - we'll get in touch soon!",
    "sk": "Už si na radarze - čoskoro sa ozveme!",
    "nl": "Je staat al op de radar - we nemen snel contact met je op!",
    "ro": "Ești deja pe radar - vom lua legătura cu tine în curând!",
    "cs": "Už jsi na radaru - brzy se ti ozveme!",
    "sl": "Že si na radarju - kmalu se bomo oglasili!",
    "fr": "Vous êtes déjà sur le radar - nous vous contacterons bientôt !",
    "fi": "Olet jo radarilla - otamme yhteyttä pian!",
    "is": "Þú ert þegar á radarinu - við höfum samband fljótlega!",
    "es": "¡Ya estás en el radar, nos pondremos en contacto pronto!"
  },
  "last_step": {
    "de": "Ein letzter Schritt",
    "en": "One last step",
    "sk": "Posledný krok",
    "nl": "Een laatste stap",
    "ro": "Un ultim pas",
    "cs": "Poslední krok",
    "sl": "Zadnji korak",
    "fr": "Une dernière étape",
    "fi": "Viimeinen askel",
    "is": "Síðasta skref",
    "es": "Un último paso"
  },
  "last_step_description": {
    "de": "Damit dein Account richtig eingerichtet werden kann, teile uns noch folgende Informationen mit:",
    "en": "To properly set up your account, please provide us with the following information:",
    "sk": "Aby bol váš účet správne nastavený, poskytnite nám nasledujúce informácie:",
    "nl": "Om je account correct in te stellen, deel je de volgende informatie met ons:",
    "ro": "Pentru a vă configura corect contul, vă rugăm să ne furnizați următoarele informații:",
    "cs": "Aby byl váš účet správně nastaven, poskytněte nám následující informace:",
    "sl": "Da bomo pravilno nastavili vaš račun, prosimo, da nam posredujete naslednje informacije:",
    "fr": "Pour configurer correctement votre compte, veuillez nous fournir les informations suivantes :",
    "fi": "Jotta voimme määrittää tilisi oikein, anna meille seuraavat tiedot:",
    "is": "Til að stilla reikninginn þinn rétt, vinsamlegast gefðu okkur eftirfarandi upplýsingar:",
    "es": "Para configurar correctamente su cuenta, por favor proporcione la siguiente información:"
  },
  "last_step_confirmation": {
    "de": "In {{x}} Tagen erhalte ich die Rechnung in Höhe von einmalig €{{y}},- für eine weitere Laufzeit von {{z}} Monaten. Kein Abo!",
    "en": "In {{x}} days, I will receive an invoice of €{{y}} one-time for an additional term of {{z}} months. No subscription!",
    "sk": "O {{x}} dní obdržím faktúru vo výške jednorazovo €{{y}} na ďalšie obdobie {{z}} mesiacov. Žiadne predplatné!",
    "nl": "Over {{x}} dagen ontvang ik een eenmalige factuur van €{{y}} voor een verdere looptijd van {{z}} maanden. Geen abonnement!",
    "ro": "În {{x}} zile voi primi o factură unică de €{{y}} pentru o durată suplimentară de {{z}} luni. Fără abonament!",
    "cs": "Za {{x}} dní obdržím jednorázovou fakturu ve výši €{{y}} za další období {{z}} měsíců. Žádné předplatné!",
    "sl": "V {{x}} dneh bom prejel enkraten račun v višini €{{y}} za nadaljnje obdobje {{z}} mesecev. Brez naročnine!",
    "fr": "Dans {{x}} jours, je recevrai une facture unique de €{{y}} pour une durée supplémentaire de {{z}} mois. Pas d'abonnement!",
    "fi": "{{x}} päivän kuluttua saan kertalaskun €{{y}} lisäajasta {{z}} kuukautta. Ei tilausta!",
    "is": "Eftir {{x}} daga mun ég fá einu sinni reikning upp á €{{y}} fyrir viðbótartímabil upp á {{z}} mánuði. Engin áskrift!",
    "es": "En {{x}} días, recibiré una factura única de €{{y}} por un período adicional de {{z}} meses. ¡Sin suscripción!"
  },
  "x_days_left_explanation": {
    "de": "*danach erhältst du eine einmalige Rechnung.",
    "en": "*after that, you will receive a one-time invoice.",
    "sk": "*potom obdržíte jednorazovú faktúru.",
    "nl": "*daarna ontvang je een eenmalige factuur.",
    "ro": "*după aceea, veți primi o factură unică.",
    "cs": "*poté obdržíte jednorázovou fakturu.",
    "sl": "*nato boste prejeli enkraten račun.",
    "fr": "*après cela, vous recevrez une facture unique.",
    "fi": "*sen jälkeen saat kertalaskun.",
    "is": "*eftir það færðu einu sinni reikning.",
    "es": "*después de eso, recibirá una factura única."
  },
  "shop_info_1": {
    "de": "Nutze den PPL Trainer bis Jahresende kostenlos. Im Januar erhältst du eine einmalige Rechnung für die von dir ausgewählte Laufzeit (Kein Abo).",
    "en": "Use the PPL Trainer for free until the end of the year. In January, you will receive a one-time invoice for the duration you selected (No subscription).",
    "sk": "Použite PPL Trainer zadarmo až do konca roka. V januári obdržíte jednorazovú faktúru za zvolené obdobie (Žiadne predplatné).",
    "nl": "Gebruik de PPL Trainer gratis tot het einde van het jaar. In januari ontvang je een eenmalige factuur voor de door jou gekozen looptijd (Geen abonnement).",
    "ro": "Utilizați PPL Trainer gratuit până la sfârșitul anului. În ianuarie veți primi o factură unică pentru durata selectată (Fără abonament).",
    "cs": "Použijte PPL Trainer zdarma až do konce roku. V lednu obdržíte jednorázovou fakturu za vámi zvolené období (Žádné předplatné).",
    "sl": "Uporabljajte PPL Trainer brezplačno do konca leta. Januarja boste prejeli enkraten račun za izbrano obdobje (Brez naročnine).",
    "fr": "Utilisez le PPL Trainer gratuitement jusqu'à la fin de l'année. En janvier, vous recevrez une facture unique pour la durée que vous avez sélectionnée (Pas d'abonnement).",
    "fi": "Käytä PPL Traineria ilmaiseksi vuoden loppuun asti. Tammikuussa saat kertalaskun valitsemallesi kestolle (Ei tilausta).",
    "is": "Notaðu PPL Trainer ókeypis til ársloka. Í janúar færðu einu sinni reikning fyrir það tímabil sem þú valdir (Engin áskrift).",
    "es": "Utilice el PPL Trainer gratis hasta fin de año. En enero recibirá una factura única por la duración seleccionada (Sin suscripción)."
  }
};

function t(key, lang) {
  lang = document.documentElement.lang;
  var el = keys[key] || {};
  var translation = el[lang];
  if (!translation) {
    return el["en"];
  }
  return translation; 
}

t.setLanguage = function (lang) {
  document.documentElement.lang = lang;

  var $body = $('body');
  $body.attr('class', $body.get(0).className.replace(/lang-\S+/g, ''));
  $body.addClass("lang-" + lang);
  localStorage.setItem("preferredLanguage", lang);

  $('[data-i18n]').each(function(index, element) {
    var key = element.getAttribute("data-i18n");
    var obj = t(key, lang);
    element.innerText = obj || key || ""; // fallback is key
  });
}

const localeMapping = {
  "de": "de_DE",
  "en": "en_US",
  "sk": "sk_SK",
  "nl": "nl_NL",
  "ro": "ro_RO",
  "cs": "cs_CZ",
  "sl": "sl_SI",
  "fr": "fr_FR",
  "fi": "fi_FI",
  "is": "is_IS",
  "es": "es_ES",
};

t.getCurrentLocale = function() {
  var language = document.documentElement.lang;
  return localeMapping[language] || 'en_US';
}

t.available_keys = JSON.parse(JSON.stringify(keys));;

window.setlanguage = t.setLanguage;

export default t;