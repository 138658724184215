import React, { Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import i18n from '../../../utils/i18n';

class GroupsFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: "",
    };
    this.update = this.update.bind(this);
  }

  update(options) {
    const { name, value } = options.target;
    const obj = {};
    obj[name] = value;
    this.setState(obj, () => {
      this.props.updateContent({data: this.state, id: 'group'});
    });
  }

  render() {
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_class")}</Modal.Header>
      <Modal.Description>
        <div>
          <div className='mm-description-item'>{i18n("create_new_class_hint")}</div>
        </div>
      </Modal.Description>
      <div className='mm-form-container'>
        <form>
          <div className='mm-form-item'>
            <Icon name="list" title={i18n("class")} />
            <input type="text" id="group" placeholder={i18n("class")} name="group" onChange={(options) => this.update(options)} />
          </div>
        </form>
      </div>  
    </Modal.Content>
  }
}

export default GroupsFormular;
