import React, { Component } from 'react';
import _ from 'underscore';
import { Icon, Modal, Dropdown, Checkbox } from 'semantic-ui-react';
import i18n from '../../../utils/i18n';

class StudentsFormular extends Component {
  constructor(props) {
    super(props);
    const firstGroup = (this.props.groups && !_.isEmpty(this.props.groups)) ? this.props.groups[0] : "";
    let atoFullName = "";
    try {
      atoFullName = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.ato_name;
    } catch (e) {}

    this.state = {
      firstname: "",
      lastname: "",
      name: "",
      email: "",
      group: firstGroup,
      isAdmin: false,
      atoFullName,
    };
    this.update = this.update.bind(this);
    this.noGroupsAvailable = this.noGroupsAvailable.bind(this);
  }

  update(options, data) {
    let name = options.target.name || (data && data.name);
    let value = options.target.value || (data && (data.value || data.checked));
    const obj = {};
    obj[name] = value;
    this.setState(obj, () => {
      const { firstname, lastname, email, group, isAdmin, atoFullName } = this.state;
      const fullName = firstname + " " + lastname;
      this.props.updateContent({
        data: {"student": { firstname, lastname, name: fullName, email, group, isAdmin, atoFullName }}, 
        id: 'student'});
    });
  }

  noGroupsAvailable() {
    return !this.props.groups || _.isEmpty(this.props.groups);
  }

  render() {
    if (this.noGroupsAvailable()) {
      alert(i18n("first_create_a_class"));
      this.props.onClose();
      return "";
    }
    const selectableGroups = _.map(this.props.groups, function(group) { 
      return {
        key: group,
        text: group,
        value: group,
      }
    });
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("link_flight_student")}</Modal.Header>
      <Modal.Description>
        <div>
          <div className='mm-description-item'>{i18n("link_flight_student_hint_1")}</div>
          <div className='mm-description-item'>{i18n("ppltrainer_account_is_required_by_students")}</div>
        </div>
      </Modal.Description>
      <div className='mm-form-container'>
        <form>
          <div className='mm-form-item'>
            <Icon name="user" title={i18n("provide_first_and_last_name")} />
            <input type="text" id="firstname" className='maxWidth-120' placeholder={i18n("firstName")} name="firstname" onChange={(options) => this.update(options)} />
            <input type="text" id="lastname" placeholder={i18n("lastName")} name="lastname" onChange={(options) => this.update(options)} />
          </div>

          <div className='mm-form-item'>
            <Icon name="mail" title={i18n("an_account_must_already_be_linked")} />
            <input type="email" id="email" placeholder={i18n("email")} name="email" onChange={(options) => this.update(options)}/><br/>
          </div>

          <div className='mm-form-item mm-form-item-additional-space'>
            <Icon name="list" title={i18n("in_which_class_a_user_should_be")} />
            <Dropdown
              placeholder={i18n("class")}
              fluid
              selection
              options={selectableGroups}
              defaultValue={selectableGroups[0].value}
              name="group" 
              onChange={(options, data) => this.update(options, data)}
            />
          </div>

          <div className='mm-form-item'>
            <Icon name="unlock" title={i18n("super_user")} />
            <Checkbox className='' id="isAdmin" name="isAdmin" toggle checked={this.state.isAdmin} onChange={(event, data) => this.update(event, data)} />
            <div className='description'>{i18n("admin_description_adding_student")}</div>
          </div>

        </form>
      </div>  
    </Modal.Content>
  }
}

export default StudentsFormular;
