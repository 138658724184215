import React, { Component } from 'react';
// import { Icon } from 'semantic-ui-react';

import i18n from "../utils/i18n";

class Attachements extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getDocuments(documents) {
    return documents.map((document) => {
      const isImage = document.fileType.startsWith('image/');
      const isPDF = document.fileType === 'application/pdf';
      const handleOpen = () => window.open(document.image, '_blank', 'noopener,noreferrer');
  
      return (
        <div key={document.id} onClick={handleOpen} className="attachement-item">
          {isImage && <img src={document.image} alt={document.name} />}
          {isPDF && (
            <div className='attachement-iframe-container'>
              <iframe src={document.image} title={document.name} />
              <div className='iframe-position-fix' />
            </div>
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <div className='question-attachement-container'>
        <h4>{i18n("attachments")}</h4>
        <div className='attachments-list'>
          { this.getDocuments(this.props.documents) }
        </div>
      </div>
    );
  }
}

export default Attachements;
