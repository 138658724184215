import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';

import Main from './components/Main';
import RequestLoginData from './components/RequestLoginData';
import Login from './components/auth/Login';
import Survey from './components/survey/Survey';

import './landingpage/css/bootstrap.min.css';
import './landingpage/css/all.css';
import './landingpage/css/style.css';

const OKTA_ENDPOINT = 'https://dev-911881.okta.com/oauth2/default';

function onAuthRequired({ history }) {
  history.push('/login');
}

const config = {
  issuer: OKTA_ENDPOINT,
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: "0oaaqpqnpdEIpOWZp357"
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const params = new URLSearchParams(window.location.search);
    const purchaseSchoolRef = params.get('purchase_school_ref');
    if (purchaseSchoolRef) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      document.cookie = `purchase_school_ref=${purchaseSchoolRef}; expires=${expirationDate.toUTCString()}; path=/`;
    }
  }

  render() {
    return (
      <Router>
        <Security
          issuer={config.issuer}
          client_id={config.clientId}
          redirect_uri={config.redirectUri}
          onAuthRequired={onAuthRequired}
        >
          <SecureRoute path="/" exact={true} component={Main} /> 
          <Route
            path="/login"
            render={(location, history) => (
              <div>
                <Login baseUrl="https://dev-911881.okta.com" />
                <RequestLoginData location={location} history={history} />
              </div>
            )}
          />
          <Route path="/successful" exact={true} component={Survey} />
          <Route path="/implicit/callback" component={ImplicitCallback} />
        </Security>
      </Router>
    );
  }
}

export default App;
