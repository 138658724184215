import axios from 'axios';

  class API {

    static DEFAULT_USER_URL = process.env.DEFAULT_USER_URL;

    static getData(endpoint) {
      return new Promise((resolve, reject) => {
        axios.get(API.DEFAULT_USER_URL + endpoint)
          .then((response) => {
            resolve(response && response.data);
          })
      }); 
    }

    static createData(data, endpoint, ato) {
      return new Promise((resolve, reject) => {
        if (ato) { data.ato = ato; }
        axios.post(API.DEFAULT_USER_URL + endpoint, data)
        .then((response) => {
          resolve(response && response.data);
        })
      })
    }

    static updateData(data, endpoint, ato) {
      return new Promise((resolve, reject) => {
        if (ato) { data.ato = ato; }
        axios.post(API.DEFAULT_USER_URL + endpoint, data)
        .then((response) => {
          resolve(response && response.data);
        })
      })
    }

    static getATOData() {
      const endpoint = "/ato/data";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static getCategories() {
      const endpoint = "/ato/categories";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createCategory(data) {
      const endpoint = "/ato/category";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteCategory(category) {
      const endpoint = "/ato/category/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "category": category })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getGroups() {
      const endpoint = "/ato/groups";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createGroup(data) {
      const endpoint = "/ato/group";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteGroup(group) {
      const endpoint = "/ato/group/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "group": group })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getQuestions() {
      const endpoint = "/ato/questions";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createQuestion(data) {
      const endpoint = "/ato/question";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static imageUpload(image) {
      const endpoint = "/ato/question/image";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, image, {
          headers: { 'Content-Type': `undefined` },
          timeout: 120000 
        })
        .then((response) => {
          resolve(response && response.data);
        })
      })
    }

    static updateQuestion(data) {
      const endpoint = "/ato/question/update";
      return new Promise((resolve, reject) => {
        const id = data.question.id;
        const question = data.question;
        if (!id) {
          console.warn("id is undefined", data);
          return;
        }
        axios.post(API.DEFAULT_USER_URL + endpoint, { "question": question, "id": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static deleteQuestion(question) {
      const endpoint = "/ato/question/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "question": question })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getScenarios() {
      const endpoint = "/ato/scenarios";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createScenario(data) {
      const endpoint = "/ato/scenario";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static updateScenario(data) {
      const endpoint = "/ato/scenario/update";
      return new Promise((resolve, reject) => {
        const id = data.scenario.id;
        const scenario = data.scenario;
        if (!id) {
          console.warn("id is undefined", data);
          return;
        }
        axios.post(API.DEFAULT_USER_URL + endpoint, { "scenario": scenario, "id": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static deleteScenario(id) {
      const endpoint = "/ato/scenario/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "scenario": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }
    
    static getAnswers() {
      const endpoint = "/ato/answers";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createAnswer(data) {
      const endpoint = "/ato/answer";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static updateAnswer(data) {
      const endpoint = "/ato/answer/update";
      return new Promise((resolve, reject) => {
        const id = data.answer.id;
        const customText = data.answer.customText;
        const lang = data.answer.language;
        const image = data.answer.image;
        if (!id) {
          console.warn("id is undefined", data);
          return;
        }
        axios.post(API.DEFAULT_USER_URL + endpoint, { "customText": customText, "id": id, "image": image, language: lang })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static deleteAnswer(question) {
      const endpoint = "/ato/answer/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "question": question })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getDocument() {
      const endpoint = "/ato/document";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createDocument(data) {
      const endpoint = "/ato/document";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static uploadDocument(image) {
      const endpoint = "/ato/document/upload";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, image, {
          headers: { 'Content-Type': `undefined` },
          timeout: 120000 
        })
        .then((response) => {
          resolve(response && response.data);
        })
      })
    }

    static updateDocument(data) {
      const endpoint = "/ato/document/update";
      return new Promise((resolve, reject) => {
        const id = data.document.id;
        const document = data.document;
        if (!id) {
          console.warn("id is undefined", data);
          return;
        }
        axios.post(API.DEFAULT_USER_URL + endpoint, { "document": document, "id": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static deleteDocument(id) {
      const endpoint = "/ato/document/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "document": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getPublicAnswers() {
      const endpoint = "/ato/public/answers";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createPublicAnswer(data) {
      const endpoint = "/ato/public/answer";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deletePublicAnswer(answer) {
      const endpoint = "/ato/public/answer/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "answer": answer })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getStudentInsights(studentid) {
      const endpoint = "/ato/student/insights";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "studentid": studentid })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getStudents() {
      const endpoint = "/ato/students";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createStudent(data) {
      const endpoint = "/ato/student";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteStudent(id) {
      const endpoint = "/ato/student/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "student": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static updateStudent(data) {
      const endpoint = "/ato/student/update";
      return new Promise((resolve, reject) => {
        API.updateData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static getHomework() {
      const endpoint = "/ato/homework";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static getHomeworkStatus(data) {
      const endpoint = "/ato/homework/details";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createHomework(data) {
      const endpoint = "/ato/homework";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteHomework(id) {
      const endpoint = "/ato/homework/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "homework": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static getExam() {
      const endpoint = "/ato/exam";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static getExamStatus(data) {
      const endpoint = "/ato/exam/details";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createExam(data) {
      const endpoint = "/ato/exam";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteExam(id) {
      const endpoint = "/ato/exam/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "exam": id })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    // Details Archvive
    static getExamArchive() {
      const endpoint = "/ato/archive/exam";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    // Details Archvive
    static getHomeworkArchive() {
      const endpoint = "/ato/archive/homework";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    // Manage ATOs

    static getATOs() {
      const endpoint = "/admin/ato/list";
      return new Promise((resolve, reject) => {
        API.getData(endpoint).then((response) => {
          resolve(response);
        });
      })
    }

    static createATOCategory(data, ato) {
      const endpoint = "/admin/ato/category";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }
    
    static deleteATOCategory(category, ato) {
      const endpoint = "/admin/ato/category/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "category": category, ato })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static createATOAccount(data, ato) {
      const endpoint = "/admin/ato/create";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }

    static createATOQuestion(data, ato) {
      const endpoint = "/admin/ato/question";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }

    static updateATOQuestion(data, ato) {
      const endpoint = "/admin/ato/question/update";
      return new Promise((resolve, reject) => {
        API.updateData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }
    
    static deleteATOQuestion(question, ato) {
      const endpoint = "/admin/ato/question/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "question": question, ato })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static createATOGroup(data, ato) {
      const endpoint = "/admin/ato/group";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteATOGroup(group, ato) {
      const endpoint = "/admin/ato/group/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "group": group, ato })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

    static createATOStudent(data, ato) {
      const endpoint = "/admin/ato/student";
      return new Promise((resolve, reject) => {
        API.createData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }
    
    static updateATOStudent(data, ato) {
      const endpoint = "/admin/ato/student/update";
      return new Promise((resolve, reject) => {
        API.updateData(data, endpoint, ato).then((response) => {
          resolve(response);
        });
      })
    }

    static deleteATOStudent(id, ato) {
      const endpoint = "/admin/ato/student/delete";
      return new Promise((resolve, reject) => {
        axios.post(API.DEFAULT_USER_URL + endpoint, { "student": id, ato })
        .then((response) => {
          resolve(response.data);
        })
      })
    }

  }
  
  export default API;