import React, { Component } from 'react';
import { Input, Icon } from 'semantic-ui-react'

import Loading from './Loading';

import AdvancedSettings from './AdvancedSettings';

import i18n from "../utils/i18n";
import _ from 'underscore';

class SetATODetails extends Component {
  constructor(props) {
    super(props);

    var userinfo = this.props.userinfo;
    const atoName = (userinfo && userinfo.idToken && userinfo.idToken.claims && userinfo.idToken.claims.ato_name) || "ATO";

    this.state = {
      atoName: atoName,
      firstname: null,
      lastname: null,
      details: null,
      showOverlay: false,
      advanced_settings: false,
      question_count: 16,
      question_count_radio: 16
    };

    this.start = this.start.bind(this);
    this.continueExam = this.continueExam.bind(this);
    this.toggleAdvancedSettings = this.toggleAdvancedSettings.bind(this);
    this.printExam = this.printExam.bind(this);
    this.unsetSessionExamLocalStorageAndStoreUserData = this.unsetSessionExamLocalStorageAndStoreUserData.bind(this);
    this.onUpdateQuestionsCount = this.onUpdateQuestionsCount.bind(this);
  }

  componentDidMount() {
    const runningExam = JSON.parse(localStorage.getItem("ppltrainer_exam_session"));
    const that = this;
    if (runningExam && !_.isEmpty(runningExam)) {
      window.setTimeout(function() {
        that.setState({ showOverlay: true });
      }, 500);
    }
  }

  start() {
    if (!this.state.firstname || !this.state.lastname) return;
    this.unsetSessionExamLocalStorageAndStoreUserData();
    this.props.onChange({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      details: this.state.details,
      totalCount: this.state.question_count,
      totalCountRadio: this.state.question_count_radio,
    });
  }

  printExam() {
    this.props.onChange({
      printNewExam: true,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      details: this.state.details,
      totalCount: this.state.question_count,
      totalCountRadio: this.state.question_count_radio,
    });
  }

  unsetSessionExamLocalStorageAndStoreUserData() {
    localStorage.setItem("ppltrainer_exam_session", "{}");

    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      details: this.state.details,
      totalCount: this.state.question_count,
      totalCountRadio: this.state.question_count_radio,
    }
    localStorage.setItem('ppltrainer_exam_session_user', JSON.stringify(data));
  }

  continueExam() {
    // TODO go on here !!!!
    const user = JSON.parse(localStorage.getItem('ppltrainer_exam_session_user'));
    if (!user) alert("Cannot restore last exam. Please contact your admin and start a new exam.");
    const firstname = user.firstname;
    const lastname = user.lastname;
    const details = user.details;
    const totalCount = user.totalCount;
    this.props.onChange({
      firstname,
      lastname,
      details,
      totalCount,
    });
  }

  examSessionIsInProgress() {
    const exam = localStorage.getItem("ppltrainer_exam_session");
    return exam && !_.isUndefined(exam);
  }

  toggleAdvancedSettings() {
    this.setState({
      advanced_settings: !this.state.advanced_settings
    });
  }

  onUpdateQuestionsCount(options) {
    options = options || {};
    this.setState(options);
  }

  render() {
    // TODO if ppltrainer_exam_session is not null - restore firstname, lastname, details
    if (this.state.isLoading) return <Loading />
    return (
      <div className="exam-detail-container">
        <div className="quiz-question-header">
          <label>{this.state.atoName}<br/></label>
        </div>
        <div className="text-center">
          <div className="bold big">{i18n("flightstudent_details")}</div>
          <div><small>{i18n("is_used_for_exam_sheet")}</small></div>
        </div>
        
        <div>
          <Input placeholder={i18n("firstName")} fluid onChange={(event) => this.setState({firstname: event.target.value})} />
          <Input placeholder={i18n("lastName")} fluid onChange={(event) => this.setState({lastname: event.target.value})} />
          <Input placeholder={i18n("details")} fluid onChange={(event) => this.setState({details: event.target.value})} />

          <div>
            <button className="smaller-content-btn btn-second" onClick={this.toggleAdvancedSettings}>{i18n("advanced_settings")} {this.state.advanced_settings ? <Icon name="angle up"></Icon> : <Icon name="angle down"></Icon>}</button>
          </div>

          {
            this.state.advanced_settings && 
              <AdvancedSettings
                onChange={this.onUpdateQuestionsCount}
                question_count={this.state.question_count}
                question_count_radio={this.state.question_count_radio}
              />
          }
          
          <div className="start-btn">
            <button className="ui fluid btn-primary" onClick={this.start}>{i18n("next")}</button>
          </div>

          <div className='start-exam-btn'>
            <button className="btn-second printNewExamBtn" onClick={e => this.printExam()} >
              <Icon name="print" /> {i18n("printExamtoPDF")}
            </button>
          </div>

        </div>
        {
          this.examSessionIsInProgress() ?
            <div className={`overlay-apple-design ${this.state.showOverlay ? "active" : ""}`}>
              <div className="bold big">{i18n("examFound")}</div>
              <div className="mt-20">{i18n("continueOrStartNewExam")}</div>
              <div className="Overview actions-container">
                <button className="btn-primary" onClick={this.continueExam}>{i18n("continueExam")}</button>
                <button className="btn-second" onClick={() => this.setState({showOverlay: false})} >{i18n("startNewExam")}</button>
              </div>
            </div>
          :
          null
        }
      </div>
    );
  }
}

export default SetATODetails;
