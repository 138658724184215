import React, { Component } from 'react';
import Loader from './Loader';
import Questions from './results/Questions';

import i18n from '../../utils/i18n';
import _ from 'underscore';

import './manager.css';

class SearchContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      foundedElements: [],
      minimumWarning: true
    };

    this.isLoading = this.isLoading.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.close = this.close.bind(this);
  }

  isLoading() { return this.state.loading }

  componentDidMount() {
    this.fetchData(this.props.searchTerm);
  }

  componentDidUpdate(prevProps) {
    const that = this;
    const term = this.props.searchTerm;
    if (this.props.searchTerm !== prevProps.searchTerm && term) {
      this.setState({ 
        loading: true,
        foundedElements: [],
      });
      that.fetchData(term);
    }
  }

  fetchData(term) {
    // fetch data (person, questions, categories, old question)
    const foundedElements = [];
    for (let key in this.props.questions) {
      _.each(this.props.questions[key], (item) => {
        _.each(item.data, function(element) {
          let question = element.question || element["Question"] || "";
          let questionID = element["Question ID"] || "";
          let questionItem = question.match(new RegExp(term, 'i')) || questionID.match(new RegExp(term, 'i'));
          if (questionItem) {
            foundedElements.push(element);
          }
        });
      });
    }

    this.setState({
      loading: false,
      foundedElements,
    });
  }

  close() {
    this.props.onClose();
  }

  render() {
    if (!this.props.searchTerm) return null;
    return <div className='search-result-container'>
      {
        this.isLoading() ? 
          <Loader />
        : <div>
            <div className='result-header'>{i18n("search_results")}: {this.state.foundedElements.length}</div>
            <div onClick={this.close} className="close-action-box">X</div>
            <Questions elements={this.state.foundedElements} />
          </div>
      }
    </div>
  }
}

export default SearchContainer;
