import React, { Component } from 'react';
import { Table, Checkbox, Select } from 'semantic-ui-react';

import moment from 'moment';

import _ from 'underscore';
import { Icon } from 'semantic-ui-react'
import i18n from "../utils/i18n";
import Loading from './Loading';

import TableList from '../components/manager/TableList';
import CreateItemModal from '../components/manager/CreateItemModal';

import StudentFormular from '../components/manager/formular/StudentFormular';
import StudentsFormular from '../components/manager/formular/StudentsFormular';
import GroupsFormular from '../components/manager/formular/GroupsFormular';
import CategoriesFormular from '../components/manager/formular/CategoriesFormular';
import QuestionsFormular from '../components/manager/formular/QuestionsFormular';
import HomeworkFormular from '../components/manager/formular/HomeworkFormular';
import ExamFormular from '../components/manager/formular/ExamFormular';
import ATOFormular from '../components/manager/formular/ATOFormular';

import API from '../utils/resources/ato.js';
import helper from '../utils/ato/helper.js'

class ManageFlightschool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      atos: null,
      selectedATO: null,
    };

    this.isLoading = this.isLoading.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getATOName = this.getATOName.bind(this);
    this.onSelectATO = this.onSelectATO.bind(this);
    this.createNewTask = this.createNewTask.bind(this);
    this.getATOsAsOptions = this.getATOsAsOptions.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.renderAvailableCategories = this.renderAvailableCategories.bind(this);
    this.updateQuestionState = this.updateQuestionState.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.setSelectedATO = this.setSelectedATO.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
    this.renderAvailableQuestions = this.renderAvailableQuestions.bind(this);
    this.getUnverifiedStudentItem = this.getUnverifiedStudentItem.bind(this);
    this.getStudentDetails = this.getStudentDetails.bind(this);
    this.updateStudentState = this.updateStudentState.bind(this);
    this.renderAvailableGroups = this.renderAvailableGroups.bind(this);
    this.renderAvailableHomework = this.renderAvailableHomework.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.toggleCategoryVisibility = this.toggleCategoryVisibility.bind(this);
    this.toggleStudentsVisibility = this.toggleStudentsVisibility.bind(this);
  }

  isLoading() { return this.state.loading }
  setLoading() { this.setState({loading: true}) }
  unsetLoading() { this.setState({loading: false}) }
  getATOName() { return this.props.userinfo.idToken.claims.ato_name }

  componentDidMount() {
    this.fetchData();
  }

  createNewTask(id) {
    let formular = null;
    switch (id) {
      case 'groups': 
        formular = GroupsFormular;
        break;
      case 'student': 
        formular = StudentFormular;
        break;
      case 'students': 
        formular = StudentsFormular;
        break;
      case 'categories': 
        formular = CategoriesFormular;
        break;
      case 'questions': 
        formular = QuestionsFormular;
        break;
      case 'homework': 
        formular = HomeworkFormular;
        break;
      case 'exam': 
        formular = ExamFormular;
        break;
      case 'ato': 
        formular = ATOFormular;
        break;
      default:
        formular = null;
    }
    this.setState({ 
      openModal: true,
      formular,
    });
  }

  fetchData() {
    const that = this;
    const data = API.getATOs();
    data.then(function(response) {
      const { atos } = response;
      that.setState({
        atos,
      })
      if (that.state.selectedATOID) {
        that.setSelectedATO(that.state.selectedATOID);
      }
    });
  }

  getATOsAsOptions() {
    return _.map(this.state.atos, function(ato, index) {
      return { key: index, value: index, text: index };
    });
  }

  onSelectATO(target, options) {
    this.setSelectedATO(options.value);
  }

  setSelectedATO(id) {
    const ato = this.state.atos[id];
    const { categories, questions, students, groups, homework } = ato;
    this.setState({
      selectedATO: ato,
      selectedATOID: id,
      categories,
      questions,
      students,
      groups,
      homework,
    });
  }

  deleteCategory(category) {
    const that = this;
    API.deleteATOCategory(category, this.state.selectedATOID).then((categories) => {
      // that.setState({ categories });
      that.fetchData();
    });
  }

  renderAvailableCategories() {
    const that = this;
    if (_.isEmpty(this.state.categories)) return null;
    return _.map(this.state.categories, function(entry, index) {
      const translation = i18n(entry) || entry;
      return <Table.Row key={index}>
              <Table.Cell className="max-width">{translation}</Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteCategory(entry)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }
  
  updateQuestionState(event, data, entry) {
    const that = this;
    const { checked } = data;
    let hidden = true;
    if (checked === false) {
      hidden = false;
    }
    entry.hidden = hidden;
    API.updateATOQuestion({"question": entry, ato: this.state.selectedATOID}).then((questions) => {
      // that.setState({ questions });
      that.fetchData();
    });
  }

  deleteQuestion(question) {
    const that = this;
    API.deleteATOQuestion(question, this.state.selectedATOID).then((questions) => {
      // that.setState({ questions });
      that.fetchData();
    });
  }

  imageToUpload(data) {
    return data && data.question && data.question.image;
  }

  onSaveData(options) {
    const that = this;
    const { data, id } = options.data;
    const endpoint = 'createATO' + helper.capitalize(id);
    if (this.imageToUpload(data)) {
      API.imageUpload(data.question.image).then((imageURL) => {
        data.question.image = imageURL;
        const image = imageURL[0];
        data.image = image;
        that.saveData(endpoint, data);
      });
    } else {
      this.saveData(endpoint, data);
    }
  }

  saveData(endpoint, data) {
    const that = this;
    console.log(endpoint)
    API[endpoint](data, this.state.selectedATOID).then(() => {
      that.fetchData();
      that.onCloseModal();
    })
  }

  onCloseModal() {
    this.setState({
      openModal: false,
      formular: null,
      formularOptions: null,
    });
  }

  toggleCategoryVisibility(category) {
    this.setState((prevState) => ({
      openCategory: prevState.openCategory === category ? null : category,
    }));
  }

  renderAvailableQuestions() {
    const that = this;
    if (_.isEmpty(this.state.questions)) return null;

    const groupedQuestions = _.groupBy(this.state.questions, 'category');

    return Object.keys(groupedQuestions).map((category) => {
      const questionsInCategory = groupedQuestions[category];
      const questionCount = questionsInCategory && questionsInCategory.length;
      const thisCategoryIsOpen = this.state.openCategory === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";

      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleCategoryVisibility(category)}>
              {i18n(category) || category} <span>({questionCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.openCategory === category && questionsInCategory.map((entry, index) => {
            const { hidden, question, answer1, answer2, answer3, answer4 } = entry;
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <Checkbox toggle checked={hidden} onChange={(event, data) => that.updateQuestionState(event, data, entry)} />
                </Table.Cell>
                <Table.Cell>{i18n(category) || category}</Table.Cell>
                <Table.Cell className="fbc">
                  {question}
                  {entry.image ? <img className='custom-image-preview' src={entry.image} alt='custom' /> : null}
                </Table.Cell>
                <Table.Cell className='answers'>
                  <div className='correct-answer'>{answer1}</div>
                  <div>{answer2}</div>
                  <div>{answer3}</div>
                  <div>{answer4}</div>
                </Table.Cell>
                <Table.Cell className="dyn-width action-content action-content-question">
                  <div className="single-action" onClick={() => that.deleteQuestion(entry)}>{i18n('delete')}</div>
                  <div className="single-action" onClick={() => that.updateTaskFormular("questions", entry)}>{i18n('edit')}</div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  isVerifiedStudent(entry) {
    return entry && entry.verified === true;
  }

  getUnverifiedStudentItem(entry) {
    if (!this.isVerifiedStudent()) {
      return <small className='color-red'><b> ({i18n("not_verified")})</b></small>;
    }
    return "";
  }

  shouldHideSaveAction() {
    const that = this;
    const hiddenFormulars = [
      StudentFormular,
      ExamFormular,
    ];

    return _.find(hiddenFormulars, function(formular) {
      return formular === that.state.formular;
    });
  }

  toggleStudentsVisibility(category) {
    this.setState((prevState) => ({
      studentCategory: prevState.studentCategory === category ? null : category,
    }));
  }

  renderAvailableStudents() {
    const that = this;
    if (_.isEmpty(this.state.students)) return null;

    const groupedStudents = _.groupBy(this.state.students, 'group');

    return Object.keys(groupedStudents).map((category) => {
      const studentsInCategory = groupedStudents[category];
      const stundentCount = studentsInCategory && studentsInCategory.length;
      const thisCategoryIsOpen = this.state.studentCategory === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";

      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleStudentsVisibility(category)}>
              {i18n(category) || category} <span>({stundentCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.studentCategory === category && studentsInCategory.map((entry, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                <Checkbox className='no-spaces' toggle checked={entry.isAdmin} onChange={(event, data) => that.updateStudentState(event, data, entry)} />
              </Table.Cell>
              <Table.Cell className={`max-width ${that.isVerifiedStudent(entry) && "cursor-pointer"}`} title={entry.name} onClick={() => that.getStudentDetails(entry)}>{that.isVerifiedStudent(entry) && <Icon name="external" />} {entry.name}</Table.Cell>
              <Table.Cell className={`max-width ${that.isVerifiedStudent(entry) && "cursor-pointer"}`} title={entry.email} onClick={() => that.getStudentDetails(entry)}>
                {entry.email}
                { !that.isVerifiedStudent(entry) ? that.getUnverifiedStudentItem(entry) : '' }
              </Table.Cell>
              <Table.Cell className="dyn-width" title={entry.group}>{entry.group}</Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteStudent(entry.id)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  getStudentDetails(student) {
    if (!this.isVerifiedStudent(student)) return;
    this.setState({ formularOptions: { student }})
    this.createNewTask("student");
  }

  deleteStudent(id) {
    const that = this;
    API.deleteATOStudent({id, ato: this.state.selectedATOID}).then((students) => {
      // that.setState({ students });
      that.fetchData();
    });
  }

  updateStudentState(event, data, entry) {
    const that = this;
    const { checked } = data;
    entry.isAdmin = checked;
    API.updateATOStudent({"student": entry, ato: this.state.selectedATOID}).then((students) => {
      that.fetchData();
    });
  }

  getHomeworkCategorieTranslations(categories) {
    return _.map(categories, function(category) {
      return i18n(category) || category;
    }).join(", ");
  }

  getReadableDate(date) {
    return moment(date).format("DD. MMM YYYY");
  }

  getHomeworkGroup(homework) {
    return homework.group || _.map(homework.selectedStudents, function (student) { return student }).join(', ') || '';
  }

  renderAvailableHomework() {
    const that = this;
    if (_.isEmpty(this.state.homework)) return null;
    return _.map(this.state.homework, function(entry, index) {
      return <Table.Row key={index}>
              <Table.Cell className={`max-width`} title={that.getHomeworkGroup(entry)}>{that.getHomeworkGroup(entry)}</Table.Cell>
              <Table.Cell className={`max-width`} title={that.getHomeworkCategorieTranslations(entry.selectedCategories)}>
                {that.getHomeworkCategorieTranslations(entry.selectedCategories)}
              </Table.Cell>
              <Table.Cell className="dyn-width" title={that.getReadableDate(entry.enddate)}>{that.getReadableDate(entry.enddate)}</Table.Cell>
              {/* <Table.Cell className="dyn-width action-content" onClick={() => that.deleteHomework(entry.id)}>{i18n('delete')}</Table.Cell> */}
            </Table.Row>
    });
  }

  renderAvailableGroups() {
    const that = this;
    if (_.isEmpty(this.state.groups)) return null;
    return _.map(this.state.groups, function(entry, index) {
      return <Table.Row key={index}>
              <Table.Cell className="max-width">{entry}</Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteGroup(entry)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }

  deleteGroup(group) {
    const that = this;
    API.deleteATOGroup(group, this.state.selectedATOID).then((groups) => {
      // that.setState({ categories });
      that.fetchData();
    });
  }
  
  render() {
    if (this.isLoading()) return <Loading />;
    return <div className='manage-flightsschool-container Overview clearfix'>
      <div className='header-information'>
        <label><b>{this.getATOName()}</b></label>
      </div>
      <div className='manage-flightschool-inner-container create-flightschool-container'>

      <div className='fbc'>
        <button className='btn-second fbf' onClick={this.props.onBack}>{i18n("back")}</button>
      </div>

      <div className='mng-headline'>
        <label>{i18n('manage_all_atos')}</label>
        <span>{i18n('here_you_get_all_ato_details')}</span>
      </div>

      <div className='content-item mt-15 mb-20'>
        <div className=''>
          {i18n('select_ato')} <button onClick={() => this.createNewTask('ato')} className='btn-second'>{i18n('create_new_ato')}</button>
        </div>
        { !_.isEmpty(this.state.atos) &&  <Select className="mt-5 select-ato-from-list" placeholder={i18n('select_ato')} options={this.getATOsAsOptions()} onChange={this.onSelectATO} /> }
      </div>

      {
        this.state.selectedATO && <div>

        <TableList
          tableClass="custom-table"
          headlineText={i18n('all_current_homeworks')}
          headerActionText={i18n('create_homework')}
          onHeaderAction={id => this.createNewTask('homework')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('flightstudent')}/{i18n('class')}</Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('kategorien')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width">{i18n('due_to')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableHomework() }
          emptyDataValue={i18n('no_homework_available')}
        />

           <TableList
            tableClass="custom-table"
            headlineText={i18n('flightschool_classes')}
            headerActionText={i18n('add_student_group')}
            onHeaderAction={id => this.createNewTask('groups')}
            tableHeaderRow={
              <Table.Row>
                <Table.HeaderCell className="max-width">{i18n('class')}</Table.HeaderCell>
                <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
              </Table.Row>
            }
            tableBody={ this.renderAvailableGroups() }
            emptyDataValue={i18n('no_groups_available')}
          />

          <TableList
            tableClass="custom-table"
            headlineText={i18n('all_flight_students')}
            headerActionText={i18n('create_flightstudent')}
            onHeaderAction={id => this.createNewTask('students')}
            headerActionIcon="info circle"
            headerActionIconInfo={i18n('add_student_with_license')}
            tableHeaderRow={
              <Table.Row>
                <Table.HeaderCell className="hidden-field-item">{i18n('super_user')} <Icon className='' name="info circle" title={i18n('this_is_a_super_user')} /></Table.HeaderCell>
                <Table.HeaderCell className="max-width">{i18n('flightstudent')}</Table.HeaderCell>
                <Table.HeaderCell className="max-width">{i18n('email')}</Table.HeaderCell>
                <Table.HeaderCell className="dyn-width">{i18n('class')}</Table.HeaderCell>
                <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
              </Table.Row>
            }
            tableBody={ this.renderAvailableStudents() }
            emptyDataValue={i18n('no_students_available')}
          />
          
          <TableList
            tableClass="custom-table"
            headlineText={i18n('category_count')}
            headerActionText={i18n('add_category')}
            onHeaderAction={id => this.createNewTask('categories')}
            tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('category')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
            }
            tableBody={ this.renderAvailableCategories() }
            emptyDataValue={i18n('no_categorys_available')}
          />

          <TableList
            tableClass="fixed collapsing fullWidth"
            headlineText={i18n('questions')}
            headerActionText={i18n('add_question')}
            onHeaderAction={id => this.createNewTask('questions')}
            tableHeaderRow={
              <Table.Row>
                <Table.HeaderCell className="hidden-field-item">{i18n('hidden')} <Icon className='' name="info circle" title={i18n('show_this_question_in_ato_only')} /></Table.HeaderCell>
                <Table.HeaderCell>{i18n('category')}</Table.HeaderCell>
                <Table.HeaderCell>{i18n('question')}</Table.HeaderCell>
                <Table.HeaderCell>{i18n('answers')} <Icon name="info circle" title={i18n('first_answer_in_green_is_correct')} /></Table.HeaderCell>
                <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
              </Table.Row>
            }
            tableBody={ this.renderAvailableQuestions() }
            emptyDataValue={i18n('no_questions_available')}
          />

        </div>
      }

        <CreateItemModal
          open={this.state.openModal}
          hideSaveAction={this.shouldHideSaveAction()}
          onClose={this.onCloseModal}
          onSave={this.onSaveData}
          categories={this.state.categories}
          groups={this.state.groups}
          students={this.state.students}
          formular={this.state.formular}
          formularOptions={this.state.formularOptions}
        />

      </div>
    </div>
  }
}

export default ManageFlightschool;
