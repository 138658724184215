import React, { Component } from 'react';
import _ from 'underscore';

class Questions extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.collectAnswers = this.collectAnswers.bind(this);
  }

  collectAnswers(item) {
    const answers = [];
    const correctAnswer = item[item["Correct"]];
    answers.push(item["A"]);
    answers.push(item["B"]);
    answers.push(item["C"]);
    answers.push(item["D"]);
    const index = answers.indexOf(correctAnswer);
    if (index !== -1) {
      answers.splice(index, 1);
      answers.unshift(correctAnswer);
    }
    return answers;
  }

  render() {
    if (!this.props.elements || !this.props.elements.length) return null;
    const that = this;
    return <div className='result-content'>
      <ul>
        {
          _.map(this.props.elements, function (item, index) {
            let question = item.question || item["Question"] || "";
            let questionID = item["Question ID"] || index;
            const correctAnswerIndex = item["correctAnswer"] || 0;
            const answers = item.answers || that.collectAnswers(item);
            return <li key={index + "_" + questionID} className="question-answer-item">
              <div className='question-item'>{question} <small>({questionID})</small></div>
              <div className='answers-containers'>{_.map(answers, function(answer, index) {
                return <span key={index + "_" + questionID} className={`${index === correctAnswerIndex ? 'correctAnswer' : ''}`}>{answer}</span>
              })}</div>
            </li>;
          })
        }
      </ul>
    </div>
  }
}

export default Questions;
