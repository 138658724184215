import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';

import i18n from "../../utils/i18n";

class TableList extends Component {

  render() {
    return <div className='content-item'>
      {
        this.props.headlineText &&
          <div className='content-item-headline'>
            {this.props.headlineText} <button onClick={this.props.onHeaderAction} className='btn-second'>{this.props.headerActionText}</button> <Icon name={this.props.headerActionIcon} title={this.props.headerActionIconInfo}/>
            {this.props.archive && <span onClick={this.props.archive} className='headline-archive'>{this.props.archiveText || i18n("archive")} <Icon name="file archive outline" /></span>}
          </div>
      }
      {
        this.props.tableBody == null ? 
          <div>
            <div className='empty-table-data'>{this.props.emptyDataValue}</div>
            { this.props.archive && <span onClick={this.props.archive} className='headline-archive-inner hidden'> - {i18n("archive")} <Icon name="file archive outline" /></span>}
          </div>
        :
        <Table className={this.props.tableClass ? this.props.tableClass : ''}>
          <Table.Header>
            {this.props.tableHeaderRow}
          </Table.Header>
          <Table.Body>
            { this.props.tableBody }
          </Table.Body>
        </Table>
      }
    </div>
  }
}

export default TableList;
