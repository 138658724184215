import React, { Component } from 'react';
import { Segment, Container, Radio, Form } from "semantic-ui-react";
import FollowAt from "react-social-media-follow";
import ReactPixel from 'react-facebook-pixel';

import i18n from "../../utils/i18n";

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightschool: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendFlightschool = this.sendFlightschool.bind(this);
    this.updateFlightschool = this.updateFlightschool.bind(this);
  }

  componentDidMount() {
    const advancedMatching = {};
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('711428279376703', advancedMatching, options);
  }

  handleChange (e, { value }) {
    ReactPixel.trackCustom("Empfohlen von: " + value, {});
    this.setState({ surveyDone: true });
  }

  updateFlightschool(e) {
    const flightschool = e.target.value;
    this.setState({ flightschool });
  }

  sendFlightschool() {
    ReactPixel.trackCustom("Flugschule: " + this.state.flightschool, {});
    this.setState({ completed: true });
  }

  render() {
    const links = [
      'https://www.facebook.com/ppltrainer',
      'https://www.instagram.com/ppltrainer',
      'https://www.youtube.com/channel/UCGQnFayI4i3iv2Ytk5_RUhw',
    ];
    return (
      <div className="survey">
        <Container className="container-successful-payment">
          <Segment>
          <a className="logo-link" href="/"><img src="/img/logo.png" alt="Logo" /></a>
            <h3>{i18n("thanks_for_your_order")}</h3>
            <div className="survey-big-font">{i18n("as_soon_as_your_account_has_been_activate_we_will_inform_you")}</div>
            <small>{i18n("login_here_after_creation_of_account")} <a className="gotoLink" href="https://app.ppltrainer.at">app.ppltrainer.at</a></small>
            <div className="small-info-label"><small>{i18n("contact_us_after_12_hours")}</small></div>

            <div className="survey-start-container">
              {
                this.state.surveyDone ?
                  <div>
                    <div className="survey-done">
                      {
                        !this.state.completed ? 
                        <div>
                          <div>{i18n("what_is_your_flightschool_name")}</div>
                          <div>
                            <input type="text" name="flightschool-name" value={this.state.flightschool} onChange={this.updateFlightschool} placeholder={i18n("flightschool")} />
                            <button className='btn-primary' onClick={this.sendFlightschool}>{i18n("save")}</button>
                          </div>
                        </div>
                        : 
                        i18n("thank_you_for_your_feedback")
                      }

                    </div>
                  </div>
                : 
                <div className="survey-start-container-inner">
                  <b>{i18n("from_where_have_you_heard_from_us")}</b>
                  <div className="survey-list-items">
                  <Form>
                    <Form.Field className="survey-list-item">
                      <Radio label={i18n("flightschool")} name='radioGroup' value='Flugschule' onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field className="survey-list-item">
                      <Radio label={i18n("Bekanntenkreis")} name='radioGroup' value='Bekanntenkreis' onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field className="survey-list-item">
                      <Radio label='Google' name='radioGroup' value='Google' onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field className="survey-list-item">
                      <Radio label='Facebook' name='radioGroup' value='Facebook' onChange={this.handleChange} />
                    </Form.Field>
                  </Form>
                  </div>
                </div>
              }

              <div className="more_products_container">
                <a href="https://shop.ppltrainer.at">Weitere Produkte entdecken</a>
                <div>
                  <a href="https://shop.ppltrainer.at/produkt/tassenuntersetzer/" className="product_survey_item">
                    <img src="https://shop.ppltrainer.at/wp-content/uploads/2021/12/bieruntersetzer.png" alt="Bieruntersetzer" />
                    Bieruntersetzer
                  </a>
                  <a href="https://shop.ppltrainer.at/produkt/aviation-headset/" className="product_survey_item">
                    <img src="https://shop.ppltrainer.at/wp-content/uploads/2021/12/short.jpg" alt="Headset" />
                    PNC Headset
                  </a>
                  <a href="https://shop.ppltrainer.at/produkt/flugbuch/" className="product_survey_item">
                    <img src="https://shop.ppltrainer.at/wp-content/uploads/2021/12/Flugbuch.jpg" alt="Flugbuch" />
                    Flugbuch
                  </a>
                </div>
              </div>

              <FollowAt links = {links}/>
            </div>

          </Segment>
        </Container>
      </div>
    );
  }
}

export default Survey;
