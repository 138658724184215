import React, { Component } from 'react';

class PopupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`popup-container ${this.props.active ? "active" : ''} ${this.props.className ? this.props.className : ''}`}>
        <div className="popup-background"></div>
        <div className="popup-inner">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PopupDialog;
