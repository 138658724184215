import React, { Component } from 'react';

import './css/Assistant.css';

class Skeleton extends Component {
  render() {
    return (
      <div className="skeleton-text">
        <div className="skeleton-line half-skeleton"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
        <div className='mt-40'></div>
      </div>
    );
  }
}

export default Skeleton;
