import React, { Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../../../utils/i18n';

import SelectQuestionAndCategoryFormular from './SelectQuestionAndCategoryFormular';

class DocumentFormular extends Component {
  constructor(props) {
    super(props);

    let questions = null, categories = null, previewImageURL = null, fileType = null, name = null;
    // let image = null,  language = null;
    if (this.props.formularData) {
      ({ questions, categories, fileType, name } = this.props.formularData);
      previewImageURL = this.props.formularData.image;
      // ({ image, language } = this.props.formularData);
    }

    this.state = {
      filePreview: null,
      fileType: fileType || null,
      isDragging: false,
      image: null,
      name: name || null,
      previewImageURL: previewImageURL || false,
      questions: questions || [],
      addQuestion: false,
      categories: categories || [],
      addCategory: false,
    };

    this.update = this.update.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
  }

  processFile(file) {
    if (file) {
      this.setState({ 
        fileType: file.type,
        name: file.name,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ 
          filePreview: reader.result,
          previewImageURL: false,
        });
      };

      if (file.type.includes("image") || file.type === "application/pdf") {
        reader.readAsDataURL(file);
      }
    }
  }

  handleDragOver(event) {
    event.preventDefault();
    this.setState({ isDragging: true });
  }

  handleDragLeave(event) {
    event.preventDefault();
    this.setState({ isDragging: false });
  }

  handleDrop(event) {
    event.preventDefault();
    this.setState({ isDragging: false });

    const file = event.dataTransfer.files[0];
    this.processFile(file);
  }

  addQuestion() {
    this.setState({ addQuestion: true });
  }

  addCategory() {
    this.setState({ addCategory: true });
  }

  setQuestion(item) {
    const { question, questionID, readableKey } = item;
    const questions = this.state.questions;
    questions.push({
      question, questionID, readableKey
    });
    this.setState({
      addQuestion: false,
      questions
    });
    this.update();
  }

  setCategory(item) {
    var category = item.item;
    const categories = this.state.categories;
    categories.push({ category });
    this.setState({
      addCategory: false,
      categories
    });
    this.update();
  }

  removeQuestion(index) {
    const questions = this.state.questions;
    questions.splice(index, 1);
    this.setState({ questions });
    this.update();
  }

  removeCategory(index) {
    const categories = this.state.categories;
    categories.splice(index, 1);
    this.setState({ categories });
    this.update();
  }

  update(options) {
    const obj = {};

    if (options && options.target && options.target.files) {
      const file = options.target.files[0];
      this.processFile(file);
      const formData = new FormData();
      formData.append('file', file);
      obj["image"] = formData;
    }

    this.setState(obj, () => {
      const { questions, categories, image, fileType, name } = this.state;
      this.props.updateContent({
        data: {"document": { questions, categories, image, fileType, name }},
        id: 'document',
      });
    });
  }

  render() {
    const { filePreview, fileType, isDragging, previewImageURL } = this.state;
    const that = this;
    return (
      <Modal.Content scrolling className="mm-content-container">
        <Modal.Header>{i18n("upload_new_document")}</Modal.Header>
        <Modal.Description>
          <div>
            <div className='mm-description-item'>{i18n("new_document_description")}</div>
          </div>
        </Modal.Description>
        {
          this.state.addQuestion || this.state.addCategory ?
            <SelectQuestionAndCategoryFormular
              questions={this.props.questions}
              categories={this.props.categories}
              mode={this.state.addCategory ? "addCategory" : "addQuestion"}
              onSelectItem={this.state.addCategory ? this.setCategory : this.setQuestion}
              onCancel={() => this.setState({ addQuestion: false, addCategory: false })}
            />
          :
          <div 
            className="mm-form-container file-upload-container">
            {
              !filePreview && !previewImageURL ?
              <div 
                className={`upload-container ${isDragging ? 'dragging' : ''}`}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleDrop}
              >
                <Icon name="cloud upload" className='upload-icon' />
                <div>
                  <div className='upload-inner-headline'><b>{i18n("choose_a_file_to_upload")}</b></div>
                  <div className='upload-inner-subheadline'>{i18n("jpg_png_pdf_file_list")}</div>
                </div>
                <button type="button" className='btn btn-default' onClick={() => document.getElementById('fileInput').click()}>{i18n("browse_file")}</button>
              </div>
              :
              <div className="file-preview">
                { fileType.includes("image") ?
                    <img src={previewImageURL || filePreview} alt="preview" />
                  : fileType === "application/pdf" ?
                    <iframe src={previewImageURL || filePreview} title="pdf-preview" />
                  : null
                }
              </div>
            }
            {
              (filePreview || previewImageURL) &&
              <div>
                <button type="button" className='btn btn-second change-document-btn' onClick={() => document.getElementById('fileInput').click()}>{i18n("change_document")}</button>

                <div className='headline mt-15'>
                  {i18n("assign_possible_questions_and_categories")} 
                  <span className='headline-description'>{i18n("select_specific_questions_to_show_the_document_in")}</span>
                </div>

                {/* SHOW ALL QUESTIONS ##################### */}

                <div className='new-question-list-container'>
                  {
                    this.state.questions.length > 0 ?
                    <ul>{
                      _.map(this.state.questions, function (item, index) {
                        return <li key={index}>
                          <div className="question-scenario-item fbc">
                            <span className='fbl'>{item.question}</span>
                            <button
                              className='btn btn-second fbf'
                              onClick={() => that.removeQuestion(index)}
                            >{i18n('delete')}</button>
                          </div>
                        </li>;
                      })
                    }</ul>
                    :
                    <div className='font-italic'>{i18n('no_questions_selected')}</div>
                  }
                </div>

                <button
                  onClick={() => this.addQuestion()}
                  className='add-new-question-container btn'>
                  <Icon name="add" /> { this.state.questions.length < 1 ? i18n('add_question') : i18n('add_another_question') }
                </button>

                <div className='mt-10' />
                {/* SHOW ALL CATEGORIES ##################### */}

                <div className='new-question-list-container'>
                  {
                    this.state.categories.length > 0 ?
                    <ul>{
                      _.map(this.state.categories, function (item, index) {
                        return <li key={index}>
                          <div className="question-scenario-item fbc">
                            <span className='fbl'>{i18n(item.category) || item.category}</span>
                            <button
                              className='btn btn-second fbf'
                              onClick={() => that.removeCategory(index)}
                            >{i18n('delete')}</button>
                          </div>
                        </li>;
                      })
                    }</ul>
                    :
                    <div className='font-italic'>{i18n('no_categories_selected')}</div>
                  }
                </div>

                <button
                  onClick={() => this.addCategory()}
                  className='add-new-question-container btn'>
                  <Icon name="add" /> { this.state.questions.length < 1 ? i18n('add_category') : i18n('add_another_category') }
                </button>

              </div>
            }
              <form>
                <input
                  type="file"
                  id="fileInput"
                  className='hidden'
                  lang={document.documentElement.lang}
                  accept=".jpeg, .jpg, .png, .pdf"
                  name="document"
                  onChange={this.update}
                />
              </form>
          </div>
        }
      </Modal.Content>
    );
  }
}

export default DocumentFormular;