import React, { Component } from 'react';
import i18n from '../utils/i18n';
import {  } from 'semantic-ui-react';

class ReminderBox extends Component {
  render() {
    if (this.props.show === false) return <div></div>;
    const { text, actions } = this.props.reminder;
    if (!text) return <div></div>;

    return (
      <>
      <div className='overlay-grey'></div>
      <div className={`overlay-apple-design active main-reminder-box ${this.props.className}`}>
        <div className="bold big">{text.additionalHeadline ? text.additionalHeadline : i18n(text.headline)}</div>
        <div className="mt-10">{i18n(text.description)} {text.additionalDescription}</div>
        <div className="Overview actions-container">
          <button className="btn-primary" onClick={actions.submit}>{i18n(text.submitText)}</button>
          <button className="btn-second" onClick={actions.cancel}>{i18n(text.cancelText || "not_now")}</button>
        </div>
      </div>
      </>
    );
  }
}

export default ReminderBox;
