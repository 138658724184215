import React, { Component } from 'react';
import { Modal, Loader } from 'semantic-ui-react'
import '../css/login.css';
import './style.css';


import i18n from '../../utils/i18n';
import utils from '../../utils/utils';
import image from './background_payment.jpg';
import pilotImage from './pilot.png';


class OutOfCapacityModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      addresse: "",
      months: "12",
      amount: "79",
      agree: false,
      loading: false,
      validemail: true,
      validname: true,
      validaddresse: true,
      valid: true,
      done: false,
      step2: false,
      error: null,
    };

    this.isvalidemail = this.isvalidemail.bind(this);
    this.register = this.register.bind(this);
    this.next = this.next.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.error = this.error.bind(this);
  }

  onChange(event) {
    const value = event.target.value;
    const key = event.target.name;
    const state = this.state;
    state[key] = value;

    if (value) {
      state["valid"+key] = true;
    } else {
      state["valid"+key] = false;
    }

    this.setState(state);
  }

  onChangeValue(event) {
    const value = event.target.value;
    let amount = "69";
    let months = "6";

    switch(value) {
      case "6":
        amount = "69";
        months = "6";
        break;
      case "12":
        amount = "79";
        months = "12";
        break;
      case "24":
        amount = "117";
        months = "24";
        break;
      default: 
        break; 
    }
    this.setState({ amount, months });
    this.forceUpdate();
  }

  isvalidemail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  error(error) {
    const that = this;
    this.setState({ loading: false, error: i18n("email_error") });
    window.setTimeout(function() {
      that.setState({ loading: false, error: false });
    }, 3000);
  }

  next() {
    const { email } = this.state;
    if (this.isvalidemail(email)) {
      this.setState({
        step2: true,
        validemail: true,
      });
    } else {
      this.setState({ validemail: false });
      return;
    }
  }

  register() {
    const that = this;
    const { email, name, addresse, months, amount } = this.state;
    if (name && addresse) {
      this.setState({
        loading: true,
        validname: true,
        validaddresse: true,
        validemail: true,
      });
    } else {
      if (!name) {
        this.setState({ validname: false});
      } else {
        this.setState({ validname: true });
      }
      if (!addresse) {
        this.setState({ validaddresse: false});
      } else {
        this.setState({ validaddresse: true });
      }
      return;
    }

    const data = {
      email,
      name,
      addresse,
      months,
      amount,
    };

    let purchase_school_ref = utils.getCookieValue("purchase_school_ref");
    if (purchase_school_ref) {
      data.purchase_school_ref = purchase_school_ref;
    }

    fetch('https://api.ppltrainer.at/public/sendMail.php', { method: 'POST', headers: { 'Content-Type': 'application/json'}, body: JSON.stringify(data)})
    .then(response => response.json())
    .then(data => {
        if (data.success) { that.setState({ done: true, step2: false }) } else { this.error(); }
    })
    .catch(error => this.error(error));
  }

  render() {
    const days_left = Math.ceil((new Date('2024-12-31') - new Date()) / (1000 * 60 * 60 * 24));
    const days_left_translation = i18n("only_x_days_left").replace("{{x}}", days_left);
    const amount = this.state.amount;
    const months = this.state.months;
    const lastStepConfirmationText = i18n("last_step_confirmation").replace("{{x}}", days_left).replace("{{y}}", amount).replace("{{z}}", months);
    const days_left_btn = i18n("x_days_left_button").replace("{{x}}", days_left);
    const x_days_left_explanation = i18n("x_days_left_explanation").replace("{{x}}", days_left);
    const emailAddress = i18n("email-address");
    const { loading } = this.state;
    return (
      <div>
        <Modal className="payment-modal ooc-modal" dimmer="inverted" size="small" open={true} onClose={this.props.onAbort} closeOnEscape={true} closeOnDimmerClick={false}>
        <Modal.Content className="payment out-of-cap-box" style={{ background: `url("${image}")`}}>
          <div className='fbc'>
            <div className='fbf'>
              <img src="/img/logo.png" className="ooc-logo" alt="" />
            </div>
            {
              this.state.step2 ?
              <div className="select-account-duration-container">
                <div className='last_step_item'>{i18n("last_step")}</div>

                <p className='ooc-additional-text'>
                  {i18n("last_step_description")} <br />
                </p>

                <div className='kaufen_email_container'>

                  <input type="text" placeholder={`${i18n("firstName")}, ${i18n("lastName")}`} className={`user-out-of-capacity-container ${!this.state.validname ? "invalid" : ""}`} 
                    onChange={this.onChange}
                    value={this.state.name}
                    name="name" />

                  <input type="text" placeholder={`${i18n("address_street")}, ${i18n("address_plz")}`} className={`user-out-of-capacity-container ${!this.state.validaddresse ? "invalid" : ""}`} 
                    onChange={this.onChange}
                    value={this.state.addresse}
                    name="addresse" />

                  <div onChange={this.onChangeValue} className={`selects-ooc months_${this.state.months}`} >
                    <label className={`active_6`} ><input type="radio" value="6" name="useage" /> <span><b>{i18n("month_6")}</b> <br /> €69,-</span></label>
                    <label className={`active_12`}><input type="radio" value="12" name="useage" defaultChecked={true} /> <span><b>{i18n("month_12")}</b> <br /> €79,-</span></label>
                    <label className={`active_24`}><input type="radio" value="24" name="useage" /> <span><b>{i18n("month_24")}</b> <br /> €117,-</span></label>
                  </div>

                    <label className='untertitel accept-check'>
                      <input type="checkbox" onChange={(e) => this.setState({ agree: e.target.checked})} />
                      {lastStepConfirmationText}
                    </label>

                    <button onClick={this.register} disabled={!this.state.agree} className='btn-primary btn-register second-step'>
                    {
                      this.state.error ?
                      <span className="btn-text">{this.state.error}</span>
                      :
                      <span className="btn-text" style={{ width: loading ? '0' : 'auto', overflow: loading ? 'hidden' : 'visible' }}>
                        {days_left_btn}
                      </span>
                    }
                    <span className="btn-loading-icon" style={{ display: loading ? 'flex' : 'none' }}>
                      <Loader />
                    </span>

                  </button>
                </div>
              </div>
              :
              this.state.done ?
              <div className='fbl'>
                <div className='pilot_done_big'>{i18n("thanks_for_register")}</div>
                <div className='pilot_done_small'>{i18n("your_are_already_on_the_radar")}</div>
                <img className='pilot_done_image' src={pilotImage} alt="pilot" />
                <div className='pilot_done_close btn-primary' onClick={this.props.onAbort}>{i18n("back")}</div>
              </div>
              :
              <Modal.Description className="fbl">
                <div className="select-account-duration-container">

                  <h4>{i18n("Important_information")}</h4>
                  <div className='days_left'>{days_left_translation}</div>

                  <p className='ooc-additional-text'>
                    {i18n("shop_info_1")} <br />
                  </p>
                  <div style={ {"paddingBottom": "10px", "fontSize": "13px"}}>
                    <span>{i18n("shop_info_3")}</span>
                  </div>
                  <div className='kaufen_email_container'>
                    <input type="email" placeholder={`${emailAddress}`} className={`user-out-of-capacity-container ${!this.state.validemail ? "invalid" : ""}`} 
                      onChange={this.onChange}
                      value={this.state.value}
                      name="email" />
                      <button onClick={this.next} className='btn-primary btn-register'>
                      {
                        this.state.error ?
                        <span className="btn-text">{this.state.error}</span>
                        :
                        <span className="btn-text" style={{ width: loading ? '0' : 'auto', overflow: loading ? 'hidden' : 'visible' }}>
                          {i18n("next")}
                        </span>
                      }
                      <span className="btn-loading-icon" style={{ display: loading ? 'flex' : 'none' }}>
                        <Loader />
                      </span>
                    </button>
                    <div className='untertitel'>{x_days_left_explanation}</div>
                  </div>
                </div>
              </Modal.Description>
            }
          </div>
       
        </Modal.Content>
      </Modal>
     </div>
    );
  }
}

export default OutOfCapacityModel;
