import React, { Component } from 'react';
import _ from 'underscore';
import { Form, Radio, Image, Icon } from "semantic-ui-react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import i18n from "../utils/i18n";
import API from '../utils/resources/api';

class Categories extends Component {
  constructor(props) {
    super(props);

    let documentPath = 'data/24.6/documents';
    this.favoriteQuestionsCollectionPath = 'favorite_questions_collection';

    this.handleChange = this.handleChange.bind(this);
    this.setFullImageSize = this.setFullImageSize.bind(this);
    this.addQuestionToFavorites = this.addQuestionToFavorites.bind(this);
    this.getStarIcon = this.getStarIcon.bind(this);
    this.checkIfQuestionIsFavoriteQuestion = this.checkIfQuestionIsFavoriteQuestion.bind(this);
    this.getFavoriteQuestions = this.getFavoriteQuestions.bind(this);
    this.setLocalStorageItem = this.setLocalStorageItem.bind(this);
    this.closeFullSizeImage = this.closeFullSizeImage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      isFullScreen: false,
      fullScreenImage: false,
      documentPath,
    };
  }

  handleChange(e, options) {
    if (this.props.readOnly) return;
    // decision between training and simulation mode to go automatically on
    if ((this.props.mode === "training" && this.props.isAnswerGiven && this.props.settings.noConfirmationOnClickingAnswer) || this.props.hasBeenVerified) return;

    this.setState({ value: options.value });
    this.props.onSelectAnswer(options);
  }

  getCheckedStatus(index) {
    if (!this.props.isAnswerGiven) return false;
    if (this.state.value === index) return true;
    return false;
  }

  getUpperCasedImage(img) {
    const fullImg = (img && img.toUpperCase && img.toUpperCase()) || img;
    const image = fullImg.split(".")[0] + ".jpg"; // split UPPERCASE.jpg
    return image;
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.closeFullSizeImage();
    }
  }

  setFullImageSize(img, toUpperCase) {
    // TODO img to uppercase? can it be that img is an object?

    window.addEventListener('keydown', this.handleKeyDown);

    if (toUpperCase) img = this.getUpperCasedImage(img);
    this.setState({
      isFullScreen: true,
      fullScreenImage: img,
    });
  }

  closeFullSizeImage() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.setState({
      isFullScreen: false,
      fullScreenImage: false
    });
  }

  getQuestionNumber(key) {
    if (!key) return '';
    const tags = key.split("_");
    let value = "";
    let additionalWording = "";

    const type = tags[0];
    const name = tags[1];

    if (tags && tags.length > 2) {
      const num = tags[2];

      if (type === "Scenario") {
        value = parseInt(num) + 1;
      } else if (type === "OLDER") {
        value = num;
      } else {
        value = num;
        if (isNaN(value)) {
          value = name;
        }
      }
    } else {
      if (type === "Scenario") {
        additionalWording = i18n("scenario");
      }
      value = (key.split("_") && key.split("_")[1]) || i18n("custom_question_ppl_trainer");
    }
    return additionalWording ? additionalWording + ": " + value : value;
  }

  setLocalStorageItem(data) {
    localStorage.setItem(this.favoriteQuestionsCollectionPath, JSON.stringify(data));
  }

  getFavoriteQuestions() {
    return JSON.parse(localStorage.getItem(this.favoriteQuestionsCollectionPath)) || {};
  }

  checkIfQuestionIsFavoriteQuestion(id) {
    const favoriteQuestions = this.getFavoriteQuestions();
    return !_.isUndefined(favoriteQuestions[id]);
  }

  getStarIcon() {
    const { id } = this.props;
    const isFavorite = this.checkIfQuestionIsFavoriteQuestion(id);
    if (isFavorite) return 'star';
    return 'star outline';
  }

  addQuestionToFavorites() {
    // const { question, id, answers, correctAnswer } = this.props;
    const { question, id } = this.props;
    const favoriteQuestions = this.getFavoriteQuestions();
    if (favoriteQuestions[id]) {
      delete favoriteQuestions[id];
      this.setLocalStorageItem(favoriteQuestions);
      toast.success(i18n("favorite_removed_successfully"));
    } else {
      // const answer = answers[correctAnswer];
      // favoriteQuestions[id] = question + " " + answer;
      favoriteQuestions[id] = question;
      this.setLocalStorageItem(favoriteQuestions);
      toast.success(i18n("favorite_added_successfully"));
    }
    API.syncSettings();
    this.forceUpdate();
  }

  render() {
    const that = this;
    const question = this.props.question.replace(/ {4}/g, '\n');
    return (
      <div className="quiz-entry">
        <div className="quiz-question">
          <span className="quiz-question-inner" onClick={this.addQuestionToFavorites} style={{ whiteSpace: 'pre-wrap' }}>
            {question} <Icon name={this.getStarIcon()} />
          </span>
          <div className="quiz-question-header">
            <label className="quiz-question-mark">
              <span><label title={this.props.id}>{this.getQuestionNumber(this.props.id)}</label></span>
            </label> 
          </div>
        </div>
        { this.props.image ?
          <div className={`${this.state.isFullScreen ? 'isFullScreen' : ''}`}>
            {
              this.state.isFullScreen ?
                <div>
                  {
                    this.state.fullScreenImage.type && this.state.fullScreenImage.type === "url" ?
                      <Image
                        src={this.state.fullScreenImage.url}
                        className="question-img"
                        centered
                      />
                    :
                      <Image
                        src={`./${this.state.documentPath}/${this.state.fullScreenImage}`}
                        className="question-img"
                        centered
                      />
                  }
                  <button className="btn-second close-btn" onClick={e => that.closeFullSizeImage(this.props.image, false)}>{i18n("close")}</button>
                </div>
              :
              <div className="inner">
                {
                  this.props.image.type && this.props.image.type === "url" ?
                    <Image
                      key={this.props.image.type}
                      onClick={e => that.setFullImageSize(this.props.image, false)}
                      src={this.props.image.url}
                      className="question-img"
                      centered
                    />
                  : 
                  this.props.image && this.props.image.map && this.props.image.map(function (img) {
                    // in this case we have multiple images, can transform it to uppercase
                    img = that.getUpperCasedImage(img)
                    return <Image
                      key={img}
                      onClick={e => that.setFullImageSize(img, true)}
                      src={`./${that.state.documentPath}/${img}`}
                      className="question-img"
                      centered
                    />
                  })
                }
              </div>
            }
          </div>
          :
          null
        }
        <div>
          <Form>
            {
              this.props.answers.map((currentItem, index) => (
                <Form.Field
                  key={index}
                  className={`
                    ${index === this.props.correctAnswer && this.props.isAnswerCorrect === false && this.props.mode !== "simulation" ? 'right' : ''}
                    ${index === this.state.value && this.props.isAnswerCorrect === true && this.props.mode !== "simulation" ? 'right' : ''}
                    ${index === this.state.value && this.props.isAnswerCorrect === false && this.props.mode !== "simulation" ? 'wrong' : ''}
                    ${this.props.isAnswerGiven && index === this.state.value && !this.props.resolveCorrectAnswer && this.props.mode !== "simulation" ? 'no_special_border_training': ''}
                    ${index === this.props.correctAnswer && this.props.isAnswerGiven && this.props.resolveCorrectAnswer && this.props.mode !== "simulation" ? 'no_special_border_training': ''}
                    ${this.props.isAnswerGiven && index === this.state.value && this.props.mode === "simulation" ? 'no_special_border': ''}
                    ${this.props.readOnly === true && index === this.props.check_correctAnswer ? 'right no_special_border_training' : ''}
                    ${this.props.readOnly === true && index === this.props.check_givenAnswer && this.props.check_isCorrect === false ? 'wrong no_special_border_training' : ''}
                  `}
                >
                  <Radio
                    label={currentItem}
                    name='answer'
                    value={index}
                    checked={this.getCheckedStatus(index)}
                    onChange={this.handleChange}
                  />
                </Form.Field>   
              ))
            }
          </Form>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={2500}
          className="toast-question-favorite"
          hideProgressBar
          closeOnClick={true}
          rtl={false}
          pauseOnHover={false}
        />

      </div>
    );
  }
}

export default Categories;
