import React, { Component } from 'react';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

import loaderimage from './img/loader.png';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    return <Segment>
    <Dimmer active inverted>
      <Loader size='medium'>Loading</Loader>
    </Dimmer>

    <Image src={loaderimage} />
  </Segment>
  }
}

export default Loading;
