import React, { Component } from 'react';
import _ from 'underscore';
import API from '../../../utils/resources/ato.js';
import TableList from '../../../components/manager/TableList';

import { Icon, Table, Modal } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';
import moment from 'moment';

class ArchiveFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.formularOptions["type"],
      list: null,
    };

    this.openFullStatistic = this.openFullStatistic.bind(this);
    this.getHeadlineDescription = this.getHeadlineDescription.bind(this);
    this.toggleGroupVisibility = this.toggleGroupVisibility.bind(this);
  }

  componentDidMount() {
    const that = this;
    const id = this.state.type;
    API["get" + id + "Archive"]().then((list) => {
      if (list) {
        that.setState({ list });
      }
    });
  }

  getWorkingGroup(data) {
    return data.group || _.map(data.selectedStudents, function (student) { return student }).join(', ') || '';
  }

  getCategorieTranslations(categories) {
    return _.map(categories, function(category) {
      return i18n(category) || category;
    }).join(", ");
  }

  getReadableDate(date) {
    return moment(date).format("DD. MMM YYYY");
  }

  getHeadlineDescription() {
    const { type } = this.state;
    switch (type) {
      case "Exam":
        return i18n("overview_of_all_exams_from_past");
      case "Homework":
        return i18n("overview_of_all_homeworks_from_past");
      default:
        return "";
    }
  }

  openFullStatistic(id) {
    // send information to outer component
    // get correct element from fetched data by id an pass it
    this.props.onInnerManagerAction({
      data: _.find(this.state.list, function (data) {
        return data.id === id;
      }),
      type: this.state.type,
      id,
    })
  }

  toggleGroupVisibility(groupKey) {
    this.setState((prevState) => ({
      openGroup: prevState.openGroup === groupKey ? null : groupKey,
    }));
  }

  renderAvailableItems() {
    const that = this;
    if (_.isEmpty(this.state.list)) return null;
  
    // Gruppierung nach "group" oder "selectedStudents"
    const groupedItems = _.groupBy(this.state.list, (item) => item.group || Object.values(item.selectedStudents).join(','));
  
    // Sortierung der Gruppen nach dem "enddate"
    const sortedGroups = Object.keys(groupedItems).sort((groupA, groupB) => {
      const itemsA = groupedItems[groupA];
      const itemsB = groupedItems[groupB];
  
      // Verwenden Sie das neueste "enddate" für die Sortierung
      const latestEndDateA = Math.max(...itemsA.map((item) => new Date(item.enddate).getTime()), 0);
      const latestEndDateB = Math.max(...itemsB.map((item) => new Date(item.enddate).getTime()), 0);
  
      return latestEndDateB - latestEndDateA;
    });
  
    return sortedGroups.map((groupKey) => {
      const itemsInGroup = groupedItems[groupKey];
  
      // Sortierung der Elemente innerhalb der Gruppe nach dem "enddate"
      const sortedItems = itemsInGroup.sort((itemA, itemB) => {
        const endDateA = new Date(itemA.enddate).getTime();
        const endDateB = new Date(itemB.enddate).getTime();
        return endDateB - endDateA;
      });
  
      return (
        <React.Fragment key={groupKey}>
          <Table.Row>
            <Table.Cell colSpan="3" className="collapsible-item" onClick={() => this.toggleGroupVisibility(groupKey)}>
              {groupKey} <span>({sortedItems.length})</span> <Icon name={this.state.openGroup === groupKey ? "triangle up" : "triangle down"} />
            </Table.Cell>
          </Table.Row>
  
          {this.state.openGroup === groupKey && sortedItems.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell onClick={() => that.openFullStatistic(entry.id)} className={`max-width cursor-pointer`} title={that.getWorkingGroup(entry)}>
                <Icon name="share square" /> {that.getWorkingGroup(entry)}
              </Table.Cell>
              <Table.Cell className={`max-width`} title={that.getCategorieTranslations(entry.selectedCategories)}>
                {that.getCategorieTranslations(entry.selectedCategories)}
              </Table.Cell>
              <Table.Cell className="dyn-width" title={that.getReadableDate(entry.enddate)}>
                {that.getReadableDate(entry.enddate)}
              </Table.Cell>
            </Table.Row>
          ))}
        </React.Fragment>
      );
    });
  }  

  render() {
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n(this.state.type + "_archive")}</Modal.Header>

        <div className='mm-form-container create-homework homework-details-container'>
          <div className='headline'><span className='headline-description'>{this.getHeadlineDescription()}</span></div>
          <TableList
            tableClass="custom-table"
            tableHeaderRow={
              <Table.Row>
                <Table.HeaderCell className="max-width">{i18n('flightstudent')}/{i18n('class')}</Table.HeaderCell>
                <Table.HeaderCell className="max-width">{i18n('kategorien')}</Table.HeaderCell>
                <Table.HeaderCell className="dyn-width">{i18n('ended_on')}</Table.HeaderCell>
              </Table.Row>
            }
            tableBody={ this.renderAvailableItems() }
            emptyDataValue={i18n('no_exams_available')}
          />
        </div>
      
    </Modal.Content>
  }
}

export default ArchiveFormular;
